import React, { useCallback, useRef, useState } from 'react';

import { Frame, Loading } from '@shopify/polaris';
import { PolarisVizProvider } from '@shopify/polaris-viz';

import { TopBarMarkup, NavigationMarkup, AnalyticsAdmin, AnalyticsPartner } from '../../components';

import './Analytics.scss';
import { useUser } from '../../utils/PrivateRoute';

export function Analytics() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive(true);
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      <PolarisVizProvider
        themes={{
          Default: {
            chartContainer: {
              backgroundColor: '#fff',
              padding: '0.5rem 0',
            },
            seriesColors: {
              single: '#00FFAA',
            },
            grid: {
              horizontalMargin: 0,
              horizontalOverflow: false,
              // showVerticalLines: true,
              color: '#bfbfbf',
            },
            yAxis: {
              backgroundColor: '#fff',
              labelColor: '#8c8c8c',
            },
            xAxis: {
              labelColor: '#8c8c8c',
            },
            line: {
              hasSpline: true,
              hasArea: true,
            },
          },
        }}
      >
        {loadingMarkup}
        {user && user.role === 'admin' ? <AnalyticsAdmin isLoading={isLoading} setLoading={setIsLoading} /> : null}
        {user && user.role === 'partner' ? <AnalyticsPartner isLoading={isLoading} setLoading={setIsLoading} /> : null}
      </PolarisVizProvider>
    </Frame>
  );
}

import { LegacyCard, EmptyState, Filters, Text, IndexTable, Link, Select, useIndexResourceState, FilterInterface, ComplexAction } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { IRiskFamily, IRiskIndividual } from '../../../types';
import { parseDateLabel } from '../../../utils/Common';
import dayjs from 'dayjs';

type RiskFamilyListProps = {
  user: any;
  setFamilyReports: any;
};

export function RiskFamilyList({ user, setFamilyReports }: RiskFamilyListProps) {
  const navigate = useNavigate();

  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState([]);
  const [frontItems, setFrontItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const resourceName = {
    singular: 'report',
    plural: 'reports',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/risks/family', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
          setFamilyReports(data.data.length);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReports();
  }, [update, user.id]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filteredItems = items.filter((item: IRiskIndividual) => {
      const name =
        item.user?.customer?.type === 'company' ? item.user?.name : item.user?.lastname ? `${item.user?.lastname} ${item.user?.name}` : item.user?.name;
      return name?.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
    });
    setFrontItems(filteredItems);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // tmp.sort((a: IRiskIndividual, b: IRiskIndividual) => new Date(a.date_created) - new Date(b.date_created));
      tmp.sort((a: IRiskIndividual, b: IRiskIndividual) => dayjs(a.date_created).diff(dayjs(b.date_created)));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      // tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      tmp.sort((a: IRiskIndividual, b: IRiskIndividual) => dayjs(b.date_created).diff(dayjs(a.date_created)));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (products: { _id: any }) => {
    return products._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Row markup
   */
  const rowMarkup = frontItems.map((item: IRiskFamily, index) => {
    const { _id: id, user, date_created } = item;

    const name = user && user.lastname ? `${user.name} ${user.lastname}` : user && user.name ? user.firstname : 'N/A';

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            <Link url={`/reports/family/${id}`} removeUnderline monochrome dataPrimaryLink>
              {name}
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{parseDateLabel(new Date(date_created))}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Empty list markup
   */

  const emptyList = (
    <EmptyState
      heading="Gestisci i reports"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/67d1bd2ad29c4adc50fb195375f31fcb1674823604794398778c01f6c185d702.svg"
    >
      <p>Qua è dove puoi gestire i tuoi reports</p>
    </EmptyState>
  );

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const riskListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            // @ts-ignore
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra report'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta report (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta report (dal meno recente)', value: 'DATE_CREATED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        loading={isLoading}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Cliente' }, { title: 'Data creazione' }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return riskListMarkup;
}

import React, { useCallback, useState } from 'react';
import { Member } from '../../../types';
import { LegacyCard, FormLayout, TextField, Select, Tag, PageActions } from '@shopify/polaris';

interface Props {
  id: number;
  member: Member;
  editMode: boolean;
  handleEdit: (index: number) => void;
  onSave: (member: Member, index: number) => void;
  onDelete: (index: number) => void;
}

enum JobType {
  Commerciante = 'commerciante',
  Dirigente = 'dirigente',
  Funzionario = 'funzionario',
  Impiegato = 'impiegato',
  LiberoProfessionistaIscritto = 'libero professionista iscritto ad una cassa',
  LiberoProfessionistaNonIscritto = 'libero professionista non iscritto ad una cassa',
  CollaboratoreEFigureAssimilate = 'collaboratore e figure assimilate',
  Artigiano = 'artigiano',
  Operaio = 'operaio',
  NonOccupato = 'non occupato',
  Pensionato = 'pensionato',
  Studente18Piu = 'studente 18+',
}

export function ReportFamilyMember({ id, member, editMode, handleEdit, onSave, onDelete }: Props) {
  /**
   * Family member states
   */
  const [isDirty, setIsDirty] = useState(true);
  const [currentMember, setCurrentMember] = useState<Member>(member);

  const selectOptions = [
    { label: 'Sì', value: '1' },
    { label: 'No', value: '0' },
  ];
  const selectSexOptions = ['M', 'F'];
  const selectJobsOptions = [
    { label: 'Commerciante', value: JobType.Commerciante },
    { label: 'Dirigente', value: JobType.Dirigente },
    { label: 'Funzionario', value: JobType.Funzionario },
    { label: 'Impiegato', value: JobType.Impiegato },
    { label: 'Libero professionista iscritto ad una cassa', value: JobType.LiberoProfessionistaIscritto },
    { label: 'Libero professionista non iscritto ad una cassa', value: JobType.LiberoProfessionistaNonIscritto },
    { label: 'Collaboratore e figure assimilate', value: JobType.CollaboratoreEFigureAssimilate },
    { label: 'Artigiano', value: JobType.Artigiano },
    { label: 'Operaio', value: JobType.Operaio },
    { label: 'Non occupato', value: JobType.NonOccupato },
    { label: 'Pensionato', value: JobType.Pensionato },
    { label: 'Studente 18+', value: JobType.Studente18Piu },
  ];

  const handleSave = useCallback(() => {
    const errFlag = false;
    if (errFlag) {
      return;
    }
    if (onSave) {
      onSave(currentMember, id);
    }
  }, [onSave]);

  const handleChange = useCallback((field: keyof Member, value: any) => {
    setCurrentMember((prev) => ({ ...prev, [field]: value }));
    handleEdit(id);
    setIsDirty(true);
  }, []);

  // onDelete = useCallback(() => {
  //   const newMemberData = currentMember;
  //   setCurrentMember(newMemberData);
  // }, []);

  const familyQuestionsMarkup = (
    <FormLayout>
      <Tag>Componente nucleo</Tag>
      <FormLayout.Group>
        <TextField label="Nome" type="text" value={currentMember.name} onChange={(e) => handleChange('name', e)} autoComplete="off" />
        <TextField label="Età" type="number" value={String(currentMember.age)} onChange={(e) => handleChange('age', Number(e))} autoComplete="off" />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select label="Sesso" options={selectSexOptions} value={currentMember.sex} onChange={(e) => handleChange('sex', e)} />
        <Select label="Sposato/a?" options={selectOptions} value={String(currentMember.married)} onChange={(e) => handleChange('married', Number(e))} />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select label="Professione" options={selectJobsOptions} value={currentMember.job} onChange={(e) => handleChange('job', e)} />
        <TextField
          label="Anni contribuiti"
          type="number"
          value={String(currentMember.job_year)}
          onChange={(e) => handleChange('job_year', Number(e))}
          autoComplete="off"
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="Reddito netto annuo (€)"
          type="number"
          value={String(currentMember.net_income)}
          onChange={(e) => handleChange('net_income', Number(e))}
          autoComplete="off"
        />
        <TextField
          label="Risparmio annuo (€)"
          type="number"
          value={String(currentMember.annual_saving)}
          onChange={(e) => handleChange('annual_saving', Number(e))}
          autoComplete="off"
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="In caso di infortunio o malattia, saresti
          sostenuto economicamente da INPS o INAIL?"
          options={selectOptions}
          value={String(currentMember.government_support)}
          onChange={(e) => handleChange('government_support', Number(e))}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="Se per colpa di un'invalidità non riuscissi a
          svolgere le azioni della vita quotidiana,
          riusciresti a sostenere i costi di una badante o
          di una casa di cura, o hai un familiare che può
          prendersi cura di te?"
          options={selectOptions}
          value={String(currentMember.caregiver)}
          onChange={(e) => handleChange('caregiver', Number(e))}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="Sei soddisfatto del Servizio Sanitario Nazionale?"
          options={selectOptions}
          value={String(currentMember.satisfied_sns)}
          onChange={(e) => handleChange('satisfied_sns', Number(e))}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="Capitale risparmiato ad oggi (€)"
          type="number"
          value={String(currentMember.saved)}
          onChange={(e) => handleChange('saved', Number(e))}
          autoComplete="off"
        />
      </FormLayout.Group>
      <PageActions
        primaryAction={{
          content: 'Aggiungi',
          disabled: !editMode,
          onAction: () => handleSave(),
        }}
        secondaryActions={[
          {
            content: 'Rimuovi',
            onAction: () => onDelete(id),
          },
        ]}
      />
    </FormLayout>
  );

  return <LegacyCard sectioned>{familyQuestionsMarkup}</LegacyCard>;
}

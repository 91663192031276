import { DataTable, TableData } from '@shopify/polaris';
import { ReactNode } from 'react';
import { TColumnsContents } from '../../../../../types/editableTable';

type Props = {
  /** The column labels */
  columnContents: TColumnsContents;
  rows?: Array<ReactNode>[];
};

function EditableTable({ columnContents, rows }: Props) {
  const columnTypes = columnContents.map((columnContent) => {
    return columnContent.columnType;
  });

  const columnNames = columnContents.map((columnContent) => {
    return columnContent.columnTitle;
  });

  return <DataTable columnContentTypes={columnTypes} headings={columnNames} rows={rows as TableData[][]} />;
}

export default EditableTable;

import { LegacyCard, EmptyState, Filters, Text, Badge, Pagination, IndexTable, useIndexResourceState, Select, Link } from '@shopify/polaris';
import axios from 'axios';
import React, { useCallback, useState, useEffect } from 'react';
import { Customer } from '../../types';

import styles from './CustomerList.module.css';
import { useNavigate } from 'react-router-dom';

export function CustomerList({}) {
  const navigate = useNavigate();

  const [selectedItems, setSelectedItems] = useState([]);
  const [taggedWith, setTaggedWith] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<Customer[]>([]);
  const [frontItems, setFrontItems] = useState<Customer[]>([]);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'cliente',
    plural: 'clienti',
  };

  const promotedBulkActions = [
    {
      content: 'Modifica clienti',
      onAction: () => console.log('Todo: implement bulk edit'),
    },
  ];

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchClients = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/customers', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchClients();
  }, []);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: string) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: any[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item: Customer) => {
        const name = item.type === 'company' ? item.user?.name : item.user?.lastname ? `${item.user?.lastname} ${item.user?.name}` : item.user?.name;
        return name?.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (customer: Customer) => {
    return customer._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  const rowMarkup = frontItems.map((customer: Customer, index) => (
    <IndexTable.Row id={customer._id} key={customer._id} selected={selectedResources.includes(customer._id)} position={index}>
      <IndexTable.Cell>
        <Link url={`/customers/${customer._id}`} removeUnderline monochrome dataPrimaryLink>
          {customer.user?.lastname ? (
            <>
              <Text as="span" fontWeight="semibold">
                {customer.user.lastname}
              </Text>{' '}
              {customer.user.name}
            </>
          ) : (
            <Text as="span" fontWeight="semibold">
              {customer.user?.name}
            </Text>
          )}
        </Link>
      </IndexTable.Cell>
      <IndexTable.Cell>
        {customer.type === 'company' ? (
          <Badge status="attention" progress="incomplete">
            Azienda
          </Badge>
        ) : (
          <Badge progress="incomplete">Privato</Badge>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>
        {customer.address !== undefined ? (
          <>
            {customer.address?.city ? customer.address.city : <Badge>Città mancante</Badge>},&nbsp;
            {customer.address?.line ? customer.address.line : <Badge>Indirizzo mancante</Badge>},&nbsp;
            {customer.address?.zip ? customer.address.zip : <Badge>CAP mancante</Badge>}
          </>
        ) : (
          <Badge>Mancante</Badge>
        )}
      </IndexTable.Cell>
      <IndexTable.Cell>{customer.user?.email ? customer.user.email : <Badge>Mancante</Badge>}</IndexTable.Cell>
      <IndexTable.Cell>{customer.user?.phone ? customer.user.phone : <Badge>Mancante</Badge>}</IndexTable.Cell>
      <IndexTable.Cell>{customer.date_created ? new Date(customer.date_created).toLocaleDateString() : <Badge>Mancante</Badge>}</IndexTable.Cell>
    </IndexTable.Row>
  ));

  /**
   * Empty state markup
   */
  const emptyList = (
    <EmptyState
      heading="Gestisci i clienti"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg"
      action={{
        content: 'Aggiungi un cliente',
        onAction: () => {
          navigate('/customers/new');
        },
      }}
    >
      <p>Qua è dove puoi gestire le informazioni dei tuoi clienti</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 50 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={this.handlePreviousPage}
          // onNext={this.handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra clienti'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta cliente (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta cliente (dal meno recente)', value: 'DATE_CREATED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        loading={isLoading}
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[
          { title: 'Nome completo' },
          { title: 'Tipologia' },
          { title: 'Indirizzo' },
          { title: 'Email' },
          { title: 'Telefono' },
          { title: 'Data di creazione' },
        ]}
        sort
      >
        {rowMarkup}
      </IndexTable>
      {paginationMarkup}
    </LegacyCard>
  );

  return customerListMarkup;
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Icon,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Box,
  LegacyStack,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../components';

import styles from './QuestionnaireAll.module.scss';

import { NoteMinor } from '@shopify/polaris-icons';
import { useUser } from '../../utils/PrivateRoute';
import axios from 'axios';
import { Variant } from '../../types';

export function QuestionnaireAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * Data states
   */
  const [items, setItems] = useState([]);

  /**
   * Fetch products
   */
  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products/variants', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setItems(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Page markup
   */
  const style = { '--med-width-rows': 4, '--max-width-rows': 2 } as React.CSSProperties;
  const actualPageMarkup = (
    <Page title="Prodotti">
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <ul className={styles.settingsList} style={style}>
              {items.map((item: Variant) => (
                <li className={styles.settingsListItem} key={item._id}>
                  <Box shadow="md" borderRadius="100" padding={'400'}>
                    <LegacyStack alignment="center">
                      <div className="settingsListItemIcon">
                        <Icon source={NoteMinor as any} />
                      </div>
                      <div>
                        <p className="settingsListItemTitle">{item.name}</p>
                        <p className={styles.settingsListItemSubtitle}>Allegati</p>
                        {item.files !== undefined &&
                          item.files.length > 0 &&
                          item.files.map((file: any) => (
                            <a href={`${process.env.REACT_APP_BLOB_DOCUMENTS_URL}${file.key}`} key={file.key} target="_blank" rel="noreferrer">
                              <div>{file.title}</div>
                            </a>
                          ))}
                      </div>
                    </LegacyStack>
                  </Box>
                </li>
              ))}
            </ul>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}

import { LegacyCard, EmptyState, Filters, Text, IndexTable, Select, useIndexResourceState, FilterInterface } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { Category } from '../../../../../types';

export function CategoryList({ categories, frontItems, setFrontItems, isLoading, setIsLoading, handleCategoryModal }: any) {
  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');

  const resourceName = {
    singular: 'categoria',
    plural: 'categorie',
  };

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: string, value: null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  // Filtering
  useEffect(() => {
    const filterItems = () => {
      const filteredItems = categories.filter((item: Category) => {
        return item.name.toLowerCase().includes(queryValue ? queryValue.toLowerCase() : '');
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (category: { _id: any }) => {
    return category._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Handle update category
   */
  const handleUpdate = useCallback(() => {
    handleCategoryModal(selectedResources[0]);
  }, [selectedResources]);

  const promotedBulkActions = [
    {
      content: 'Modifica',
      onAction: handleUpdate,
      disabled: selectedResources.length > 1,
    },
  ];

  /**
   * Row markup
   */
  const rowMarkup = frontItems.map((item: Category, index: number) => {
    const { _id: id, name, tax } = item;

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{tax.toFixed(2).replace('.', ',')} %</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  const emptyList = (
    <EmptyState
      heading="Gestisci le categorie"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/67d1bd2ad29c4adc50fb195375f31fcb1674823604794398778c01f6c185d702.svg"
      action={{
        content: 'Aggiungi una categoria',
        onAction: () => handleCategoryModal(),
      }}
    >
      <p>Qua è dove puoi gestire le categorie dei prodotti</p>
    </EmptyState>
  );

  /**
   * Markup with items
   */
  useEffect(() => {
    if (categories.length > 0) setIsLoading(false);
  }, [categories]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            // @ts-ignore
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra categorie'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta categoria (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta categoria (dal meno recente)', value: 'DATE_CREATED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        loading={isLoading}
        itemCount={categories.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Nome' }, { title: 'Tassazione' }]}
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return customerListMarkup;
}

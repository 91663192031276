import React from 'react';
import { Box, MediaCard, Icon } from '@shopify/polaris';
// import { BookIcon } from '@shopify/polaris-icons';

type TGuideCardsProps = {
  title: string;
  description: string;
};

export function GuideItem(props: TGuideCardsProps) {
  return (
    <Box padding={'2'}>
      <MediaCard
        title={props.title}
        primaryAction={{
          content: 'Learn more',
          //onAction: () => {},
        }}
        description={props.description}
        size="small"
      >
        {/* <Icon source={BookIcon} /> */}
        <img
          alt=""
          width="100%"
          height="100%"
          style={{
            objectFit: 'cover',
            objectPosition: 'center',
          }}
          src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
        />
      </MediaCard>
    </Box>
  );
}

import React, { useCallback, useState } from 'react';

import { Button, Form, FormLayout, TextField, InlineError } from '@shopify/polaris';

import './Login.css';
import logo from '../../logo_bw.svg';
import { HeroGradient } from './HeroGradient';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

export function LoginPage() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [error, setError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      setLoginError(false);
      setError(false);

      const req = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/login',
        { email, password },
        {
          timeout: 5000,
        },
      );
      const response = req.data;

      if (response.status === 'success') {
        // Save bearer token into axios
        localStorage.setItem('mb__access_token', response.data.token);

        // Redirect
        navigate('/');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      const status = axiosError.response?.status || 500;
      if (status === 404) {
        setLoginError(true);
      } else {
        setError(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [email, history, password]);

  const handleEmailChange = useCallback((value: any) => setEmail(value), []);
  const handlePwdChange = useCallback((value: any) => setPassword(value), []);

  return (
    <div className="page-main">
      <HeroGradient />
      <div className="page-content">
        <div className="login-card ">
          <header className="login-card__header">
            <h1 className="login-card__logo">
              <a href="/">
                <img src={logo} />
              </a>
            </h1>
          </header>

          <div className="login-card__content">
            <div className="main-card-section">
              <h2 className="ui-heading">Accedi</h2>
              <h3 className="ui-subheading ui-subheading--subdued">Continua verso la tua dashboard</h3>
              <div style={{ marginTop: '2rem' }}>
                <Form onSubmit={handleSubmit}>
                  <FormLayout>
                    <TextField autoComplete="on" value={email} onChange={handleEmailChange} label="Email" type="email" />
                    <TextField
                      autoComplete="on"
                      value={password}
                      onChange={handlePwdChange}
                      label="Password"
                      type="password"
                      labelAction={{ content: 'Password dimenticata?', url: '/forgot-password' }}
                    />
                    {loginError === true ? <InlineError message="Controllare i dati di accesso." fieldID="myFieldID" /> : ''}
                    {error === true ? <InlineError message="Qualcosa è andato storto. Riprovare più tardi." fieldID="myFieldID" /> : ''}
                    <Button primary loading={isLoading} size="large" submit>
                      Login
                    </Button>
                  </FormLayout>
                </Form>
              </div>
            </div>
          </div>
        </div>
        <footer className="login-footer">
          <a className="login-footer__link" target="_blank" href="https://myinsurancemate.it/contact/support" title="Centro di assistenza">
            Assistenza
          </a>
          <a className="login-footer__link" target="_blank" href="https://nano-insurance.com/legal/myinsurancemate/privacy-policy" title="Privacy Policy">
            Privacy
          </a>
          <a
            className="login-footer__link"
            target="_blank"
            href="https://nano-insurance.com/legal/myinsurancemate/termini-e-condizioni"
            title="Termini di servizio"
          >
            Termini
          </a>
        </footer>
      </div>
    </div>
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';

import {
  Button,
  LegacyCard,
  Frame,
  Layout,
  Badge,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Text,
  FormLayout,
  TextField,
  Modal,
  Banner,
  ContextualSaveBar,
  Toast,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';
import { parseDateLabel } from '../../../utils/Common';

import './Account.scss';
import { useUser } from '../../../utils/PrivateRoute';

export function Account() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [active, setActive] = useState(false);
  const [modalActive, setModalActive] = useState(false);
  const [modalChangePwdActive, setModalChangePwdActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [pwdError, setPwdError] = useState(false);
  const [paused, setPaused] = useState(false);
  const [deleted, setDeleted] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const togglePaused = useCallback(() => setPaused((paused) => !paused), []);
  const toggleDeleted = useCallback(() => setDeleted((deleted) => !deleted), []);

  const [defaultState, setDefaultState] = useState({
    name: '',
    email: '',
  });
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState('');
  const [stripeId, setStripeId] = useState('');
  const [dateCreated, setDateCreated] = useState('');

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  /** Discard */
  const handleDiscard = useCallback(() => {
    setName(defaultState.name);
    setEmail(defaultState.email);
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: name,
          email: email,
        }),
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        // Redirect to login
        if (user.email === defaultState.email) {
          const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const response = await data.json();

          if (response.status === 'success') {
            setActive(true);
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          }
        }
      } else {
        handleDiscard();
        setError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setError(true);
    }
    setIsDirty(false);
  }, [name, email, user.email, defaultState.email, handleDiscard]);

  /**
   * Delete account
   */
  const handleModalChange = useCallback(async () => {
    setModalActive(!modalActive);
  }, [modalActive]);

  const handleDelete = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        setDeleted(true);
        // Perform logout
        const logout = async () => {
          try {
            const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            const response = await data.json();

            if (response.status === 'success') {
              window.location.href = '/login';
            }
          } catch (error) {
            console.log(error);
          }
        };

        setTimeout(async () => {
          await logout();
        }, 3000);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setError(true);
    }
  }, [handleDiscard]);

  /**
   * Pause account
   */
  const handlePause = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/subscription`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        if (!response.paused) {
          handleModalChange();
          setError(true);
          return;
        }

        setPaused(true);
        // Perform logout
        const logout = async () => {
          try {
            const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            });
            const response = await data.json();

            if (response.status === 'success') {
              window.location.href = '/login';
            }
          } catch (error) {
            console.log(error);
          }
        };

        setTimeout(async () => {
          await logout();
        }, 3000);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setError(true);
    }
  }, [handleDiscard, handleModalChange]);

  /**
   * Change Password
   */
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [repeatNewPassword, setRepeatNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);

  const handleModalChangePwd = useCallback(async () => {
    setModalChangePwdActive(!modalChangePwdActive);
  }, [modalChangePwdActive]);

  const handleUpdatePwd = useCallback(async () => {
    try {
      if (newPassword !== repeatNewPassword) {
        setPasswordError(true);
        return;
      }
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/password`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          password: password,
          newPassword: newPassword,
        }),
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        // Redirect to login
        if (user.email === defaultState.email) {
          const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
            },
          });
          const response = await data.json();

          if (response.status === 'success') {
            setActive(true);
            setTimeout(() => {
              window.location.href = '/login';
            }, 3000);
          }
        }
      } else if (response.status === 'wrong_password') {
        setPwdError(true);
      } else {
        setError(true);
      }
      setModalChangePwdActive(false);
    } catch (error) {
      console.log(error);
      handleDiscard();
      setError(true);
    }
  }, [newPassword, repeatNewPassword, password, user.email, defaultState.email, handleDiscard]);

  /**
   * Manage stripe payment
   */
  const handleCreateCustomerPortal = useCallback(async () => {
    try {
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/create-customer-portal-session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();

      if (response.status === 'success') {
        // If user does not have an active subscription redirect to checkout page
        // otherwise redirect to customer portal
        if (response.subscription === false && response.data) {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
          await stripe!.redirectToCheckout({
            sessionId: response.data,
          });
        } else if (response.subscription === true && response.data) {
          window.open(response.data, '_blank')?.focus();
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, []);

  /** Handler */
  const handleNameChange = useCallback((e: string) => {
    setName(e);
    setIsDirty(true);
  }, []);
  const handleEmailChange = useCallback((e: string) => {
    setEmail(e);
    setIsDirty(true);
  }, []);
  const handlePasswordChange = useCallback((e: string) => {
    setPassword(e);
  }, []);
  const handleNewPasswordChange = useCallback((e: string) => {
    setNewPassword(e);
  }, []);
  const handleRepeatNewPasswordChange = useCallback((e: any) => {
    setRepeatNewPassword(e);
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      // contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Fetch account data
   */
  useEffect(() => {
    const fetchAccount = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          setDefaultState({
            name: response.data.name,
            email: response.data.email,
          });
          setName(response.data.name);
          setEmail(response.data.email);
          setStatus(response.data.status);
          setStripeId(response.data.stripe_id);
          setDateCreated(response.data.date_created);

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchAccount();
  }, [active]);

  /**
   * Banner markups
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const bannerWrongPwdMarkup = pwdError && (
    <Layout.Section>
      <Banner title="Password errata" status="critical" onDismiss={() => setPwdError(false)}>
        <p>Si è pregati di ricontrollare la password.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Modal markups
   */
  const modalMarkup = (
    <Modal
      open={modalActive}
      onClose={handleModalChange}
      title="Sospendi account"
      primaryAction={{
        content: 'Sospendi',
        onAction: handlePause,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Elimina account',
          onAction: handleDelete,
          loading: buttonSpinning,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Si è sicuri di voler procedere con la sospensione dell'account?</p>
          <p>
            Una volta che si è proceduto bisognerà nuovamente sottoscrivere un abbonamento. Oppure è possibile rimuovere del tutto l'account con i relativi
            dati.
          </p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  /** Change Pwd */
  const modalChangePwdMarkup = (
    <Modal
      open={modalChangePwdActive}
      onClose={handleModalChangePwd}
      title="Cambia la tua password"
      primaryAction={{
        content: 'Continua',
        onAction: handleUpdatePwd,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleModalChangePwd,
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          {passwordError && (
            <FormLayout.Group>
              <Banner title="La nuova password non coincide" status="critical">
                <p>Si è pregati di ricontrollare.</p>
              </Banner>
            </FormLayout.Group>
          )}
          <FormLayout.Group>
            <TextField autoComplete="on" type="password" label="Password attuale" value={password} onChange={handlePasswordChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="password" label="Nuova password" value={newPassword} onChange={handleNewPasswordChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="password" label="Conferma nuova password" value={repeatNewPassword} onChange={handleRepeatNewPasswordChange} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Render status banner
   */
  const renderStatusBanner = (status: string) => {
    switch (status) {
      case 'trialing':
        return <Badge status="info">Prova</Badge>;

      case 'active':
        return <Badge status="success">Attivo</Badge>;

      default:
        return <Badge status="attention">Disattivato</Badge>;
    }
  };

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      title="Account"
      backAction={{ content: 'Impostazioni', url: '/settings' }}
      primaryAction={{
        content: 'Sospendi account',
        onAction: handleModalChange,
        destructive: true,
      }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {bannerWrongPwdMarkup}
        {/* Panoramica account */}
        <Layout.AnnotatedSection title="Panoramica dell'account" description="Visualizza Termini e condizioni del servizio e informativa sulla privacy.">
          <LegacyCard sectioned>
            <LegacyStack distribution="fill">
              <LegacyStack>
                <TextContainer spacing="tight">
                  <p>
                    <Text as="span" fontWeight="semibold">
                      Membro dal
                    </Text>
                  </p>
                  <p>{parseDateLabel(new Date(dateCreated))}</p>
                </TextContainer>
              </LegacyStack>
              <LegacyStack>
                <TextContainer spacing="tight">
                  <p>
                    <Text as="span" fontWeight="semibold">
                      Piano
                    </Text>
                  </p>
                  <p>
                    <Button plain onClick={handleCreateCustomerPortal}>
                      Gestisci abbonamento
                    </Button>
                  </p>
                </TextContainer>
              </LegacyStack>
              <LegacyStack>
                <TextContainer spacing="tight">
                  <p>
                    <Text as="span" fontWeight="semibold">
                      Stato
                    </Text>
                  </p>
                  {renderStatusBanner(status)}
                </TextContainer>
              </LegacyStack>
            </LegacyStack>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Dettagli account */}
        <Layout.AnnotatedSection title="Dettagli account">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField autoComplete="on" type="text" label="Nome" value={name} onChange={handleNameChange} />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="email"
                  label="Email"
                  value={email}
                  onChange={handleEmailChange}
                  helpText="Verrà utilizzato per l'accesso all'account."
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Password">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Button onClick={() => setModalChangePwdActive(true)}>Cambia la password</Button>
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const toastMarkup = active ? (
    <Toast
      content={
        user.email === defaultState.email ? 'La password è stata aggiornata. Sarai reindirizzato alla pagina di login.' : 'I dati sono stati aggiornati.'
      }
      onDismiss={toggleActive}
    />
  ) : null;

  const toastPausedAccountMarkup = paused ? (
    <Toast content="L'account è stato sospeso. Sarai reindirizzato alla pagina di login." onDismiss={togglePaused} />
  ) : null;

  const toastDeletedAccountMarkup = deleted ? (
    <Toast content="L'account è stato eliminato. Sarai reindirizzato alla pagina di login." onDismiss={toggleDeleted} />
  ) : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {modalMarkup}
      {modalChangePwdMarkup}
      {toastMarkup}
      {toastPausedAccountMarkup}
      {toastDeletedAccountMarkup}
    </Frame>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Text,
  EmptyState,
  FormLayout,
  TextField,
  Tag,
} from '@shopify/polaris';

import axios from 'axios';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import { Customer, RiskFamilyLocation, Member, IRiskFamily } from '../../../types';

import dayjs from 'dayjs';
import it from 'dayjs/locale/it';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../utils/PrivateRoute';

export function NewReportDetailsFamily() {
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Report States
   */
  const [report, setReport] = useState<IRiskFamily | undefined>();
  const [customer, setCustomer] = useState<Customer>();

  /**
   * Location States
   */
  const [reportLocation, setReportLocation] = useState<RiskFamilyLocation>();

  // /**
  //  * Family member states
  //  */
  const [reportMembers, setReportMembers] = useState<Member[]>([]);

  /**
   * Data fetching
   */

  //Report
  useEffect(() => {
    const fetchReport = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/risks/family/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;
        if (data.status === 'success') {
          setCustomer(data.data.user.customer);
          setReport(data.data);
          setReportLocation(data.data.location);
          setReportMembers(data.data.members);
        }
      } catch (error) {
        console.log(error);
        setError(true);
      } finally {
        setIsLoading(false);
      }
    };
    fetchReport();
  }, []);

  /**
   * Customer markup
   */
  const customerMarkup = customer ? (
    <LegacyCard>
      <LegacyCard.Header
        title="Cliente"
        actions={[
          {
            content: 'Vai al cliente',
            url: `/customers/${customer._id}`,
            external: true,
          },
        ]}
      />
      <LegacyCard.Section>
        <Text as="p" fontWeight="semibold" color="subdued">
          {report?.user.lastname} {report?.user.name}
        </Text>
        <Text as="p" fontWeight="semibold" color="subdued">
          {report?.user.email}
        </Text>
      </LegacyCard.Section>
    </LegacyCard>
  ) : null;

  /**
   * Location markup
   */
  const locationMarkup = reportLocation && (
    <FormLayout>
      <FormLayout.Group>
        <TextField label="Metri quadrati dell'immobile" value={reportLocation.mq.toString()} disabled autoComplete="off" />
        <TextField label="Anno di construzione dell'immobile" value={reportLocation.year.toString()} disabled autoComplete="off" />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Valore dell'immobile (€/mq)" value={reportLocation.mq_value.toString()} disabled autoComplete="off" />
        <TextField label="L'abitazione è di proprietà?" value={reportLocation.owner.toString() === '1' ? 'Si' : 'No'} disabled autoComplete="off" />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField
          label="L'abitazione è singola (non ha muri in comune con altre abitazioni)?"
          value={reportLocation.single.toString() === '1' ? 'Si' : 'No'}
          disabled
          autoComplete="off"
        />
        <TextField label="Numero di propietà adiacenti (numero di muri in comune)" value={reportLocation.nearby.toString()} disabled autoComplete="off" />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Si possiedono altri immobili?" value={reportLocation.others.toString() === '1' ? 'Si' : 'No'} disabled autoComplete="off" />
        <TextField label="Si possiedono animali domestici?" value={reportLocation.pets.toString() === '1' ? 'Si' : 'No'} disabled autoComplete="off" />
      </FormLayout.Group>
    </FormLayout>
  );

  /**
   * Family member markup
   */
  const familyMemberMarkup = reportMembers.map((member, index) => (
    <LegacyCard sectioned key={index}>
      <FormLayout>
        <Tag>Componente nucleo</Tag>
        <FormLayout.Group>
          <TextField label="Nome" value={member.name} autoComplete="off" disabled />
          <TextField label="Età" value={member.age.toString()} autoComplete="off" disabled />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="Sesso" value={member.sex} autoComplete="off" disabled />
          <TextField label="Sposato/a?" value={member.married.toString() === '1' ? 'S1' : 'No'} autoComplete="off" disabled />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="Professione" value={member.job} autoComplete="off" disabled />
          <TextField label="Anni contribuiti" value={member.job_year.toString()} autoComplete="off" disabled />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="Reddito netto annuo (€)" value={member.net_income.toString()} autoComplete="off" disabled />
          <TextField label="Risparmio annuo (€)" value={member.annual_saving.toString()} autoComplete="off" disabled />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="In caso di infortunio o malattia, saresti
          sostenuto economicamente da INPS o INAIL?"
            value={member.government_support.toString() === '1' ? 'Si' : 'No'}
            autoComplete="off"
            disabled
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Se per colpa di un'invalidità non riuscissi a
          svolgere le azioni della vita quotidiana,
          riusciresti a sostenere i costi di una badante o
          di una casa di cura, o hai un familiare che può
          prendersi cura di te?"
            value={member.caregiver.toString() === '1' ? 'Si' : 'No'}
            autoComplete="off"
            disabled
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField
            label="Sei soddisfatto del Servizio Sanitario Nazionale?"
            value={member.satisfied_sns.toString() === '1' ? 'Si' : 'No'}
            autoComplete="off"
            disabled
          />
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="Capitale risparmiato ad oggi (€)" value={member.saved.toString()} autoComplete="off" disabled />
        </FormLayout.Group>
      </FormLayout>
    </LegacyCard>
  ));

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page
      title={`${report?.user?.lastname ? report?.user.lastname + ' ' + report?.user.name : report?.user?.name} - ${dayjs(report?.date_created)
        .locale(it)
        .format('DD MMMM YYYY')}`}
      backAction={{ content: 'Reports', url: '/reports/family' }}
      secondaryActions={[
        {
          content: 'Vai al report',
          onAction: () => {
            window.open(`${process.env.REACT_APP_IMBA_URL ?? ''}/analysis/report?token=${report?.token}`, '_blank');
          },
        },
      ]}
    >
      <Layout>
        <Layout.AnnotatedSection title="Cliente" description="Nome Cliente">
          {/* Customer */}
          {customerMarkup}
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Dati Immobile" description="Riepilogo delle risposte">
          <LegacyCard title="Immobile" sectioned>
            {locationMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Dati Famiglia" description="Riepilogo delle risposte">
          <LegacyCard title="Componenti del nucleo familiare" sectioned>
            {familyMemberMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection />
      </Layout>
    </Page>
  );
  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun report presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : error ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
    </Frame>
  );
}

import React, { useCallback, useRef, useState } from 'react';

import { Banner, LegacyCard, Text, Frame, Layout, Page, LegacyStack, Tooltip } from '@shopify/polaris';
import { DataPoint, DataSeries, LineChart, PolarisVizProvider } from '@shopify/polaris-viz';

import { TopBarMarkup, NavigationMarkup, DashboardDatePicker } from '../../components';

import { parseDateLabelChart } from '../../utils/Common';
import { useUser } from '../../utils/PrivateRoute';

export function DashboardAffinity() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(data);
  };

  /**
   * Analytics
   */
  const [policies, setPolicies] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [premioNetto, setPremioNetto] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [commissionsValue, setCommissionsValue] = useState([]);
  const [premioValue, setPremioValue] = useState([]);

  // Pass function to DashboardDatePicker
  const handleAnalyticsAffinity = (data: any) => {
    setPolicies(data.policies || []);
    setQuotes(data.quotes || []);
    setPremioNetto(data.premioNetto || []);
    setCommissions(data.commissions || []);
    setCommissionsValue(data.commissionsValue || []);
    setPremioValue(data.premioValue || []);
  };

  /**
   * Fetch Data
   */

  // Policies
  const policiesData: DataPoint[] = [];
  const policiesDataMap = new Map<string, number | undefined>();
  policies.forEach((policy: any) => {
    const date_created = parseDateLabelChart(new Date(policy.date_created));
    if (policiesDataMap.has(date_created)) {
      let tmp = policiesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      policiesDataMap.set(date_created, tmp);
    } else {
      policiesDataMap.set(date_created, 1);
    }
  });

  // Convert policiesDataMap to PoliciesData
  policiesDataMap.forEach((value: number | undefined, key: string) => {
    policiesData.push({
      key: key,
      value: value || null,
    });
  });

  const policiesDataSerie: DataSeries[] = [
    {
      data: policiesData,
      color: '#00FFAA',
      name: 'Polizze emesse',
    },
  ];

  const totalPolicies = policiesData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Premio
  const premioData: DataPoint[] = [];
  const premioDataMap = new Map<string, number | undefined>();
  const premioNettoArray = Array.isArray(premioNetto) ? premioNetto : [];
  premioNettoArray.forEach((premio: any) => {
    const date_created = parseDateLabelChart(new Date(premio.date_created));
    if (premioDataMap.has(date_created)) {
      let tmp = premioDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      premioDataMap.set(date_created, tmp);
    } else {
      premioDataMap.set(date_created, 1);
    }
  });

  // Convert premioDataMap to premioData
  premioDataMap.forEach((value: number | undefined, key: string) => {
    premioData.push({
      key: key,
      value: value || null,
    });
  });

  const premioDataSerie: DataSeries[] = [
    {
      data: premioData,
      color: '#00FFAA',
      name: 'Premio netto',
    },
  ];

  //  Quotes
  const quotesData: DataPoint[] = [];
  const quotesDataMap = new Map<string, number | undefined>();
  const quotesArray = Array.isArray(quotes) ? quotes : [];
  quotesArray.forEach((quote: any) => {
    const date_created = parseDateLabelChart(new Date(quote.date_created));
    if (quotesDataMap.has(date_created)) {
      let tmp = quotesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      quotesDataMap.set(date_created, tmp);
    } else {
      quotesDataMap.set(date_created, 1);
    }
  });

  //Convert premioDataMap to premioData
  quotesDataMap.forEach((value: number | undefined, key: string) => {
    quotesData.push({
      key: key,
      value: value || null,
    });
  });

  const quotesDataSerie: DataSeries[] = [
    {
      data: quotesData,
      color: '#00FFAA',
      name: 'Preventivi',
    },
  ];

  /** Commissions */
  const commissionData: DataPoint[] = [];
  const commissionDataMap = new Map<string, number | undefined>();
  const commissionsArray = Array.isArray(commissions) ? commissions : [];
  commissionsArray.forEach((commission: any) => {
    const date_created = parseDateLabelChart(new Date(commission.date_created));
    // Commission
    if (commissionDataMap.has(date_created)) {
      let tmp = commissionDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      commissionDataMap.set(date_created, tmp);
    } else {
      commissionDataMap.set(date_created, 1);
    }
  });

  // Convert commissionDataMap to commissionData
  commissionDataMap.forEach((value: number | undefined, key: string) => {
    commissionData.push({
      key: key,
      value: value || null,
    });
  });

  const commissionDataSerie: DataSeries[] = [
    {
      data: commissionData,
      color: '#00FFAA',
      name: 'Provvigioni',
    },
  ];

  const commissionChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={commissionDataSerie} showLegend={false} emptyStateText="Nessuna provvigione" />
    </LegacyStack>
  );

  /**
   * Charts
   */

  //Policies
  const policiesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={policiesDataSerie} showLegend={false} emptyStateText="Nessuna polizza" />
    </LegacyStack>
  );

  //Premio
  const premioCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioDataSerie} showLegend={false} emptyStateText="Nessun premio" />
    </LegacyStack>
  );

  // Prevenitivi
  const quotesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={quotesDataSerie} showLegend={false} emptyStateText="Nessun preventivo" />
    </LegacyStack>
  );

  //Banner markups
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  //Page markup for Admin users
  const pageMarkupAffinity = (
    <Page fullWidth title="Dashboard">
      {/* Banners */}
      {bannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <DashboardDatePicker handleAnalyticsAffinity={handleAnalyticsAffinity} userId={user.id} />
          <Text as="span" color="subdued">
            Scegli la data di inizio e fine per visualizzare i dati.
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Preventivi */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di preventivi fatti" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Preventivi</span>
                </Tooltip>
                <>
                  <Text variant="headingMd" as="p">
                    Preventivi totali
                  </Text>
                  <Text variant="headingLg" as="p">
                    {quotes.length}
                  </Text>
                </>
                {quotesCharts}
              </LegacyStack>
            </LegacyCard>
            {/* Commission */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Provvigioni calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Provvigioni</span>
                  </Text>
                </Tooltip>
                <Text variant="headingMd" as="p">
                  Provvigioni totali
                </Text>
                <Text variant="heading2xl" as="p">
                  {Number(commissionsValue).toFixed(2)} €
                </Text>
                {commissionChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col*/}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Policies */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di polizze fatte" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Polizze</span>
                </Tooltip>
                <>
                  <Text variant="headingMd" as="p">
                    Polizze totali
                  </Text>
                  <Text variant="heading2xl" as="p">
                    {totalPolicies}
                  </Text>
                </>
                {policiesCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Premio */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premi calcolati a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Premi</span>
                </Tooltip>
                <>
                  <Text variant="headingMd" as="p">
                    Premi totali
                  </Text>
                  <Text variant="heading2xl" as="p">
                    {Number(premioValue).toFixed(2)} €
                  </Text>
                </>
                {premioCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      <PolarisVizProvider
        themes={{
          Default: {
            chartContainer: {
              backgroundColor: '#fff',
              padding: '0.5rem 0',
            },
            seriesColors: {
              single: '#00FFAA',
            },
            grid: {
              horizontalMargin: 0,
              horizontalOverflow: false,
              // showVerticalLines: true,
              color: '#bfbfbf',
            },
            yAxis: {
              backgroundColor: '#fff',
              labelColor: '#8c8c8c',
            },
            xAxis: {
              labelColor: '#8c8c8c',
            },
            line: {
              hasSpline: true,
              hasArea: true,
            },
          },
        }}
      >
        {pageMarkupAffinity}
      </PolarisVizProvider>
    </Frame>
  );
}

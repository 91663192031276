import React from 'react';
import ReactDOM from 'react-dom/client';
import { render } from 'react-dom';
import '@shopify/polaris/build/esm/styles.css';
import '@shopify/polaris-viz/build/esm/styles.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { AppProvider } from '@shopify/polaris';
import itTranslation from '@shopify/polaris/locales/it.json';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <AppProvider i18n={itTranslation}>
    <App />
  </AppProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

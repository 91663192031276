import React, { useEffect, useState } from 'react';

import { Banner, Frame, Layout, Page, LegacyStack, Spinner } from '@shopify/polaris';

import styles from './logout.module.css';

export function Logout() {
  const [error, setError] = useState(false);

  /**
   * Logout after 5 seconds
   */
  useEffect(() => {
    const logout = async () => {
      try {
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          window.location.href = '/login';
        }
      } catch (error) {
        console.log(error);
      }
    };
    setTimeout(async () => {
      await logout();
    }, 5000);
  }, []);

  /**
   * Error banner
   */
  const bannerMarkup = error && (
    <div style={{ marginBottom: '20px' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page fullWidth>
      <Layout>
        <div className={styles.FullPage}>
          {bannerMarkup}
          <LegacyStack vertical distribution="center">
            <LegacyStack.Item fill>
              <div className={styles.LogoutSpinner}>
                <Spinner accessibilityLabel="Logout spinner" size="large" />
              </div>
            </LegacyStack.Item>
            <LegacyStack.Item>
              <div className={styles.LogoutText}>
                <p>Si sta eseguendo il logout.</p>
                <p>Per favore attendi il completamento dell'operazione.</p>
              </div>
            </LegacyStack.Item>
          </LegacyStack>
        </div>
      </Layout>
    </Page>
  );

  const pageMarkup = actualPageMarkup;

  return <Frame>{pageMarkup}</Frame>;
}

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { LegacyCard, LegacyStack, Spinner, Text } from '@shopify/polaris';

interface Props {
  user: any;
  familyReports: number;
  setFamilyReports: any;
}

export const ReportsCards = ({ user, familyReports, setFamilyReports }: Props) => {
  const [loading, setLoading] = useState(false);
  const [reportsAvaible, setReportsAvaible] = useState(0);
  const [unlimitedReports, setUnlimitedReports] = useState(user.role === 'admin');
  const [individualReports, setIndividualReports] = useState(0);

  /**
   * Fetch Wallet
   */
  useEffect(() => {
    const fetchOrganizations = async () => {
      try {
        setLoading(true);

        const response = await axios.get(`${process.env.REACT_APP_API_URL ?? '/api'}/settings/organization`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const { wallet } = data.data.organization;
          setReportsAvaible(wallet.family_analysis);
          setUnlimitedReports(wallet.family_unlimited ?? false);
        }

        const { reports_family, reports_individual } = data.data;
        setFamilyReports(reports_family);
        setIndividualReports(reports_individual);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (user.role === 'partner') {
      fetchOrganizations();
    }
  }, [user]);

  const totalReports = familyReports + individualReports;

  // Card actions
  const cardActions =
    user.role === 'partner'
      ? [
          {
            content: 'Acquista altri reports',
            url: '/organization/reports/buy',
          },
        ]
      : undefined;

  // Available reports markup
  const availableReportsMarkup = unlimitedReports ? <>&#8734;</> : reportsAvaible;

  const cardsMarkup = (
    <div className="dashboardContent">
      {/* First col */}
      <div className="dashboardContentCol">
        <div className="dashboardContentColPadding">
          <LegacyCard title="Reports disponibili" sectioned actions={cardActions}>
            <LegacyStack vertical>
              <Text variant="heading2xl" as="p">
                {loading ? <Spinner size="small" /> : availableReportsMarkup}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </div>
      </div>
      {/* First col */}
      <div className="dashboardContentCol">
        <div className="dashboardContentColPadding">
          <LegacyCard sectioned title="Report fatti">
            <LegacyStack vertical>
              <Text variant="heading2xl" as="p">
                {loading ? <Spinner size="small" /> : totalReports}
              </Text>
            </LegacyStack>
          </LegacyCard>
        </div>
      </div>
    </div>
  );
  return cardsMarkup;
};

import { Button, DatePicker, Popover, Select, LegacyStack, TextField } from '@shopify/polaris';
import { CalendarMinor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';

import './DashboardDatePicker.scss';
import { useUser } from '../../utils/PrivateRoute';

export function DashboardDatePicker({ handleAnalytics, updateSelectedDates, handleAnalyticsAffinity }: any) {
  const { user } = useUser();
  /**
   * Popover
   */
  const [popoverActive, setPopoverActive] = useState(false);
  const [defaultInput, setDefaultInput] = useState('Questo mese');
  const [input, setInput] = useState('Questo mese');

  // Used on first load
  const [inputIsChanged, setInputIsChanged] = useState(false);

  const togglePopoverActive = useCallback(() => setPopoverActive((popoverActive) => !popoverActive), []);

  const date = new Date();
  const [{ month, year }, setDate] = useState({ month: date.getMonth(), year: date.getFullYear() });
  const [selectedDates, setSelectedDates] = useState({
    start: new Date(date.getFullYear(), date.getMonth(), 1),
    end: new Date(date.getFullYear(), date.getMonth(), date.getDate()),
  });

  const dateOptions = new Map([
    [
      'Questo mese',
      {
        start: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()),
      },
    ],
    [
      'Mese scorso',
      {
        start: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
        end: new Date(new Date().getFullYear(), new Date().getMonth(), 0),
      },
    ],
    [
      'Da inizio anno',
      {
        start: new Date(new Date().getFullYear(), 0, 1),
        end: new Date(),
      },
    ],
    [
      'Da sempre',
      {
        start: new Date(new Date().getFullYear(), -24),
        end: new Date(),
      },
    ],
    [
      'Personalizzato',
      {
        start: new Date(),
        end: new Date(),
      },
    ],
  ]);

  /** Select handler */
  const handleSelectChange = useCallback(
    (e: any) => {
      setInput(e);
      setSelectedDates({
        // @ts-ignore
        start: dateOptions.get(e).start,
        // @ts-ignore
        end: dateOptions.get(e).end,
      });
      setDate({
        // @ts-ignore
        month: dateOptions.get(e)?.start.getMonth(),
        // @ts-ignore
        year: dateOptions.get(e)?.start.getFullYear(),
      });
    },
    [dateOptions],
  );

  const activator = (
    <Button icon={CalendarMinor as any} onClick={togglePopoverActive}>
      {defaultInput}
    </Button>
  );

  /**
   * Date picker
   */
  const handleMonthChange = useCallback((month: any, year: any) => setDate({ month, year }), []);

  /** Date picker selected date handler */
  const handleSelectedDate = useCallback(
    (e: any) => {
      setSelectedDates({
        start: e.start,
        end: e.end,
      });

      // Check if map contains this set of date
      // @ts-ignore
      for (const [key, value] of dateOptions) {
        if (key === 'Personalizzato') continue;

        if (new Date(value.start).toDateString() !== e.start.toDateString() || new Date(value.end).toDateString() !== e.end.toDateString()) {
          // Add Personalizzato to dateOptions
          dateOptions.set('Personalizzato', {
            start: e.start,
            end: e.end,
          });
          setInput('Personalizzato');
        } else if (new Date(value.start).toDateString() === e.start.toDateString() && new Date(value.end).toDateString() === e.end.toDateString()) {
          // Remove Personalizzato
          if (dateOptions.has('Personalizzato')) {
            dateOptions.delete('Personalizzato');
          }

          setInput(key);
          break;
        }
      }
    },
    [dateOptions],
  );

  const parseDateLabel = (date: Date) => {
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
  };

  /**
    Data fetching:
    - fetch analytics
  */

  function fetchAllAnalytics() {
    // DashboardAdmin
    if (user.role === 'admin' || user.role === 'partner') {
      const fetchAnalytics = async () => {
        try {
          const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            body: JSON.stringify({
              start: selectedDates.start.toISOString(),
              end: selectedDates.end.toISOString(),
            }),
          });
          const data = await response.json();

          if (data.status === 'success') {
            const res = {
              totalPrice: data.data.totalPrice ? data.data.totalPrice : 0,
              customers: data.data.customers,
              deadlines: data.data.deadlines,
            };
            handleAnalytics(res);
          } else {
            const res = {
              totalPrice: 0,
              customers: 0,
              deadlines: [],
            };
            handleAnalytics(res);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchAnalytics();
    } else if (user.role === 'affinity') {
      const fetchAnalyticsAffinity = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/analytics/affinity`, {
            method: 'POST',
            credentials: 'include',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            body: JSON.stringify({
              start: selectedDates.start.toISOString(),
              end: selectedDates.end.toISOString(),
            }),
          });
          const data = await response.json();

          if (data.status === 'success') {
            const res = {
              customers: data.data.customers,
              policies: data.data.policies,
              quotes: data.data.quotes,
              premioNetto: data.data.premioNetto,
              premioValue: data.data.premioValue,
              commissions: data.data.commissions,
              commissionsValue: data.data.commissionsValue,
            };
            handleAnalyticsAffinity(res);
          } else {
            const res = {
              customers: [],
              quotes: [],
              commissions: [],
              commissionsValue: [],
              policies: [],
              premioNetto: [],
              premioValue: [],
            };
            handleAnalyticsAffinity(res);
          }
        } catch (error) {
          console.log(error);
        }
      };
      fetchAnalyticsAffinity();
    }
  }

  useEffect(() => {
    fetchAllAnalytics();
  }, []);

  /**
   * Submit handler
   */
  const handleSubmit = useCallback(async () => {
    try {
      setDefaultInput(input);
      setInputIsChanged(true);

      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          start: selectedDates.start.toISOString(),
          end: selectedDates.end.toISOString(),
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setPopoverActive(false);
        const res = {
          totalPrice: data.data.totalPrice ? data.data.totalPrice : 0,
          customers: data.data.customers,
          deadlines: data.data.deadlines,
        };
        updateSelectedDates(selectedDates);
        handleAnalytics(res);
      } else {
        const res = {
          totalPrice: 0,
          customers: 0,
          deadlines: [],
        };
        updateSelectedDates(selectedDates);
        handleAnalytics(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedDates, updateSelectedDates]);

  /**
   * Submit Handler Affinity
   */

  const handleSubmitAffinity = useCallback(async () => {
    try {
      setDefaultInput(input);
      setInputIsChanged(true);

      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/analytics/affinity', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          start: selectedDates.start.toISOString(),
          end: selectedDates.end.toISOString(),
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setPopoverActive(false);
        const res = {
          customers: data.data.customers,
          policies: data.data.policies,
          quotes: data.data.quotes,
          premioNetto: data.data.premioNetto,
          premioValue: data.data.premioValue,
          commissions: data.data.commissions,
          commissionsValue: data.data.commissionsValue,
        };
        updateSelectedDates(selectedDates);
        handleAnalyticsAffinity(res);
      } else {
        const res = {
          customers: [],
          quotes: [],
          policies: [],
          premioNetto: [],
          premioValue: [],
          commissions: [],
          commissionsValue: [],
        };
        updateSelectedDates(selectedDates);
        handleAnalyticsAffinity(res);
      }
    } catch (error) {
      console.log(error);
    }
  }, [selectedDates, updateSelectedDates]);

  const popoverMarkup = (
    <div>
      <Popover active={popoverActive} activator={activator} onClose={togglePopoverActive} ariaHaspopup={false} preferredAlignment="left" fluidContent>
        <Popover.Pane sectioned>
          <LegacyStack vertical wrap>
            <Select label="Intervallo di date" options={Array.from(dateOptions.keys())} value={input} onChange={handleSelectChange} />
            <LegacyStack distribution="fillEvenly">
              <TextField autoComplete="on" label="Inizio" value={parseDateLabel(selectedDates.start)} onChange={undefined} />
              <TextField autoComplete="on" label="Fine" value={parseDateLabel(selectedDates.end)} onChange={undefined} />
            </LegacyStack>
            <div className="popoverDatePicker">
              <DatePicker
                month={month}
                year={year}
                onChange={handleSelectedDate}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                multiMonth
                allowRange
              />
            </div>
          </LegacyStack>
        </Popover.Pane>
        <Popover.Pane fixed>
          <Popover.Section>
            <LegacyStack distribution="equalSpacing">
              <div>
                <Button
                  onClick={() => {
                    setPopoverActive(false);
                  }}
                >
                  Annulla
                </Button>
              </div>
              <div>
                {user && (user.role === 'admin' || user.role === 'partner') ? (
                  <Button primary disabled={input === defaultInput ? (input === 'Personalizzato' ? false : true) : false} onClick={handleSubmit}>
                    Applica
                  </Button>
                ) : null}
                {user && user.role === 'affinity' ? (
                  <Button primary disabled={input === defaultInput ? (input === 'Personalizzato' ? false : true) : false} onClick={handleSubmitAffinity}>
                    Applica
                  </Button>
                ) : null}
              </div>
            </LegacyStack>
          </Popover.Section>
        </Popover.Pane>
      </Popover>
    </div>
  );

  return popoverMarkup;
}

import { User } from './user';

// Enum
export enum CustomerFileTypes {
  identity = 'identity',
  passport = 'passport',
  driver_license = 'driver_license',
  other = 'other',
}

// Customer email type
export type CustomerEmail = {
  address: string;
  email_type: 'home' | 'work' | 'personal';
};

// Customer phone type
export type customerPhoneType = {
  number: string;
  phone_type: 'home' | 'work' | 'personal';
};

// Customer address type
export type CustomerAddress = {
  line: string;
  city: string;
  company?: string;
  province: string;
  zip: string;
  country: string;
};

// Customer file type
export type CustomerFile = {
  key: string;
  title: string;
  file_type: 'idendity' | 'passport' | 'driver_license' | 'other';
};

// Customer birth city type
export type CustomerBirthCity = {
  label: string;
  value: string;
};

// Legal representative type
export type LegalRepresentative = {
  name: string;
  lastname: string;
  email: string;
  phone: string;
  file: {
    key: string;
    title: string;
  };
};

/**
 * Customer interface
 */
export type Customer = {
  _id: string;
  user?: User;
  dealer: any;
  /** The customer type:
   * - private (persona fisica)
   * - company (persona giuridica) */
  type: 'private' | 'company';
  /** The customer gender (only if is private) */
  gender?: 'male' | 'female';
  /** The customer birth date */
  birth_date?: Date;
  /** The customer birth city */
  birth_city?: CustomerBirthCity;
  /** The customer fiscal code */
  fiscal_code?: string;
  /** The customer address */
  address?: CustomerAddress;
  /** The customer citizenship */
  it_citizenship?: boolean;
  /** The customer vat */
  vat?: string;
  /** The customer ateco (only if is company) */
  ateco?: string;
  /** The customer job (only if it is private) */
  job?: any;
  /** The customer employees (only if it is private) */
  employees?: number;
  /** The customer revenues (refer to the last year) */
  revenues?: number;
  /** The customer legal representative (only if it is company) */
  legal_representative?: LegalRepresentative;
  /** The customer notes */
  notes?: string;
  /** The customer files */
  files?: CustomerFile[];
  /** System information */
  date_created: Date;
  date_updated: Date;
  /** Insurance management service flag */
  insurance_management: boolean;
};

import { ChangeEvent, HTMLProps, useState } from 'react';

type Props = {
  selfPointer: string;
  state: number[][];
  setState: (param: any) => void;
} & HTMLProps<HTMLInputElement>;

function EditableRows({ state, setState, selfPointer, ...props }: Props) {
  const [value, setValue] = useState(selfPointer && state.length ? state[JSON.parse(selfPointer)[0]][JSON.parse(selfPointer)[1]] : 0);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(e.target.value);

    if (!isNaN(newValue) && selfPointer) {
      const arrayPointer = JSON.parse(selfPointer);
      setValue(newValue);
      const newState = state.map((row, rowIndex) => {
        return rowIndex === arrayPointer[0] ? row.map((cell, colIndex) => (colIndex === arrayPointer[1] ? newValue : cell)) : row;
      });
      setState(newState);
    }
  };
  return <input value={value} onChange={(e) => handleChange(e)} min={0} {...props} />;
}

export default EditableRows;

import React, { useEffect, useState } from 'react';

import { Banner, LegacyCard, Text, Layout, Page, Spinner, LegacyStack, Tooltip } from '@shopify/polaris';
import { DataPoint, DataSeries, LineChart } from '@shopify/polaris-viz';

import { AnalyticsDatePicker } from '../../components';
import { useUser } from '../../utils/PrivateRoute';
import { parseDateLabelChart } from '../../utils/Common';

type AnalyticsPartnerProps = {
  isLoading: boolean;
  setLoading: any;
};

export function AnalyticsPartner({ isLoading, setLoading }: AnalyticsPartnerProps) {
  /**
   * State
   */
  const { user } = useUser();
  const [customers, setCustomers] = useState([]);
  const [quotes, setQuotes] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [premioNetto, setPremioNetto] = useState([]);

  /**
   * Fetch Data
   */

  //Customers
  const customersData: DataPoint[] = [];
  const customersDataMap = new Map<string, number | undefined>();
  customers.forEach((customer: any) => {
    const date_created = parseDateLabelChart(new Date(customer.date_created));
    if (customersDataMap.has(date_created)) {
      let tmp = customersDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      customersDataMap.set(date_created, tmp);
    } else {
      customersDataMap.set(date_created, 1);
    }
  });

  // Convert customersDataMap to customersData
  customersDataMap.forEach((value: number | undefined, key: string) => {
    customersData.push({
      key: key,
      value: value || null,
    });
  });

  const customersDataSerie: DataSeries[] = [
    {
      data: customersData,
    },
  ];

  const totalCustomers = customersData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  //Quotes

  const quotesData: DataPoint[] = [];
  const quotesDataMap = new Map<string, number | undefined>();
  quotes.forEach((quote: any) => {
    const date_created = parseDateLabelChart(new Date(quote.date_created));
    if (quotesDataMap.has(date_created)) {
      let tmp = quotesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      quotesDataMap.set(date_created, tmp);
    } else {
      quotesDataMap.set(date_created, 1);
    }
  });

  // Convert quotesDataMap to quotesData
  quotesDataMap.forEach((value: number | undefined, key: string) => {
    quotesData.push({
      key: key,
      value: value || null,
    });
  });

  const quotesDataSerie: DataSeries[] = [
    {
      data: quotesData,
    },
  ];

  const totalQuotes = quotesData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  //Policies

  const policiesData: DataPoint[] = [];
  const policiesDataMap = new Map<string, number | undefined>();
  policies.forEach((policy: any) => {
    const date_created = parseDateLabelChart(new Date(policy.date_created));
    if (policiesDataMap.has(date_created)) {
      let tmp = policiesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      policiesDataMap.set(date_created, tmp);
    } else {
      policiesDataMap.set(date_created, 1);
    }
  });

  //Convert policiesDataMap to PoliciesData
  policiesDataMap.forEach((value: number | undefined, key: string) => {
    policiesData.push({
      key: key,
      value: value || null,
    });
  });

  const policiesDataSerie: DataSeries[] = [
    {
      data: policiesData,
    },
  ];

  const totalPolicies = policiesData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  // Premio
  const premioData: DataPoint[] = [];
  const premioDataMap = new Map<string, number | undefined>();
  premioNetto.forEach((premio: any) => {
    const date_created = parseDateLabelChart(new Date(premio.date_created));
    const totalPrice = premio.total_price;
    if (premioDataMap.has(date_created)) {
      let tmp = premioDataMap.get(date_created);
      // @ts-ignore
      tmp += totalPrice;
      premioDataMap.set(date_created, tmp);
    } else {
      premioDataMap.set(date_created, totalPrice);
    }
  });

  //Convert premioDataMap to premioData
  premioDataMap.forEach((value: number | undefined, key: string) => {
    premioData.push({
      key: key,
      value: value || null,
    });
  });

  const premioDataSerie: DataSeries[] = [
    {
      data: premioData,
    },
  ];

  const totalPremio = premioData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  /**
   * Charts
   */

  //Customers
  const customerCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={customersDataSerie} showLegend={false} emptyStateText="Nessun cliente" />
    </LegacyStack>
  );

  //Quotes
  const quotesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={quotesDataSerie} showLegend={false} emptyStateText="Nessun quote" />
    </LegacyStack>
  );

  //Policies
  const policiesCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={policiesDataSerie} showLegend={false} emptyStateText="Nessun policy" />
    </LegacyStack>
  );
  //Premio
  const premioCharts = (
    <LegacyStack vertical>
      <Text variant="headingSm" as="p">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioDataSerie} showLegend={false} emptyStateText="Nessun policy" />
    </LegacyStack>
  );

  // Pass function to DashboardDatePicker
  const handleAnalyticsPartner = (data: any) => {
    setCustomers(data.customers);
    setQuotes(data.quotes);
    setPolicies(data.policies);
    setPremioNetto(data.premioNetto);
  };
  console.log(customers, premioNetto, policies, quotes);

  //BAnner markups
  const [error, setError] = useState(false);
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  useEffect(() => {
    if (customersData.length !== 0 || quotesData.length !== 0 || policiesData.length !== 0 || premioData.length !== 0) {
      setLoading(false);
    }
  }, [customersData, quotesData, policiesData, premioData, setLoading]);

  /**
   * Page markup
   */
  //Page markup for Admin users
  const pageMarkupPartner = (
    <Page fullWidth title="Dashboard">
      {/* Banners */}
      {bannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <AnalyticsDatePicker handleAnalyticsPartner={handleAnalyticsPartner} setLoading={setLoading} />
          <Text as="span" color="subdued">
            Scegli la data di inizio e fine per visualizzare i dati.
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di clienti" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Clienti</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale clienti
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalCustomers}
                    </Text>
                  </>
                )}
                {customerCharts}
              </LegacyStack>
            </LegacyCard>
            {/* Quotes */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di preventivi fatte" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Preventivi</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale preventivi
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalQuotes}
                    </Text>
                  </>
                )}
                {quotesCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col*/}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numeri di polizze fatte" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Polizze</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Polizze totale
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalPolicies}
                    </Text>
                  </>
                )}
                {policiesCharts}
              </LegacyStack>
            </LegacyCard>
            {/* Premio */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premi calcolati a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <span className="cardTitle">Premi</span>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale Premi
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {totalPremio}
                    </Text>
                  </>
                )}
                {premioCharts}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
    </Page>
  );

  return pageMarkupPartner;
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Text,
  Banner,
  Filters,
  EmptyState,
  FilterInterface,
  IndexTable,
  useIndexResourceState,
  Badge,
  Toast,
  Select,
  Loading,
  FormLayout,
  Modal,
  ChoiceList,
  ButtonGroup,
  Button,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';
import axios, { AxiosError } from 'axios';
import { OrganizationTemplate } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';

export function Templates() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [update, setUpdate] = useState(false);

  const [fileUploadingActive, setFileUploadingActive] = useState(false);
  const toggleFileUploadingActive = useCallback(() => setFileUploadingActive((fileUploadingActive) => !fileUploadingActive), []);

  const [fileUploadedActive, setFileUploadedActive] = useState(false);
  const toggleFileUploadedActive = useCallback(() => setFileUploadedActive((fileUploadedActive) => !fileUploadedActive), []);

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * File states
   */
  const [taggedWith, setTaggedWith] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<OrganizationTemplate[]>([]);
  const [frontItems, setFrontItems] = useState<OrganizationTemplate[]>([]);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'template',
    plural: 'templates',
  };

  const templateTypeOptions = [
    { label: 'Preventivo', value: 'quote' },
    { label: 'Ricevuta', value: 'receipt' },
  ];
  const [type, setType] = useState<'quote' | 'receipt'>('quote');

  const [productOptions, setProductOptions] = useState<{ value: any; label: string }[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);

  const [isEditing, setIsEditing] = useState(false);
  const [templateId, setTemplateId] = useState('');

  /** Empty fields */
  const [emptyFields, setEmptyFields] = useState({
    type: false,
  });

  /**
   * Handle type change
   */
  const handleTypeChange = useCallback((value: any) => {
    if (emptyFields.type) {
      setEmptyFields((emptyFields) => ({ ...emptyFields, type: false }));
    }

    setType(value);
  }, []);

  /**
   * Handle product change
   */
  const handleProductChange = useCallback((value: any) => {
    setSelectedProducts(value);
  }, []);

  /**
   * Fetch organization files
   */
  useEffect(() => {
    const fetchOrganizationTemplates = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/templates`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            timeout: 10000,
          },
        );
        const data = response.data;
        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response);
        }
      } finally {
        setIsLoading(false);
      }
    };
    const fetchOrganizationProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/products`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
            timeout: 10000,
          },
        );
        const data = response.data;
        if (data.status === 'success') {
          const tmp: any[] = [];
          data.data.forEach((product: any) => {
            tmp.push({
              label: product.product.name,
              value: product.product._id,
            });
          });
          setProductOptions(tmp);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganizationTemplates();
    fetchOrganizationProducts();
  }, [user.organization, update]);

  /**
   * Handle upload template
   */
  const handleUploatTemplate = useCallback(() => {
    try {
      if (uploadModalOpen) {
        setUploadModalOpen(false);
      }

      uploadInputRef.current?.click();
    } catch (error) {
      console.log(error);
    }
  }, [uploadModalOpen]);

  /**
   * Handle template change
   */
  const handleTemplateChange = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (!event.target.files) return;

        // Create form data
        const formData = new FormData();
        formData.append('document', event.target.files[0]);
        formData.append('type', type);
        formData.append('products', JSON.stringify(selectedProducts));

        // Activate toast
        toggleFileUploadingActive();

        // Send request
        const response = await axios.post(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/templates`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );
        // Handle response
        const data = response.data;
        if (data.status === 'success') {
          toggleFileUploadedActive();
          setUpdate(!update);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response);
          setError(true);
        }
      } finally {
        setButtonSpinning(false);
        setSelectedProducts([]);
      }
    },
    [type, selectedProducts, update],
  );

  /**
   * Handle update template
   */
  const handleUpdateTemplate = useCallback(
    async (id: string) => {
      try {
        setButtonSpinning(true);

        // Update template
        const tmp = items.map((item) => {
          if (item._id === id) {
            item.type = type;
            item.products = selectedProducts;
          }
          return item;
        });

        const response = await axios.put(
          (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/templates/`,
          {
            templates: tmp,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            },
          },
        );

        const data = response.data;
        if (data.status === 'success') {
          setUpdate(!update);
          setUploadModalOpen(false);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          setError(true);
        }
      } finally {
        setButtonSpinning(false);
        setIsEditing(false);
      }
    },
    [items, type, selectedProducts, update],
  );

  /**
   * Handle edit template modal
   */
  const handleEdit = useCallback(
    (id: string) => {
      const item = items.find((item) => item._id === id);
      if (item) {
        console.log(item);
        setUploadModalOpen(true);
        setIsEditing(true);
        setTemplateId(id);
        setType(item.type);
        setSelectedProducts(item.products ? item.products : []);
      }
    },
    [items],
  );

  /**
   * Filtering
   */
  function disambiguateLabel(key: string, value: string | null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return '';
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers
   */
  const resourceIDResolver = (items: { _id: any }) => {
    return items._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Render row
   */
  const rowMarkup = frontItems.map((item: OrganizationTemplate, index: number) => {
    const { _id: id, name, type, products } = item;

    let typeMarkup;
    if (type === 'quote') {
      typeMarkup = (
        <Badge status="info" progress="incomplete">
          Preventivo
        </Badge>
      );
    } else if (type === 'receipt') {
      typeMarkup = (
        <Badge status="info" progress="complete">
          Ricevuta
        </Badge>
      );
    }

    const productName = products?.length ? `${products.length} ${products.length > 1 ? 'prodotti' : 'prodotto'}` : 'Nessun prodotto';

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{typeMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{productName}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ float: 'right' }}>
            <ButtonGroup segmented>
              <Button size="slim" onClick={() => handleEdit(id)}>
                Modifica
              </Button>
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Upload modal
   */
  const uploadModalMarkup = (
    <Modal
      open={uploadModalOpen}
      onClose={() => setUploadModalOpen(false)}
      title="Carica un template"
      primaryAction={{
        content: !isEditing ? 'Scegli il template' : 'Modifica',
        onAction: () => (!isEditing ? handleUploatTemplate() : handleUpdateTemplate(templateId)),
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setUploadModalOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <Select label="Tipo di template" value={type} options={templateTypeOptions} onChange={handleTypeChange} />
          <ChoiceList
            title="Seleziona i prodotti da abbinare al template"
            allowMultiple
            choices={productOptions}
            selected={selectedProducts}
            onChange={handleProductChange}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Empty list markup
   */
  const emptyListMarkup = !items.length && (
    <EmptyState
      heading="Carica e gestisci i tuoi templates"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/87c89b0a776f2d13fdde186958e6a83c28fd4a6ca1ff7f04bb16e6629bb8f6de.svg"
      action={{
        content: 'Carica un template',
        onAction: () => setUploadModalOpen(true),
        loading: buttonSpinning,
      }}
    >
      <p>Scarica il template di esempio</p>
    </EmptyState>
  );

  /**
   * Banner & toast markups
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const toastFileUploadingMarkup = fileUploadingActive && <Toast content="Upload del file..." onDismiss={toggleFileUploadingActive} />;

  const toastFileUploadedMarkup = fileUploadedActive && <Toast content="Il file è stato caricato" onDismiss={toggleFileUploadedActive} />;

  /**
   * Page markup
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const actualPageMarkup = (
    <Page
      title="Templates"
      backAction={{ content: 'Impostazioni', url: '/settings' }}
      primaryAction={{
        content: 'Carica un template',
        onAction: () => setUploadModalOpen(true),
        loading: buttonSpinning,
      }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}

        {/* Input file */}
        <input type="file" hidden ref={uploadInputRef} onChange={handleTemplateChange} />

        {/* Templates list */}
        <Layout.Section>
          <LegacyCard>
            <div style={{ padding: '16px', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  // @ts-ignore
                  appliedFilters={appliedFilters}
                  queryPlaceholder={'Filtra templates'}
                  onQueryChange={setQueryValue}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
              <div style={{ paddingLeft: '0.4rem' }}>
                <Select
                  labelInline
                  label="Ordina per"
                  options={[
                    { label: 'Data aggiunta template (dal più recente)', value: 'DATE_CREATED_DESC' },
                    { label: 'Data aggiunta template (dal meno recente)', value: 'DATE_CREATED_ASC' },
                  ]}
                  value={sortValue}
                  onChange={(selected) => {
                    setSortValue(selected);
                  }}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              emptyState={emptyListMarkup}
              loading={isLoading}
              itemCount={frontItems.length}
              selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
              hasMoreItems
              onSelectionChange={handleSelectionChange}
              headings={[{ title: 'Nome' }, { title: 'Tipologia' }, { title: 'Prodotti' }]}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  const loadingPageMarkup = isLoading && <Loading />;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingPageMarkup}
      {actualPageMarkup}
      {uploadModalMarkup}
      {toastFileUploadingMarkup}
      {toastFileUploadedMarkup}
    </Frame>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import {
  LegacyCard,
  ContextualSaveBar,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Toast,
  Banner,
  FormLayout,
  TextField,
  DropZone,
  Thumbnail,
  Select,
  Modal,
} from '@shopify/polaris';
import { TopBarMarkup, NavigationMarkup } from '../../../../components';
import './CompaniesDetails.scss';
import { useUser } from '../../../../utils/PrivateRoute';
import { useParams, useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { Company, CompanyFile } from '../../../../types';

export function CompaniesDetails() {
  const { user } = useUser();
  const params = useParams();
  const navigate = useNavigate();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [saveError, setSaveError] = useState(false);
  const [conflictError, setConflictError] = useState(false);

  const [company, setCompany] = useState<Company>({
    name: '',
    referent: {
      name: '',
      lastname: '',
      email: '',
      phone: '',
    },
    billing: {
      email: '',
      day: 1,
    },
    platform_link: '',
    phone: '',
    logo: undefined,
  });
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
  const [isDirty, setIsDirty] = useState(false);
  const [success, setSuccess] = useState(false);
  const [deleted, setDeleted] = useState(false);

  /** Delete modal */
  const [buttonDeleteSpinning, setButtonDeleteSpinning] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);

  const handleDeleteModal = useCallback(() => {
    setDeleteModalActive(!deleteModalActive);
  }, [deleteModalActive]);

  /** Loading markup */
  const loadingMarkup = isLoading ? <Loading /> : null;

  /** Toast */
  const toggleSuccess = useCallback(() => setSuccess((success) => !success), []);
  const toastMarkup = success ? <Toast content="Compagnia modificata con successo!" onDismiss={toggleSuccess} /> : null;

  const toggleDeleted = useCallback(() => setDeleted((deleted) => !deleted), []);
  const toastDeletedMarkup = deleted ? <Toast content="Compagnia eliminata con successo!" onDismiss={toggleDeleted} /> : null;

  /**
   * Data fetching
   */
  useEffect(() => {
    const fetchCompanyDetails = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/companies/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setCompany(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCompanyDetails();
  }, [params.id]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setSaveError(false);
      setButtonSpinning(true);

      // Create form data
      const formData = new FormData();
      if (company.logo) {
        formData.append('document', company.logo);
      }
      if (company.name) {
        formData.append('name', company.name);
      }
      if (company.referent) {
        formData.append('referent', JSON.stringify(company.referent));
      }
      if (company.billing) {
        formData.append('billing', JSON.stringify(company.billing));
      }
      if (company.platform_link) {
        formData.append('platform_link', company.platform_link);
      }
      if (company?.phone) {
        formData.append('phone', company.phone);
      }

      console.log(formData);

      const response = await axios.put((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/companies/${params.id}`, formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        setSuccess(true);
        setTimeout(() => {
          navigate(`/admin/companies`);
        }, 2000);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
    setIsDirty(false);
  }, [params.id, company, navigate]);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: () => navigate('/admin/companies'),
      }}
    />
  ) : null;

  /** Company drop zone logo */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    // Add necessary file properties for CompanyFile type
    const newLogo: CompanyFile = Object.assign(file, {
      key: file.name,
      title: file.name,
    });

    setCompany((prevCompany) => ({
      ...prevCompany,
      logo: newLogo,
    }));
    setIsDirty(true);
  }, []);
  /** Handle input changes */
  const handleChange = (field: keyof Company | 'referent' | 'billing', value: any, subField?: string) => {
    setCompany((prevCompany) => {
      if (field === 'referent' && subField) {
        return { ...prevCompany, referent: { ...prevCompany.referent, [subField]: value } };
      } else if (field === 'billing' && subField) {
        return { ...prevCompany, billing: { ...prevCompany.billing, [subField]: value } };
      } else {
        return { ...prevCompany, [field]: value };
      }
    });
    setIsDirty(true);
  };

  const uploadedFile = company.logo && (
    <LegacyStack alignment="center">
      <Thumbnail size="large" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />
      <div>{company.logo.title}</div>
    </LegacyStack>
  );

  const existingLogo = company?.logo && (
    <LegacyStack alignment="center">
      <Thumbnail size="large" alt={company.logo.title} source={process.env.REACT_APP_BLOB_IMAGES_URL + company.logo.key} />
      <div>{company.logo.title}</div>
    </LegacyStack>
  );

  const fileUpload = !company?.logo && <DropZone.FileUpload />;

  /**
   * Delete company
   */
  const handleDelete = useCallback(async () => {
    try {
      setButtonDeleteSpinning(true);
      const response = await axios.delete(`${process.env.REACT_APP_API_URL ?? '/api'}/admin/companies/${params.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });

      const data = response.data;

      if (data.status === 'success') {
        handleDeleteModal();
        setDeleted(true);
        setTimeout(() => {
          navigate(`/admin/companies`);
        }, 2000);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      const status = axiosError.response?.status || 500;

      if (status === 409) {
        setConflictError(true);
      } else {
        setSaveError(true);
      }
      handleDeleteModal();
    } finally {
      setButtonDeleteSpinning(false);
    }
  }, [params.id, navigate, handleDeleteModal]);

  /**
   * Delete modal
   */
  const deleteModalMarkup = (
    <Modal
      open={deleteModalActive}
      onClose={handleDeleteModal}
      title="Elimina compagnia"
      primaryAction={{
        content: 'Elimina',
        onAction: handleDelete,
        loading: buttonDeleteSpinning,
        destructive: true,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleDeleteModal,
        },
      ]}
    >
      <Modal.Section>
        <p>Sei sicuro di voler eliminare questa compagnia?</p>
      </Modal.Section>
    </Modal>
  );

  /**
   * Banner & toast
   */
  const bannerMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const conflictBannerMarkup = conflictError && (
    <Layout.Section>
      <Banner title="Impossibile eliminare la compagnia" status="critical" onDismiss={() => setConflictError(false)}>
        <p>Non è possibile eliminare la compagnia poiché è associata a un prodotto.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = company && (
    <Page
      title="Modifica compagnia"
      backAction={{ content: 'Compagnie', url: '/admin/companies' }}
      primaryAction={{ content: 'Elimina compagnia', onAction: handleDeleteModal, loading: buttonDeleteSpinning, destructive: true }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {conflictBannerMarkup}

        {/* Dettagli compagnia */}
        <Layout.Section>
          <LegacyCard title="Dettagli compagnia" sectioned>
            <FormLayout>
              <FormLayout.Group>
                <TextField label="Nome" value={company?.name || ''} onChange={(value) => handleChange('name', value)} autoComplete="off" />
              </FormLayout.Group>
              <FormLayout.Group>
                <DropZone accept={validImageTypes.join(',')} type="image" onDrop={handleDropZoneDrop}>
                  {uploadedFile}
                  {!uploadedFile && existingLogo}
                  {!uploadedFile && !existingLogo && fileUpload}
                </DropZone>
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Contatti e piattaforma */}
        <Layout.Section>
          <LegacyCard sectioned title="Contatti e Piattaforma">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Link alla Piattaforma"
                  value={company.platform_link || ''}
                  onChange={(value) => handleChange('platform_link', value)}
                  autoComplete="off"
                />
                <TextField
                  label="Numero di Telefono Azienda"
                  value={company.phone || ''}
                  onChange={(value) => handleChange('phone', value)}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Referente */}
        <Layout.Section>
          <LegacyCard sectioned title="Dati Referente">
            <FormLayout>
              <FormLayout.Group>
                <TextField label="Nome" value={company.referent?.name || ''} onChange={(value) => handleChange('referent', value, 'name')} autoComplete="off" />
                <TextField
                  label="Cognome"
                  value={company.referent?.lastname || ''}
                  onChange={(value) => handleChange('referent', value, 'lastname')}
                  autoComplete="off"
                />
              </FormLayout.Group>
              <FormLayout.Group>
                <TextField
                  label="Email"
                  value={company.referent?.email || ''}
                  onChange={(value) => handleChange('referent', value, 'email')}
                  autoComplete="off"
                />
                <TextField
                  label="Numero di Telefono"
                  value={company.referent?.phone || ''}
                  onChange={(value) => handleChange('referent', value, 'phone')}
                  autoComplete="off"
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        {/* Dati Fatturazione */}
        <Layout.Section>
          <LegacyCard sectioned title="Dati Fatturazione">
            <FormLayout>
              <FormLayout.Group>
                <TextField
                  label="Email"
                  value={company.billing?.email || ''}
                  onChange={(value) => handleChange('billing', value, 'email')}
                  autoComplete="off"
                />
                <Select
                  label="Giorno del Mese per la Fatturazione"
                  options={Array.from({ length: 31 }, (_, i) => ({
                    label: (i + 1).toString(),
                    value: (i + 1).toString(),
                  }))}
                  value={company.billing?.day ? company.billing.day.toString() : undefined}
                  onChange={(value) => handleChange('billing', parseInt(value, 10), 'day')}
                />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {contextualSaveBarMarkup}
      {pageMarkup}
      {deleteModalMarkup}
      {toastMarkup}
      {toastDeletedMarkup}
    </Frame>
  );
}

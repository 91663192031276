import { LegacyCard, EmptyState, Badge, Filters, Text, Pagination, Select, IndexTable, Link, useIndexResourceState } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';
import { parseDateLabel } from '../../utils/Common';

import styles from './ClaimList.module.css';

export function ClaimList({ setActive, setSaveError }: any) {
  const [queryValue, setQueryValue] = useState('');
  const [taggedWith, setTaggedWith] = useState(null);
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<any>([]);
  const [total, setTotal] = useState(-1);
  const [isFirstPage, setIsFirstPage] = useState(true);
  const [isLastPage, setIsLastPage] = useState(true);
  const [frontItems, setFrontItems] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  const resourceName = {
    singular: 'sinistro',
    plural: 'sinistri',
  };

  /**
   * Data fetching
   */
  useEffect(() => {
    let customers: any = [];
    setIsLoading(true);

    const fetchClients = async () => {
      try {
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/customers', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ id: item._id, name: item.name });
          }
          customers = tmp;
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchClients();

    // Fetch claims
    const fetchClaims = async () => {
      try {
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/claims', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];

          setTotal(response.data.length);
          for (const item of response.data) {
            const itemCustomer = item.customer_id;
            let customerName = '';

            customers.forEach((customer: any) => {
              if (customer.id === itemCustomer) customerName = customer.name;
            });

            tmp.push({ ...item, customer: customerName });
          }
          setFrontItems(tmp);
          setItems(tmp);
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };
    fetchClaims().then(() => setIsLoading(false));
  }, [update]);

  /**
   * Handle search query
   */
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleQueryValueRemove();
  }, [handleQueryValueRemove]);

  /**
   * Filters
   */
  function disambiguateLabel(key: any, value: any) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return value;
    }
  }

  function isEmpty(value: any) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: any = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          onRemove: () => alert('to do'),
        },
      ]
    : [];

  // Filtering
  useEffect(
    () => {
      const filterItems = () => {
        const filteredItems = items.filter((item: any) => {
          // return item.numero_polizza.toLowerCase().includes(queryValue.toLowerCase());
          return item.customer.toLowerCase().includes(queryValue);
        });
        setFrontItems(filteredItems);
      };
      filterItems();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryValue],
  );

  const handleQueryChange = useCallback(
    (e: string) => {
      setQueryValue(e);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [queryValue],
  );

  const filterControl = (
    <Filters
      queryValue={queryValue}
      filters={filters}
      onQueryChange={handleQueryChange}
      onQueryClear={handleQueryValueRemove}
      onClearAll={handleClearAll}
    ></Filters>
  );

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_EVENT_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_event) - new Date(b.date_event));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_EVENT_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_event) - new Date(a.date_event));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CLOSED_ASC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_closed) - new Date(b.date_closed));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CLOSED_DESC') {
      const tmp = [...frontItems];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_closed) - new Date(a.date_closed));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers & markup
   */
  const resourceIDResolver = (products: any) => {
    return products._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  // Delete selected notes
  const handleDelete = useCallback(async () => {
    try {
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          claims: selectedResources,
        }),
      });
      const response = await data.json();

      if (response.status === 'success') {
        setActive(true);
        setUpdate(!update);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    }
  }, [selectedResources, setActive, setSaveError, update]);

  const promotedBulkActions = [
    {
      content: 'Elimina sinistri',
      onAction: handleDelete,
    },
  ];

  const rowMarkup = frontItems.map((item: any, index: any) => {
    const str = item.status.charAt(0).toUpperCase() + item.status.slice(1);
    let statusMarkup;
    if (item.status === 'aperto') {
      statusMarkup = (
        <Badge status="attention" progress="partiallyComplete">
          {str}
        </Badge>
      );
    } else if (item.status === 'liquidato') {
      statusMarkup = (
        <Badge status="success" progress="complete">
          {str}
        </Badge>
      );
    } else if (item.status === 'senza seguito') {
      statusMarkup = <Badge progress="incomplete">{str}</Badge>;
    }

    return (
      <IndexTable.Row id={item._id} key={item._id} selected={selectedResources.includes(item._id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            <Link url={`/claims/${item._id}`} removeUnderline monochrome data-primary-link>
              <a style={{ color: 'inherit', textDecoration: 'none' }} data-primary-link>
                {item.customer ? `${item.customer}` : <Badge>Mancante</Badge>}
              </a>
            </Link>
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{statusMarkup}</IndexTable.Cell>
        <IndexTable.Cell>{parseDateLabel(new Date(item.date_event))}</IndexTable.Cell>
        <IndexTable.Cell>{parseDateLabel(new Date(item.date_created))}</IndexTable.Cell>
        <IndexTable.Cell>{parseDateLabel(new Date(item.date_closed))}</IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Empty state markup
   */
  const emptyList = (
    <EmptyState heading="Gestisci i sinistri" image="https://cdn.shopify.com/shopifycloud/web/assets/v1/e7b58a8b2e612fe6cf6f8c9e53830b70.svg">
      <p>Qua è dove puoi gestire i tuoi sinistri</p>
    </EmptyState>
  );

  /**
   * Pagination markup
   */
  const paginationMarkup =
    items.length > 0 ? (
      <div className={styles.CustomerListFooter}>
        <Pagination
          hasPrevious={!isFirstPage}
          hasNext={!isLastPage}
          // onPrevious={this.handlePreviousPage}
          // onNext={this.handleNextPage}
        />
      </div>
    ) : null;

  /**
   * Markup with items
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const customerListMarkup = (
    <LegacyCard>
      <div style={{ padding: '16px', display: 'flex' }}>
        <div style={{ flex: 1 }}>
          <Filters
            queryValue={queryValue}
            filters={filters}
            appliedFilters={appliedFilters}
            queryPlaceholder={'Filtra clienti'}
            onQueryChange={setQueryValue}
            onQueryClear={handleQueryValueRemove}
            onClearAll={handleClearAll}
          />
        </div>
        <div style={{ paddingLeft: '0.4rem' }}>
          <Select
            labelInline
            label="Ordina per"
            options={[
              { label: 'Data aggiunta sinistro (dal più recente)', value: 'DATE_CREATED_DESC' },
              { label: 'Data aggiunta sinistro (dal meno recente)', value: 'DATE_CREATED_ASC' },
              { label: 'Data evento sinistro (dal più recente)', value: 'DATE_EVENT_DESC' },
              { label: 'Data evento sinistro (dal meno recente)', value: 'DATE_EVENT_ASC' },
              { label: 'Data chiusura sinistro (dal più recente)', value: 'DATE_CLOSED_DESC' },
              { label: 'Data chiusura sinistro (dal meno recente)', value: 'DATE_CLOSED_ASC' },
            ]}
            value={sortValue}
            onChange={(selected) => {
              setSortValue(selected);
            }}
          />
        </div>
      </div>
      <IndexTable
        resourceName={resourceName}
        emptyState={emptyList}
        itemCount={frontItems.length}
        selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
        hasMoreItems
        loading={isLoading}
        promotedBulkActions={promotedBulkActions}
        onSelectionChange={handleSelectionChange}
        headings={[{ title: 'Cliente' }, { title: 'Stato' }, { title: 'Data evento' }, { title: 'Data apertura' }, { title: 'Data chiusura' }]}
        sort
      >
        {rowMarkup}
      </IndexTable>
    </LegacyCard>
  );

  return customerListMarkup;
}

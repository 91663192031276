import React, { useCallback, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Icon,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  UnstyledLink,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import './Settings.scss';
import { AttachmentMajor, BankMajor, CategoriesMajor, PaymentsMajor, ProfileMajor, SettingsMajor } from '@shopify/polaris-icons';
import { useUser } from '../../../utils/PrivateRoute';

export function Settings() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  // ---- Page markup ----
  const style = { '--med-width-rows': 4, '--max-width-rows': 2 } as React.CSSProperties;
  const actualPageMarkup = (
    <Page title="Impostazioni">
      <Layout>
        <Layout.Section>
          <LegacyCard>
            <ul className="settingsList" style={style}>
              {/* General settings */}
              {/* <li className="settingsListItem">
                <UnstyledLink url="/settings/general" className="settingsListItemLink">
                  <div className="settingsListItemIcon">
                    <Icon source={SettingsMajor}></Icon>
                  </div>
                  <div>
                    <p className="settingsListItemTitle">Generali</p>
                    <p className="settingsListItemSubtitle">Visualizza e aggiorna i dettagli della società</p>
                  </div>
                </UnstyledLink>
              </li> */}
              {/* Account settings */}
              <li className="settingsListItem">
                <UnstyledLink url="/settings/account" className="settingsListItemLink">
                  <div className="settingsListItemIcon">
                    {/* <Icon source={ProfileMajor}></Icon> */}
                    <Icon source={ProfileMajor as any} />
                  </div>
                  <div>
                    <p className="settingsListItemTitle">Account</p>
                    <p className="settingsListItemSubtitle">Visualizza le informazioni relative al tuo account</p>
                  </div>
                </UnstyledLink>
              </li>
              {/* Payments settings */}
              {user.role === 'partner' && (
                <li className="settingsListItem">
                  <UnstyledLink url="/settings/payments" className="settingsListItemLink">
                    <div className="settingsListItemIcon">
                      <Icon source={PaymentsMajor as any}></Icon>
                    </div>
                    <div>
                      <p className="settingsListItemTitle">Pagamenti</p>
                      <p className="settingsListItemSubtitle">Visualizza le informazioni di pagamento relative al tuo account</p>
                    </div>
                  </UnstyledLink>
                </li>
              )}
              {/* File settings */}
              {user.role === 'partner' && (
                <li className="settingsListItem">
                  <UnstyledLink url="/settings/templates" className="settingsListItemLink">
                    <div className="settingsListItemIcon">
                      <Icon source={AttachmentMajor as any}></Icon>
                    </div>
                    <div>
                      <p className="settingsListItemTitle">Templates</p>
                      <p className="settingsListItemSubtitle">Carica e gestisci i template della tua organizzazione</p>
                    </div>
                  </UnstyledLink>
                </li>
              )}
            </ul>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}

/**
 * Age
 */
enum Age {
  Young = 'U30',
  Adult = '31-45',
  MiddleAged = '46-65',
  Older = 'O66',
}

/**
 * Work Activity
 */
enum WorkActivity {
  RegulatedProfession = 'Professione regolamentata',
  DigitalProfession = 'Professione digitale',
  OtherProfession = 'Altra professione',
  Entrepreneur = 'Imprenditore',
}
/**
 * Owns Shop
 */
enum OwnsShop {
  Yes = 'Sì',
  No = 'No',
}

/**
 * Has Employees
 */
enum HasEmployees {
  NoneOrOne = 'Nessuno o 1',
  MoreThanTwo = '> 2',
}

/**
 * Has Children
 */
enum HasChildren {
  None = 'Nessuno',
  OneOrMore = '1 o piu',
}

/**
 * Owns Land Or Buildings
 */
enum OwnsLandOrBuildings {
  No = 'No',
  Owned = 'Si di proprietà',
  OwnedWithMortgage = 'Si di proprietà con mutuo',
  Inherited = 'Si di proprietà derivante da donazione',
}

/**
 * Doesn Annual trips
 */
enum AnnualTrips {
  None = 'Nessuno',
  OneToTwo = '1 o 2',
  MoreThanThree = '> 3',
}

/**
 * Risk Individual interface
 */
export interface IRiskIndividual {
  _id: string;
  /** The user linked to the report */
  user: any;
  age: Age;
  work_activity: WorkActivity;
  shops: OwnsShop;
  employees: HasEmployees;
  children: HasChildren;
  lands: OwnsLandOrBuildings;
  vehicles: string;
  flights: AnnualTrips;
  /** Response */
  response: any;
  /** System information */
  date_created: Date;
  date_updated: Date;
}

import {
  Dashboard,
  CustomerAll,
  CompaniesAll,
  CompaniesNew,
  ProductAll,
  CategoryAll,
  Settings,
  Account,
  CustomerNew,
  ProductNew,
  ProductDetails,
  CustomerDetails,
  CompaniesDetails,
  ClaimAll,
  ClaimDetails,
  ClaimNew,
  UserAll,
  UserNew,
  UserDetails,
  Disabled,
  Logout,
  Analytics,
  OrganizationAll,
  OrganizationNew,
  OrganizationDetails,
  OrganizationAnalytics,
  VariantNew,
  VariantDetails,
  OrderAll,
  OrderNew,
  OrderDetails,
  OrganizationProducts,
  Payments,
  Templates,
  Reports,
  OrganizationSettings,
  PolicyAll,
  PolicyDetails,
  QuoteAll,
  QuoteDetails,
  QuestionnaireAll,
  ReportAllBusiness,
  ReportDetailsBusiness,
  ReportAllFamily,
  Guide,
  ReportPurchase,
  NewReportDetailsFamily,
  DashboardAffinity,
  ResourcesMarketing,
  Banners,
  Logo,
  Social,
} from './pages';
import { NewReportFamily } from './pages/Reports/Family/NewReportFamily';

const routes = [
  { path: '/', name: 'Dashboard', component: Dashboard, exact: true },
  { path: '/disabled', name: 'Account disattivato', component: Disabled, exact: true },
  { path: '/logout', name: 'Logout', component: Logout, exact: true },
  { path: '/analytics', name: 'Anaytics', component: Analytics, exact: true },
  /** Customers */
  { path: '/customers', name: 'Clienti', component: CustomerAll, exact: true },
  { path: '/customers/new', name: 'Nuovo cliente', component: CustomerNew, exact: true },
  { path: '/customers/:id', name: 'Dettagli cliente', component: CustomerDetails, exact: true },
  /** Orders */
  { path: '/orders', name: 'Polizze', component: OrderAll, exact: true },
  { path: '/orders/new', name: 'Nuova Polizza', component: OrderNew, exact: true },
  { path: '/orders/:id', name: 'Dettagli polizza', component: OrderDetails, exact: true },
  /** Policies */
  { path: '/policies', name: 'Polizze', component: PolicyAll, exact: true },
  { path: '/policies/new', name: 'Nuova Polizza', component: OrderNew, exact: true },
  { path: '/policies/:id', name: 'Dettagli polizza', component: PolicyDetails, exact: true },
  /** Quotes */
  { path: '/quotes', name: 'Preventivi', component: QuoteAll, exact: true },
  { path: '/quotes/new', name: 'Nuovo Preventivo', component: OrderNew, exact: true },
  { path: '/quotes/:id', name: 'Dettaglio Preventivo', component: QuoteDetails, exact: true },
  /** Questionnaires */
  { path: '/questionnaires', name: 'Questionari', component: QuestionnaireAll, exact: true },
  /** Claims */
  { path: '/claims', name: 'Sinistri', component: ClaimAll, exact: true },
  { path: '/claims/new', name: 'Nuovo sinistro', component: ClaimNew, exact: true },
  { path: '/claims/:id', name: 'Dettagli sinistro', component: ClaimDetails, exact: true },
  /** Reports */
  { path: '/reports', name: 'Reports', component: ReportAllBusiness, exact: true },
  { path: '/reports/family', name: 'Reports Famiglie', component: ReportAllFamily, exact: true },
  { path: '/reports/family/:id', name: 'Dettaglio report famiglia', component: NewReportDetailsFamily, exact: true },
  { path: '/reports/family/new', name: 'Nuovo report', component: NewReportFamily, exact: true },
  { path: '/reports/business', name: 'Reports Business', component: ReportAllBusiness, exact: true },
  { path: '/reports/business/:id', name: 'Dettaglio report business', component: ReportDetailsBusiness, exact: true },

  /** Guide e Risorse */
  //Resources
  { path: '/resources', name: 'Risorise', component: ResourcesMarketing, exact: true },
  { path: '/resources/banners', name: 'Banners', component: Banners, exact: true },
  { path: '/resources/logo', name: 'Loghi', component: Logo, exact: true },
  { path: '/resources/social', name: 'Social', component: Social, exact: true },

  /** Settings */
  { path: '/settings', name: 'Impostazioni', component: Settings, exact: true },
  // Account
  { path: '/settings/account', name: 'Account', component: Account, exact: true },
  // Payments
  { path: '/settings/payments', name: 'Pagamento', roles: ['partner'], component: Payments, exact: true },
  // Files
  { path: '/settings/templates', name: 'Files', roles: ['partner'], component: Templates, exact: true },
  /** Organization */
  { path: '/organization', name: 'Organizzazione', organization: true, component: OrganizationAnalytics, exact: true },
  { path: '/organization/settings', name: 'Impostazioni organizzazione', roles: ['partner'], organization: true, component: OrganizationSettings, exact: true },
  { path: '/organization/reports/buy', name: 'Acquista report', roles: ['partner'], organization: true, component: ReportPurchase, exact: true },
  /** Guide */
  { path: '/guide', name: 'Guida', component: Guide, exact: true },
  /**
   * Admin
   */
  // Users
  { path: '/admin/users', name: 'Utenti', roles: ['admin'], component: UserAll, exact: true },
  { path: '/admin/users/new', name: 'Nuovo utente', roles: ['admin'], component: UserNew, exact: true },
  { path: '/admin/users/:id', name: 'Utente', roles: ['admin'], component: UserDetails, exact: true },
  // Products
  { path: '/admin/products', name: 'Polizze', component: ProductAll, exact: true },
  { path: '/admin/products/new', name: 'Nuovo Prodotto', roles: ['admin'], component: ProductNew, exact: true },
  { path: '/admin/products/categories', name: 'Categorie', roles: ['admin'], component: CategoryAll, exact: true },
  { path: '/admin/products/:id', name: 'Dettagli Prodotto', roles: ['admin'], component: ProductDetails, exact: true },
  // Products variants
  { path: '/admin/products/:id/variants/new', name: 'Nuova variante', component: VariantNew, exact: true },
  { path: '/admin/products/:id/variants/:variantId', name: 'Dettagli variante', component: VariantDetails, exact: true },
  // Organizations
  { path: '/admin/organizations', name: 'Organizzazioni', roles: ['admin'], component: OrganizationAll, exact: true },
  { path: '/admin/organizations/new', name: 'Nuova organizzazione', roles: ['admin'], component: OrganizationNew, exact: true },
  { path: '/admin/organizations/:id', name: 'Dettagli Organizzazione', roles: ['admin'], component: OrganizationDetails, exact: true },
  { path: '/admin/organizations/:id/products', name: 'Prodotti organizzazione', roles: ['admin'], component: OrganizationProducts, exact: true },
  // Reports
  { path: '/admin/reports', name: 'Reports', roles: ['admin'], component: Reports, exact: true },
  // Companies
  { path: '/admin/companies', name: 'Compagnie', roles: ['admin'], component: CompaniesAll, exact: true },
  { path: '/admin/companies/new', name: 'Nuova compagnia', roles: ['admin'], component: CompaniesNew, exact: true },
  { path: '/admin/companies/:id', name: 'Dettagli compagnia', roles: ['admin'], component: CompaniesDetails, exact: true },
  /**
   * Affinity
   */
  // Dashboard
  { path: '/dashboard/affinity', name: 'Dashboard', component: DashboardAffinity, exact: true },
];

export default routes;

import React, { useCallback, useState } from 'react';
import {
  CustomersMajor,
  HomeMajor,
  ProductsMajor,
  SettingsMajor,
  NoteMajor,
  FraudProtectUnprotectedMajor,
  AnalyticsMajor,
  OrganizationMajor,
  BankMajor,
  OrdersMajor,
  ReportsMajor,
  StoreStatusMajor,
  InventoryMajor,
  CirclePlusMajor,
  CartMajor,
  OrderStatusMinor,
} from '@shopify/polaris-icons';

import { Navigation, SubNavigationItem } from '@shopify/polaris';
import { navigationContextControlMarkup } from '../NavigationContextControlMarkup';

const BookIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M6.75 6a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 9a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M6.75 12a.75.75 0 0 0 0 1.5h.5a.75.75 0 0 0 0-1.5h-.5Z" />
    <path d="M12 6.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path d="M12 9.75a.75.75 0 0 1 .75-.75h.5a.75.75 0 0 1 0 1.5h-.5a.75.75 0 0 1-.75-.75Z" />
    <path
      fillRule="evenodd"
      d="M7.586 3.5c.87 0 1.714.273 2.414.771a4.164 4.164 0 0 1 2.414-.771h2.336c.966 0 1.75.784 1.75 1.75v9.5a1.75 1.75 0 0 1-1.75 1.75h-.238a9.25 9.25 0 0 0-3.161.557l-1.095.398a.75.75 0 0 1-.493.007l-1.46-.487a9.25 9.25 0 0 0-2.926-.475h-.127a1.75 1.75 0 0 1-1.75-1.75v-9.5c0-.966.784-1.75 1.75-1.75h2.336Zm-2.586 1.75a.25.25 0 0 1 .25-.25h2.336c.608 0 1.194.208 1.664.584v10.125l-.473-.157a10.75 10.75 0 0 0-3.4-.552h-.127a.25.25 0 0 1-.25-.25v-9.5Zm5.75 10.43v-10.096a2.664 2.664 0 0 1 1.664-.584h2.336a.25.25 0 0 1 .25.25v9.5a.25.25 0 0 1-.25.25h-.238a10.75 10.75 0 0 0-3.674.647l-.088.032Z"
    />
  </svg>
);

export function NavigationMarkup({ user }: any) {
  const [navItemActive, setNavItemActive] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const toggleIsLoading = useCallback(() => setIsLoading((isLoading) => !isLoading), []);

  const location = window.location.pathname;

  if (!user) {
    return null;
  }

  /**
   * Products sub navigation
   */
  const productsSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Tutti i prodotti',
      url: '/admin/products',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    },
  ];
  if (user.role === 'admin') {
    productsSubNavigationMarkup.push({
      label: 'Categorie',
      url: '/admin/products/categories',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('products');
      },
    });
  }

  /**
   * Reports sub navigation
   */
  // const reportsSubNavigationMarkup: SubNavigationItem[] = [
  //   {
  //     label: 'Retail e Famiglie',
  //     url: '/reports/family',
  //     onClick: () => {
  //       toggleIsLoading();
  //       setNavItemActive('reports');
  //     },
  //   },
  //   {
  //     label: 'Business (beta)',
  //     url: '/reports',
  //     onClick: () => {
  //       toggleIsLoading();
  //       setNavItemActive('reports');
  //     },
  //   },
  // ];

  /**
   * Resources Marketing
   */
  const resourcesSubNavigationMarkup: SubNavigationItem[] = [
    {
      label: 'Banners',
      url: '/resources/banners',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
    {
      label: 'Logo',
      url: '/resources/logo',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
    {
      label: 'Social',
      url: '/resources/social',
      onClick: () => {
        toggleIsLoading();
        setNavItemActive('resources');
      },
    },
  ];

  const navigationMarkupFinal = (
    <Navigation location={location} contextControl={navigationContextControlMarkup}>
      {/* Check if user is admin */}
      {user && user.role === 'admin' && (
        <>
          <Navigation.Section
            title="Home"
            items={[
              {
                label: 'Home',
                icon: HomeMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('home');
                },
                url: '/',
                exactMatch: true,
              },
              {
                label: 'Clienti',
                icon: CustomersMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('customers');
                },
                url: '/customers',
              },
              {
                label: 'Prodotti',
                icon: ProductsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('questionnaires');
                },
                url: '/questionnaires',
              },
              {
                label: 'Preventivi',
                icon: NoteMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('quotes');
                },
                url: '/quotes',
              },
              {
                label: 'Polizze',
                icon: OrdersMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('policies');
                },
                url: '/policies',
              },
              {
                label: 'Sinistri',
                icon: FraudProtectUnprotectedMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('claims');
                },
                url: '/claims',
              },
            ]}
          />
          <Navigation.Section
            title="Analisi assicurative"
            items={[
              {
                label: 'Retail e Famiglie',
                icon: StoreStatusMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('reports family');
                },
                url: '/reports/family',
              },
              {
                label: 'Business (beta)',
                icon: OrderStatusMinor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('reports');
                },
                url: '/reports/business',
              },
            ]}
          />
          <Navigation.Section
            title="Analytics"
            items={[
              {
                label: 'Analytics',
                icon: AnalyticsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('analytics');
                },
                url: '/analytics',
              },
            ]}
          />
          <Navigation.Section
            title="Admin"
            fill
            items={[
              {
                label: 'Utenti',
                icon: CustomersMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('users');
                },
                url: '/admin/users',
              },
              {
                label: 'Organizzazioni',
                icon: BankMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('organizations');
                },
                url: '/admin/organizations',
              },
              {
                label: 'Fabbrica prodotti',
                icon: ProductsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('products');
                },
                secondaryAction: {
                  url: '/admin/products',
                  accessibilityLabel: 'Altri',
                  icon: CirclePlusMajor as any,
                  onClick: () => {
                    toggleIsLoading();
                    setNavItemActive('products');
                  },
                },
                url: '/admin/products',
                subNavigationItems: productsSubNavigationMarkup,
              },
              {
                label: 'Compagnie',
                icon: InventoryMajor,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('companies');
                },
                url: '/admin/companies',
              },
              {
                label: 'Reports personalizzati',
                icon: ReportsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('reports');
                },
                url: '/admin/reports',
              },
            ]}
          />
          <Navigation.Section
            items={[
              {
                label: 'Impostazioni',
                icon: SettingsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('settings');
                },
                url: '/settings',
                matchPaths: ['/settings', '/settings/*'],
              },
            ]}
          />
        </>
      )}
      {/* Check if user is partner */}
      {user && user.role === 'partner' && (
        <>
          <Navigation.Section
            title="Home"
            items={[
              {
                label: 'Home',
                icon: HomeMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('home');
                },
                url: '/',
                exactMatch: true,
              },
              {
                label: 'Clienti',
                icon: CustomersMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('customers');
                },
                url: '/customers',
              },
              {
                label: 'Prodotti',
                icon: ProductsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('questionnaires');
                },
                url: '/questionnaires',
              },
              {
                label: 'Preventivi',
                icon: NoteMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('quotes');
                },
                url: '/quotes',
              },
              {
                label: 'Polizze',
                icon: OrdersMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('policies');
                },
                url: '/policies',
              },
            ]}
          />
          <Navigation.Section
            title="Analisi assicurative"
            items={[
              {
                label: 'Retail e Famiglie',
                icon: StoreStatusMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('reports family');
                },
                url: '/reports/family',
              },
              {
                label: 'Business (beta)',
                icon: OrderStatusMinor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('reports');
                },
                url: '/reports',
              },
            ]}
          />
          <Navigation.Section
            title="Analytics"
            items={[
              {
                label: 'Analytics',
                icon: AnalyticsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('analytics');
                },
                url: '/analytics',
              },
            ]}
          />
          <Navigation.Section
            title="Guide Risorse"
            items={[
              {
                label: 'Guide',
                icon: BookIcon as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('guide');
                },
                url: '/guide',
              },
              {
                label: 'Risorse Marketing',
                icon: BookIcon as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('resources');
                },
                secondaryAction: {
                  url: '/resources',
                  accessibilityLabel: 'Altri',
                  icon: CirclePlusMajor as any,
                  onClick: () => {
                    toggleIsLoading();
                    setNavItemActive('resources');
                  },
                },
                url: '/resources',
                subNavigationItems: resourcesSubNavigationMarkup,
              },
            ]}
          />
          <Navigation.Section
            title="Organizzazione"
            items={[
              {
                label: 'Acquista analisi',
                icon: CartMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('analisi');
                },
                url: '/organization/reports/buy',
              },
              {
                label: 'Impostazioni',
                icon: SettingsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('settings');
                },
                url: '/settings',
                matchPaths: ['/settings', '/settings/*'],
              },
            ]}
          />
        </>
      )}
      {/* Check if user is affinity */}
      {user && user.role === 'affinity' && (
        <>
          <Navigation.Section
            title="Dashboard"
            items={[
              {
                label: 'Dashboard',
                icon: HomeMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('dashboard');
                },
                url: '/',
                exactMatch: true,
              },
              {
                label: 'Prodotti attivi',
                icon: ProductsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('products');
                },
                url: '/products',
                exactMatch: true,
              },
            ]}
          />
          <Navigation.Section
            title="Guide Risorse"
            items={[
              {
                label: 'Guide',
                icon: BookIcon as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('guide');
                },
                url: '/guide',
                exactMatch: true,
              },
              {
                label: 'Risorse Marketing',
                icon: BookIcon as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('resources');
                },
                secondaryAction: {
                  url: '/resources',
                  accessibilityLabel: 'Altri',
                  icon: CirclePlusMajor as any,
                  onClick: () => {
                    toggleIsLoading();
                    setNavItemActive('resources');
                  },
                },
                url: '/resources',
                subNavigationItems: resourcesSubNavigationMarkup,
              },
            ]}
          />
          <Navigation.Section
            title="Presenta un partner"
            items={[
              {
                label: 'Contatti',
                icon: ReportsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('contacts');
                },
                url: '/contacts',
                exactMatch: true,
              },
            ]}
          />
          <Navigation.Section
            title="Organizzazione"
            items={[
              {
                label: 'Impostazioni',
                icon: SettingsMajor as any,
                onClick: () => {
                  toggleIsLoading();
                  setNavItemActive('settings');
                },
                url: '/settings',
                matchPaths: ['/settings', '/settings/*'],
              },
            ]}
          />
        </>
      )}
    </Navigation>
  );

  return navigationMarkupFinal;
}

import React, { useCallback, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  CalloutCard,
  MediaCard,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';
import { useUser } from '../../../utils/PrivateRoute';

export function Social() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  const actualPageMarkup = (
    <Page title="Social">
      <Layout>
        <Layout.Section>
          <MediaCard
            title="Image A"
            primaryAction={{
              content: 'Scarica',
              // onAction: () => {},
            }}
            description="Scarica questa image"
            // popoverActions={[{ content: 'Dismiss', onAction: () => {} }]}
          >
            <img
              alt=""
              width="100%"
              height="100%"
              style={{
                objectFit: 'cover',
                objectPosition: 'center',
              }}
              src="https://burst.shopifycdn.com/photos/business-woman-smiling-in-office.jpg?width=1850"
            />
          </MediaCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}

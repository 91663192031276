import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
  Autocomplete,
  LegacyStack,
  Thumbnail,
  DropZone,
} from '@shopify/polaris';

import axios, { AxiosError } from 'axios';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../../components';

import './ProductNew.scss';
import { NoteMinor, SearchMinor } from '@shopify/polaris-icons';

import { Option } from '../../../../types';
import { useUser } from '../../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';

type AutocompleteCategoryOption = {
  label: string;
  value: string;
};

export function ProductNew() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [saveError, setSaveError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Product states
   */
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  // Status
  const [selectedStatus, setSelectedStatus] = useState<'active' | 'draft'>('draft');
  const statusOptions = [
    { label: 'Attivo', value: 'active' },
    { label: 'Bozza', value: 'draft' },
  ];

  // Icon
  const [icon, setIcon] = useState<File>();
  const validImageTypes = ['image/gif', 'image/jpeg', 'image/png', 'image/svg'];

  const [deselectedCategoryOptions, setDeselectedCategoryOptions] = useState<AutocompleteCategoryOption[]>([]);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState([]);
  const [categoryInputValue, setCategoryInputValue] = useState('');
  const [categoryOptions, setCategoryOptions] = useState(deselectedCategoryOptions);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [category, setCategory] = useState('');

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    description: false,
    category: false,
  });

  /**
   * Fetch categories
   */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setCategoryLoading(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/categories', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          const categoryOptions = [];
          for (const category of data.data) {
            categoryOptions.push({
              value: category._id,
              label: `${category.name}`,
            });
          }
          setCategoryOptions(categoryOptions);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setCategoryLoading(false);
      }
    };
    fetchCategories();
  }, []);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);

      // Check if fields are empty
      let errFlag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        errFlag = true;
      }

      if (description === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, description: true }));
        errFlag = true;
      }

      if (category === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, category: true }));
        errFlag = true;
      }

      if (errFlag) {
        setIsLoading(false);
        return;
      }

      // Create form data
      const formData = new FormData();
      if (icon) {
        formData.append('document', icon, icon?.name);
      }
      formData.append('name', name);
      formData.append('description', description);
      formData.append('category', category);
      formData.append('status', selectedStatus);

      const response = await axios.post((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products/new', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setTimeout(() => {
          navigate(`/admin/products/${data.data._id}`);
        }, 3000);
        setIsDirty(false);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [name, description, category, selectedStatus, icon, history]);

  /**
   * Policy Handlers
   */
  const handleNameChange = useCallback(
    (e: string) => {
      // If emptyFields.name is true, set it to false
      if (emptyFields.name) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(e);
      setIsDirty(true);
    },
    [emptyFields.name],
  );

  const handleDescriptionChange = useCallback(
    (e: string) => {
      // If emptyFields.description is true, set it to false
      if (emptyFields.description) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, description: false }));
      }
      setDescription(e);
      setIsDirty(true);
    },
    [emptyFields.description],
  );

  const handleStatusChange = useCallback((e: any) => {
    setSelectedStatus(e);
    setIsDirty(true);
  }, []);

  /**
   * Category autocomplete
   */
  const updateCategoryText = useCallback(
    (value: any) => {
      setCategoryInputValue(value);

      if (!categoryLoading) {
        setCategoryLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCategoryOptions(deselectedCategoryOptions);
          setCategoryLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCategoryOptions.filter((option: any) => option.label.match(filterRegex));
        setCategoryOptions(resultOptions);
        setCategoryLoading(false);
      }, 300);
    },
    [deselectedCategoryOptions, categoryLoading],
  );

  const updateCategorySelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.category) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, category: false }));
      }

      const selectedCustomer = selected.map((selectedItem: any) => {
        const matchedOption = categoryOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCategoryOptions(selected);
      setCategoryInputValue(selectedCustomer[0].label);

      // Add customer to order
      const category = selectedCustomer[0].value;
      setCategory(category);
    },
    [categoryOptions],
  );

  const categoryTextField = (
    <Autocomplete.TextField
      onChange={updateCategoryText}
      label="Categoria"
      value={categoryInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca una categoria"
      autoComplete="off"
      error={emptyFields.category && 'Per favore seleziona una categoria'}
    />
  );

  const emptyCategoryState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessuna categoria trovata</TextContainer>
      </div>
    </React.Fragment>
  );

  /**
   * Product drop zone icon
   */
  const handleDropZoneDrop = useCallback((_dropFiles: File[], acceptedFiles: File[], _rejectedFiles: File[]) => {
    setIcon(acceptedFiles[0]);
    setIsDirty(true);
  }, []);

  const fileUpload = !icon ? <DropZone.FileUpload /> : null;

  const uploadedFile = icon && (
    <div style={{ padding: '0' }}>
      <LegacyStack alignment="center">
        <Thumbnail size="large" alt={icon.name} source={NoteMinor as any} />
        <div>{icon.name}</div>
      </LegacyStack>
    </div>
  );

  const iconDropZoneMarkup = (
    <DropZone onDrop={handleDropZoneDrop} allowMultiple={false}>
      {uploadedFile}
      {fileUpload}
    </DropZone>
  );

  const removeIconAction = icon
    ? [
        {
          content: 'Rimuovi icona',
          onAction: () => setIcon(undefined),
        },
      ]
    : undefined;

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields
    setName('');
    setDescription('');
    setSelectedStatus('draft');
    setCategory('');
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il prodotto è stato creato con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Aggiungi prodotto" backAction={{ content: 'Prodotti', url: '/admin/products' }}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        {/* Name & description */}
        <Layout.Section>
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout>
                <TextField label="Nome" value={name} onChange={handleNameChange} autoComplete="off" error={emptyFields.name && 'Il nome è obbligatorio'} />
                <TextField
                  label="Descrizione"
                  value={description}
                  onChange={handleDescriptionChange}
                  autoComplete="off"
                  error={emptyFields.description && 'La descrizione è obbligatoria'}
                />
              </FormLayout>
            </FormLayout>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section secondary>
          {/* Status */}
          <LegacyCard sectioned title="Stato del prodotto">
            <Select
              label="Stato"
              options={statusOptions}
              onChange={handleStatusChange}
              value={selectedStatus}
              helpText={selectedStatus === 'active' ? 'Questo prodotto sarà disponibile sullo store.' : 'Questo prodotto sarà nascosto sullo store.'}
            />
          </LegacyCard>

          {/* Categories */}
          <LegacyCard sectioned title="Organizzazione dei prodotti">
            <FormLayout>
              <Autocomplete
                options={categoryOptions}
                selected={selectedCategoryOptions}
                onSelect={updateCategorySelection}
                loading={categoryLoading}
                textField={categoryTextField}
                emptyState={emptyCategoryState}
              />
            </FormLayout>
          </LegacyCard>

          {/* Icon */}
          <LegacyCard sectioned actions={removeIconAction} title="Icona">
            {iconDropZoneMarkup}
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}

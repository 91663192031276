import React, { useCallback, useEffect, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, LegacyCard, FormLayout, Select, LegacyStack, TextField, Text, Checkbox } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor } from '@shopify/polaris-icons';
import { Warranty } from '../../../../types';
import { Check } from '@fluentui/react';

interface Props {
  id: string;
  options: any[];
  warrantyItem?: Warranty;
  quotationItemOptions?: Array<{ label: string; value: string }>;
  onDelete: (id: string) => void;
  onSave?: (
    id: string,
    name: string,
    value: string,
    included: boolean,
    deductible: string,
    result_modifier_type: 'fixed_amount' | 'percentage' | 'none',
    result_modifier_value?: string,
  ) => void;
  onUpdate?: (
    _id: string,
    id: string,
    name: string,
    value: string,
    included: boolean,
    deductible: string,
    result_modifier_type: 'fixed_amount' | 'percentage' | 'none',
    result_modifier_value?: string,
  ) => void;
}

export function SortableItemWarranty(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [name, setName] = useState(props.warrantyItem?.name || '');
  const [value, setValue] = useState(props.warrantyItem?.value || '');
  const [isIncluded, setIsIncluded] = useState(props.warrantyItem?.included || false);
  const [deductible, setDeductible] = useState(props.warrantyItem?.deductible || '0');
  const [resultModifierValue, setResultModifierValue] = useState(props.warrantyItem?.result_modifier_value || '0');
  const [isEditing, setIsEditing] = useState(props.warrantyItem === undefined);

  const [resultModifierType, setResultModifierType] = useState(props.warrantyItem?.result_modifier_type || 'none');
  const resultModifierTypeOptions = [
    { label: 'Importo Fisso', value: 'fixed_amount' },
    { label: 'Percentuale', value: 'percentage' },
    { label: 'Nessuno', value: 'none' },
  ];

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    value: false,
    resultModifierValue: false,
  });

  const onDelete = () => {
    props.onDelete(props.id);
  };

  // Name change handler
  const onNameChange = useCallback(
    (value: string) => {
      if (emptyFields.name) {
        setEmptyFields({ ...emptyFields, name: false });
      }
      setName(value);
    },
    [emptyFields.name],
  );

  // Value change handler
  const onValueChange = useCallback(
    (value: string) => {
      if (emptyFields.value) {
        setEmptyFields({ ...emptyFields, value: false });
      }
      setValue(value);
    },
    [emptyFields.value],
  );

  // Result modifier type change handler
  const onResultModifierTypeChange = useCallback((value: any) => {
    setResultModifierType(value);
  }, []);

  // Result modifier value change handler
  const onResultModifierValueChange = useCallback((value: any) => {
    setResultModifierValue(value);
  }, []);

  /**
   * Save handler
   */
  const handleSave = () => {
    let errFlag = false;

    // Check if name is empty
    if (name === '') {
      setEmptyFields({
        ...emptyFields,
        name: true,
      });
      errFlag = true;
    }

    if (resultModifierValue === '' && resultModifierType !== 'none') {
      setEmptyFields({
        ...emptyFields,
        resultModifierValue: true,
      });
      errFlag = true;
    }

    if (errFlag) {
      return;
    }

    // Save data if quotationItem is undefined otherwise update data
    if (props.warrantyItem === undefined) {
      if (props.onSave !== undefined) props?.onSave(props.id, name, value, isIncluded, deductible, resultModifierType, resultModifierValue);
    } else {
      if (props.onUpdate !== undefined && props.warrantyItem._id !== undefined) {
        props?.onUpdate(props.warrantyItem._id, props.id, name, value, isIncluded, deductible, resultModifierType, resultModifierValue);
      }
    }

    // Change component to display mode
    setIsEditing(false);
  };

  /**
   * Edit mode markup
   */
  const editModeMarkup = (
    <LegacyStack.Item fill>
      <FormLayout>
        <FormLayout.Group>
          <TextField label="Nome" value={name} onChange={onNameChange} error={emptyFields.name && 'Il campo è obbligatorio'} autoComplete="off" />
        </FormLayout.Group>
        <FormLayout.Group>
          <Select
            label="Tipologia di modificatore"
            disabled={isIncluded}
            options={resultModifierTypeOptions}
            value={resultModifierType}
            onChange={onResultModifierTypeChange}
          />
          {resultModifierType !== 'none' && (
            <TextField
              label="Valore modificatore"
              value={resultModifierValue}
              onChange={onResultModifierValueChange}
              autoComplete="off"
              error={emptyFields.resultModifierValue && 'Il campo è obbligatorio'}
            />
          )}
        </FormLayout.Group>
        <FormLayout.Group>
          <TextField label="Valore" value={value} onChange={onValueChange} autoComplete="off" />
          <TextField label="Franchigia" value={deductible} type="number" onChange={setDeductible} autoComplete="off" />
        </FormLayout.Group>
        <Checkbox
          label="Incluso"
          checked={isIncluded}
          onChange={() => {
            setIsIncluded(!isIncluded);
            if (!isIncluded) {
              setResultModifierType('none');
              setResultModifierValue('0');
            }
          }}
        />
        <Button onClick={handleSave}>Fatto</Button>
      </FormLayout>
    </LegacyStack.Item>
  );

  /**
   * Display mode markup
   */
  const displayModeMarkup = (
    <LegacyStack.Item fill>
      <Text as="span" fontWeight="semibold">
        {name}
      </Text>
      <div>Valore: {value}</div>
    </LegacyStack.Item>
  );

  return (
    <LegacyCard.Section>
      <div ref={setNodeRef} style={style}>
        <LegacyStack alignment="center">
          <LegacyStack.Item>
            {/* <Icon source={DragHandleMinor} {...attributes} {...listeners} /> */}
            <Button plain icon={DragHandleMinor as any} {...attributes} {...listeners} />
          </LegacyStack.Item>
          {isEditing ? editModeMarkup : displayModeMarkup}
          <LegacyStack.Item>
            {isEditing ? (
              <Button icon={DeleteMinor as any} plain onClick={onDelete} />
            ) : (
              <Button size="slim" onClick={() => setIsEditing(true)}>
                Modifica
              </Button>
            )}
          </LegacyStack.Item>
        </LegacyStack>
      </div>
    </LegacyCard.Section>
  );
}

// @ts-nocheck

import React from 'react';
import { Gradient } from './Gradient.js';

export function HeroGradient() {
  React.useEffect(() => {
    // Create your instance
    const gradient = new Gradient();

    // Call `initGradient` with the selector to your canvas
    gradient.initGradient('#gradient-canvas');
  }, []);

  return (
    <div className="HomepageHeroGradient Gradient">
      <canvas id="gradient-canvas" data-transition-in></canvas>
    </div>
  );
}

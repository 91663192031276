import { IndexTable, Button, useIndexResourceState, ButtonGroup } from '@shopify/polaris';
import { DeleteMinor } from '@shopify/polaris-icons';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  variants: Array<any>;
  quotation: boolean;
  productId: string;
  deleteVariant: any;
}

function VariantList(props: Props) {
  const navigate = useNavigate();
  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(props.variants);

  const resourceName = {
    singular: 'variante',
    plural: 'varianti',
  };

  const handleDelete = useCallback((variantId: string) => {
    props.deleteVariant(variantId);
  }, []);

  const rowMarkup = props.variants.map(({ _id: id, name, price, quotation }, index) => {
    const priceMarkup = !quotation ? (price ? `${price.toFixed(2)} €` : 'Non impostato') : 'Preventivabile';

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>{name}</IndexTable.Cell>
        <IndexTable.Cell>{priceMarkup}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ float: 'right' }}>
            <ButtonGroup segmented>
              <Button size="slim" onClick={() => navigate(`/admin/products/${props.productId}/variants/${id}`)}>
                Modifica
              </Button>
              <Button size="slim" icon={DeleteMinor as any} onClick={() => handleDelete(id)} />
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  return (
    <IndexTable
      resourceName={resourceName}
      itemCount={props.variants.length}
      selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
      onSelectionChange={handleSelectionChange}
      headings={[{ title: 'Nome' }, { title: 'Prezzo' }]}
    >
      {rowMarkup}
    </IndexTable>
  );
}

export { VariantList };

import React, { useCallback, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  Frame,
  Layout,
  Loading,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Toast,
  Banner,
  LegacyStack,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, CustomerList } from '../../../components';

import './CustomerAll.scss';
import { useUser } from '../../../utils/PrivateRoute';
import axios, { AxiosError } from 'axios';
import saveAs from 'file-saver';
import { parseDateLabel } from '../../../utils/Common';

export function CustomerAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  const [importActive, setImportActive] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleImportActive = useCallback(() => setImportActive(!importActive), [importActive]);

  /**
   * Error markups & toast
   */
  const toastImportMarkup = importActive ? <Toast content="I clienti sono stati importati con successo." onDismiss={toggleImportActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Handle print customer records
   */
  const handlePrintPersonalData = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/customers/records`, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
      });
      const data = response.data;

      saveAs(data, `customers_data_${parseDateLabel(new Date())}.xlsx`);
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
      }
    } finally {
      setButtonSpinning(false);
    }
  }, []);

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      fullWidth
      title="Clienti"
      primaryAction={
        <LegacyStack spacing="tight" distribution="trailing">
          <Button onClick={handlePrintPersonalData} loading={buttonSpinning}>
            Stampa anagrafiche
          </Button>
          <Button primary url="/customers/new">
            Aggiungi cliente
          </Button>
        </LegacyStack>
      }
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        <Layout.Section>
          <CustomerList />
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
      {toastImportMarkup}
    </Frame>
  );
}

import { useCallback, useEffect, useRef, useState } from 'react';
import * as React from 'react';

import {
  Button,
  LegacyCard,
  ContextualSaveBar,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
  Autocomplete,
  Text,
  LegacyStack,
  Badge,
  Select,
} from '@shopify/polaris';

import axios from 'axios';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup, NewCustomerModal } from '../../../components';

import { CancelSmallMinor, CirclePlusMinor, SearchMinor } from '@shopify/polaris-icons';
import { ReportFamilyMember } from '../components';
import { Customer } from '../../../types';
import { RiskFamilyLocation, Member } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate } from 'react-router-dom';

type AutocompleteCustomerOption = {
  label: string;
  value: string;
};

export function NewReportFamily() {
  const navigate = useNavigate();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);
  const [newUserToastactive, setNewUserToastActive] = useState(false);
  const [newCustomerModalActive, setNewCustomerModalActive] = useState(false);
  const [customerCreated, setCustomerCreated] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleNewUserActive = useCallback(() => setNewUserToastActive((newUserToastactive) => !newUserToastactive), []);

  const [saveError, setSaveError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Custoemr states
   */
  const [deselectedCustomerOptions, setDeselectedCustomerOptions] = useState<AutocompleteCustomerOption[]>([]);
  const [selectedCustomerOptions, setSelectedCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState('');
  const [customerOptions, setCustomerOptions] = useState(deselectedCustomerOptions);
  const [customerLoading, setCustomerLoading] = useState(false);
  const [customer, setCustomer] = useState<Customer | null>(null);

  /**
   * Location States
   */
  const [defaulLocationState, setDefaultLocationState] = useState<RiskFamilyLocation>({
    mq: 0,
    year: 0,
    mq_value: 0,
    owner: 0,
    single: 0,
    nearby: 0,
    others: 0,
    pets: 0,
  });
  const [mq, setMq] = useState('');
  const [year, setYear] = useState('');
  const [mqValue, setMqValue] = useState('');
  const [owner, setOwner] = useState('');
  const [single, setSingle] = useState('');
  const [nearby, setNearby] = useState('');
  const [others, setOthers] = useState('');
  const [pets, setPets] = useState('');

  /**
   * Family member states
   */
  const [member, setMember] = useState<Member>({
    name: '',
    age: 0,
    sex: 'M',
    married: 0,
    job: 'commerciante',
    job_year: 0,
    net_income: 0,
    annual_saving: 0,
    government_support: 0,
    caregiver: 0,
    satisfied_sns: 0,
    saved: 0,
  });
  const [members, setMembers] = useState<Member[]>([]);
  const [membersEditing, setMembersEditing] = useState<boolean[]>([]);
  const [defaultFamilyState, setDefaultFamilyState] = useState<Member>({
    name: '',
    age: 0,
    sex: 'M',
    married: 0,
    job: 'commerciante',
    job_year: 0,
    net_income: 0,
    annual_saving: 0,
    government_support: 0,
    caregiver: 0,
    satisfied_sns: 0,
    saved: 0,
  });

  // Empty fields

  const [emptyFields, setEmptyFields] = useState({
    lineItems: false,
    customer: false,
    questions: false,
  });

  /**
   * Data fetching:
   * - fetch customers
   */

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        setCustomerLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/customers', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const customerOptions = [];
          for (const customer of data.data) {
            customerOptions.push({
              value: customer._id,
              label: customer.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : customer.user?.name,
              customer: customer,
            });
          }
          setCustomerOptions(customerOptions);
          setDeselectedCustomerOptions(customerOptions);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setCustomerLoading(false);
      }
    };
    fetchCustomers();
  }, [customerCreated]);

  /**
   * Save data Customer,
   * Save data Report
   */

  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      setSaveError(false);

      // Check if fields are empty
      let errFlag = false;

      if (customer === null && defaulLocationState === null && defaultFamilyState === null) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, customer: true, defaultLocationState: true, defaultFamilyState: true }));
        errFlag = true;
      }

      if (errFlag) {
        setButtonSpinning(false);
        return;
      }

      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/risks/family',
        {
          user_id: customer?.user?._id,
          location: defaulLocationState,
          members: members,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setTimeout(() => {
          navigate(`/reports/family/${data.data._id}`);
        }, 3000);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [customer, defaulLocationState, members]);

  /**
   * Customer autocomplete
   */
  const updateCustomerText = useCallback(
    (value: any) => {
      setCustomerInputValue(value);

      if (!customerLoading) {
        setCustomerLoading(true);
      }

      setTimeout(() => {
        if (value === '') {
          setCustomerOptions(deselectedCustomerOptions);
          setCustomerLoading(false);
          return;
        }
        const filterRegex = new RegExp(value, 'i');
        const resultOptions = deselectedCustomerOptions.filter((option: any) => option.label.match(filterRegex));
        setCustomerOptions(resultOptions);
        setCustomerLoading(false);
      }, 300);
    },
    [deselectedCustomerOptions, customerLoading],
  );

  const updateCustomerSelection = useCallback(
    (selected: any) => {
      // Check if emptyFields is true
      if (emptyFields.customer) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, customer: false }));
      }

      const selectedCustomer = selected.map((selectedItem: any) => {
        const matchedOption = customerOptions.find((option) => {
          return option.value.match(selectedItem);
        });
        return matchedOption;
      });
      setSelectedCustomerOptions(selected);
      setCustomerInputValue(selectedCustomer[0].label);

      // Add customer to order
      const customer = selectedCustomer[0].customer;
      setCustomer(customer);
    },
    [customerOptions],
  );

  const customerTextField = (
    <Autocomplete.TextField
      onChange={updateCustomerText}
      label={null}
      value={customerInputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca o crea cliente"
      autoComplete="off"
      error={emptyFields.customer && 'Per favore seleziona un cliente'}
    />
  );

  const emptyCustomerState = (
    <React.Fragment>
      <div style={{ textAlign: 'center' }}>
        <TextContainer>Nessun cliente trovato</TextContainer>
      </div>
    </React.Fragment>
  );

  const customerMarkup =
    customer === null ? (
      <LegacyCard sectioned title="Cliente">
        <LegacyStack vertical>
          <Autocomplete
            actionBefore={{
              accessibilityLabel: 'Crea cliente',
              content: 'Crea un cliente',
              icon: CirclePlusMinor as any,
              wrapOverflow: true,
              onAction: () => setNewCustomerModalActive(true),
            }}
            options={customerOptions}
            selected={selectedCustomerOptions}
            onSelect={updateCustomerSelection}
            loading={customerLoading}
            textField={customerTextField}
            emptyState={emptyCustomerState}
          />
        </LegacyStack>
      </LegacyCard>
    ) : (
      <LegacyCard>
        <LegacyCard.Header title="Cliente">
          <Button
            icon={CancelSmallMinor as any}
            onClick={() => {
              setCustomer(null);
              setSelectedCustomerOptions([]);
              setCustomerInputValue('');
            }}
            plain
          />
        </LegacyCard.Header>
        <LegacyCard.Section>
          <LegacyStack vertical spacing="none">
            <Text as="span" fontWeight="semibold">
              {customer.user?.lastname ? `${customer.user.name} ${customer.user.lastname}` : `${customer.user?.name}`}
            </Text>
            {customer?.user?.email ? (
              <p>{customer.user.email}</p>
            ) : (
              <p>
                <Badge>Mancante</Badge>
              </p>
            )}
          </LegacyStack>
        </LegacyCard.Section>
        <LegacyCard.Section title="Telefono">
          <LegacyCard.Subsection>
            <LegacyStack vertical>
              {customer?.user?.phone ? (
                <p>{customer?.user.phone}</p>
              ) : (
                <p>
                  <Badge>Mancante</Badge>
                </p>
              )}
            </LegacyStack>
          </LegacyCard.Subsection>
        </LegacyCard.Section>
        <LegacyCard.Section title="Indirizzo">
          <LegacyCard.Subsection>
            {customer?.address && customer?.address.line.length > 0 ? (
              <LegacyStack vertical spacing="none">
                <p>{customer.address.line}</p>
                <p>
                  {customer.address.zip} {customer.address.city}
                </p>
                <p>{customer.address.country}</p>
              </LegacyStack>
            ) : (
              <p>
                <Badge>Mancante</Badge>
              </p>
            )}
          </LegacyCard.Subsection>
        </LegacyCard.Section>
      </LegacyCard>
    );

  //options for select Yes or no,
  const selectOptions = ['No', 'Si'];

  /**
   * Handlers for questions
   */
  //Location
  const handleMqChange = useCallback((e: string) => {
    setMq(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, mq: parseInt(e) }));
    setIsDirty(true);
  }, []);
  const handleYearChange = useCallback((e: string) => {
    setYear(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, year: parseInt(e) }));
    setIsDirty(true);
  }, []);
  const handleMqValueChange = useCallback((e: string) => {
    setMqValue(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, mq_value: parseInt(e) }));
    setIsDirty(true);
  }, []);
  const handleOwnerChange = useCallback((e: string) => {
    setOwner(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, owner: e === 'Si' ? 1 : 0 }));
    setIsDirty(true);
  }, []);
  const handleSingleChange = useCallback((e: string) => {
    setSingle(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, single: e === 'Si' ? 1 : 0 }));
    setIsDirty(true);
  }, []);
  const handleNearbyChange = useCallback((e: string) => {
    setNearby(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, nearby: parseInt(e) }));
    setIsDirty(true);
  }, []);
  const handleOthersChange = useCallback((e: string) => {
    setOthers(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, others: e === 'Si' ? 1 : 0 }));
    setIsDirty(true);
  }, []);
  const handlePetsChange = useCallback((e: string) => {
    setPets(e);
    setDefaultLocationState((defaultLocationState) => ({ ...defaultLocationState, pets: e === 'Si' ? 1 : 0 }));
    setIsDirty(true);
  }, []);

  /**
   * Questions
   */
  const questionsMarkup = (
    <FormLayout>
      <FormLayout.Group>
        <TextField label="Metri quadrati dell'immobile" type="number" value={mq} onChange={handleMqChange} autoComplete="off" min={0} />
        <TextField label="Anno di construzione dell'immobile" type="number" value={year} onChange={handleYearChange} autoComplete="off" min={0} />
      </FormLayout.Group>
      <FormLayout.Group>
        <TextField label="Valore dell'immobile (€/mq)" type="number" value={mqValue} onChange={handleMqValueChange} autoComplete="off" min={0} />
        <Select label="L'abitazione è di proprietà?" options={selectOptions} value={owner} onChange={handleOwnerChange} />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select
          label="L'abitazione è singola (non ha muri in comune con altre abitazioni)?"
          options={selectOptions}
          value={single}
          onChange={handleSingleChange}
        />
        <TextField
          label="Numero di propietà adiacenti (numero di muri in comune)"
          type="number"
          value={nearby}
          onChange={handleNearbyChange}
          autoComplete="off"
          min={0}
        />
      </FormLayout.Group>
      <FormLayout.Group>
        <Select label="Si possiedono altri immobili?" options={selectOptions} value={others} onChange={handleOthersChange} />
        <Select label="Si possiedono animali domestici?" options={selectOptions} value={pets} onChange={handlePetsChange} />
      </FormLayout.Group>
    </FormLayout>
  );

  /**
   * Contextual Save Bar
   */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);

    // Reset all the fields
  }, []);

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il report è stato creato con successo." onDismiss={toggleActive} /> : null;
  const toastUserMarkup = newUserToastactive ? <Toast content="Il cliente è stato creato con successo." onDismiss={toggleNewUserActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Add New Family Member
   */
  const addFamilyMember = useCallback(() => {
    setMembersEditing((prev) => [...prev, true]);
    setMember((prevMember) => ({
      ...prevMember,
      name: '',
      age: 0,
      sex: 'M',
      married: 0,
      job: 'commerciante',
      job_year: 0,
      net_income: 0,
      annual_saving: 0,
      government_support: 0,
      caregiver: 0,
      satisfied_sns: 0,
      saved: 0,
    }));
  }, [setMembersEditing, setMember]);

  // Handle editing of the family member
  const handleEdit = (index: number) => {
    setMembersEditing((prev) => prev.map((editing, i) => (i === index ? true : editing)));
  };

  // Save the family member into the members array
  const saveFamilyMember = useCallback(
    (member: Member, index: number) => {
      // Check if it's a new member or an existing one
      if (index === members.length) {
        setMembers((prev) => [...prev, member]);
      } else {
        setMembers((prev) => prev.map((m, i) => (i === index ? member : m)));
      }

      // Set editing to false
      setMembersEditing((prev) => prev.map((editing, i) => (i === index ? false : editing)));
    },
    [setMembers, members, membersEditing, setMembersEditing],
  );

  const removeFamilyMember = useCallback((index: number) => {
    setMembers((prev) => prev.filter((member, i) => i !== index));
    setMembersEditing((prev) => prev.filter((editing, i) => i !== index));
  }, []);

  const tmpFamilyMember = useCallback(() => {
    setDefaultFamilyState((member) => ({
      ...member,
      name: '',
      age: 0,
      sex: '',
      married: 0,
      job: 'commerciante',
      job_year: 0,
      net_income: 0,
      annual_saving: 0,
      government_support: 0,
      caregiver: 0,
      satisfied_sns: 0,
      saved: 0,
    }));
  }, []);

  const familyMemberMarkup = membersEditing.map((editing, index) => (
    <ReportFamilyMember
      key={index}
      id={index}
      member={member}
      editMode={editing}
      handleEdit={handleEdit}
      onSave={saveFamilyMember}
      onDelete={removeFamilyMember}
    />
  ));

  //console.log('cuantita di members', members);
  const actualPageMarkup = (
    <Page title="Nuovo Report" backAction={{ content: 'Impostazioni', url: '/reports/family' }}>
      <Layout>
        {saveErrorMarkup}
        <Layout.AnnotatedSection title="Cliente" description="Cerca un cliente o creane uno nuovo">
          {/* Customer */}
          {customerMarkup}
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Dati Immobile" description="Inserisci i dati richiesti">
          <LegacyCard title="Immobile" sectioned>
            {questionsMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Dati Famiglia" description="Inserisci i dati richiesti">
          <LegacyCard title="Componenti del nucleo familiare" actions={[{ content: 'Aggiungere un altro membro', onAction: addFamilyMember }]} sectioned>
            {familyMemberMarkup}
          </LegacyCard>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;
  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {NewCustomerModal(newCustomerModalActive, setNewCustomerModalActive, setCustomerCreated, setNewUserToastActive)}
      {toastMarkup}
      {toastUserMarkup}
    </Frame>
  );
}

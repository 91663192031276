import { FormLayout, Modal, TextField } from '@shopify/polaris';
import React, { useCallback, useMemo, useState } from 'react';
import { CategoryState } from '../../../../../types';

export function CategoryModal(modalStatus: boolean, setModalStatus: any, category: CategoryState | null, setCategory: any, isNew: boolean, saveCategory: any) {
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    tax: false,
  });

  const handleNameChange = useCallback(
    (value: string) => {
      if (emptyFields.name) {
        setEmptyFields({ ...emptyFields, name: false });
      }

      setCategory({ ...category, name: value });
    },
    [emptyFields.name, category],
  );

  const handleTaxChange = useCallback(
    (e: any) => {
      if (emptyFields.tax) {
        setEmptyFields({ ...emptyFields, tax: false });
      }

      // Check if value is greater than 100
      if (parseFloat(e.replace(',', '.')) > 100) {
        return;
      }

      // Allow max 2 decimals
      if (e.split('.').length > 1 && e.split('.')[1].length > 2) {
        return;
      }

      setCategory({ ...category, tax: e });
    },
    [emptyFields.tax, category],
  );

  const handleModalClose = useCallback(() => {
    setModalStatus(false);
  }, []);

  const handleSave = useCallback(() => {
    setButtonSpinning(true);
    let errFlag = false;

    // Check if fields are empty
    if (!category?.name || category?.name === '') {
      setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
      errFlag = true;
    }

    if (!category?.tax || category.tax === '' || parseFloat(category?.tax) < 0) {
      setEmptyFields((emptyFields) => ({ ...emptyFields, tax: true }));
      errFlag = true;
    }

    if (errFlag) {
      setButtonSpinning(false);
      return;
    }

    saveCategory(category);
    handleModalClose();
    setButtonSpinning(false);
  }, [handleModalClose, emptyFields, category]);

  /**
   * Modal markup
   */
  const secondaryActions = useMemo(
    () => [
      {
        content: 'Cancella',
        onAction: handleModalClose,
      },
    ],
    [handleModalClose],
  );

  return (
    <Modal
      open={modalStatus}
      onClose={handleModalClose}
      title={isNew ? 'Aggiungi categoria' : 'Modifica categoria'}
      primaryAction={{
        content: isNew ? 'Aggiungi' : 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={isNew ? secondaryActions : undefined}
    >
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <TextField
              label="Nome categoria"
              autoComplete="off"
              value={category?.name}
              onChange={handleNameChange}
              type="text"
              error={emptyFields.name && 'Il nome è obbligatorio'}
            />
            <TextField
              label="Valore"
              autoComplete="off"
              value={category?.tax}
              onChange={handleTaxChange}
              type="number"
              min={0}
              step={0.01}
              suffix="%"
              error={emptyFields.tax && 'Il valore è obbligatorio'}
            />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

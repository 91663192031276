import React, { useCallback, useMemo, useState } from 'react';
import { Button, FormLayout, Modal, Select, TextField } from '@shopify/polaris';

import { DiscountApplication } from '../../../types';

export function DiscountModal(modalStatus: boolean, setModalStatus: any, discount: DiscountApplication | null, setDiscount: any, isNew: boolean) {
  const [buttonSpinning, setButtonSpinning] = useState(false);

  /**
   * DiscountApplication states
   */
  const typeOptions = useMemo(
    () => [
      { label: 'Manuale', value: 'manual' },
      { label: 'Codice sconto', value: 'discount_code' },
    ],
    [],
  );
  const valueOptions = useMemo(
    () => [
      { label: 'Importo', value: 'fixed_amount' },
      { label: 'Percentuale', value: 'percentage' },
    ],
    [],
  );

  // Empty fields
  const [emptyFields, setEmptyFields] = useState({
    value: false,
  });

  const handleTypeChange = useCallback((value: any) => setDiscount({ ...discount, type: value }), [discount]);
  const handleDescriptionChange = useCallback((value: any) => setDiscount({ ...discount, description: value }), [discount]);
  const handleValueChange = useCallback(
    (value: any) => {
      if (emptyFields.value) {
        setEmptyFields({ ...emptyFields, value: false });
      }

      // Check if value is a number
      if (isNaN(value)) {
        return;
      }

      // Block user from deleting the first value
      if (value === '') {
        return;
      }

      setDiscount({ ...discount, value: parseFloat(value) });
    },
    [emptyFields.value, discount],
  );
  const handleValueTypeChange = useCallback((value: any) => setDiscount({ ...discount, value_type: value }), [discount]);

  /**
   * Modal handlers
   */
  const handleModalClose = useCallback(() => {
    setModalStatus(false);
    setDiscount(null);
  }, [setModalStatus]);

  const handleAdd = useCallback(() => {
    setButtonSpinning(true);
    console.log(discount);

    if (!discount?.value || isNaN(discount?.value) || discount?.value === 0) {
      setEmptyFields({ value: true });
      setButtonSpinning(false);
      return;
    }

    setModalStatus(false);
    setButtonSpinning(false);
  }, [handleModalClose, discount]);

  const handleRemove = useCallback(() => {
    setDiscount(null);

    handleModalClose();
  }, [handleModalClose]);

  /**
   * Markup
   */
  const secondaryActions = useMemo(
    () => [
      {
        content: 'Cancella',
        onAction: handleRemove,
      },
    ],
    [handleRemove],
  );

  const footerMarkup = !isNew ? (
    <Button destructive onClick={handleRemove} loading={buttonSpinning}>
      Rimuovi sconto
    </Button>
  ) : undefined;

  return (
    <Modal
      open={modalStatus}
      onClose={handleModalClose}
      title={discount === null ? 'Aggiungi sconto' : 'Modifica sconto'}
      primaryAction={{
        content: 'Aggiungi sconto',
        onAction: handleAdd,
        loading: buttonSpinning,
      }}
      secondaryActions={isNew ? secondaryActions : undefined}
      footer={footerMarkup}
    >
      <Modal.Section>
        <FormLayout>
          {/* <Select label="Tipo di sconto" options={typeOptions} onChange={handleTypeChange} value={type} /> */}
          <FormLayout.Group>
            <Select label="Valore sconto" options={valueOptions} onChange={handleValueTypeChange} value={discount?.value_type} />
            {discount?.value_type === 'percentage' ? (
              <TextField
                label="Valore"
                autoComplete="off"
                value={discount.value.toString()}
                onChange={handleValueChange}
                type="number"
                min={0}
                step={0.01}
                suffix="%"
              />
            ) : (
              <TextField
                label="Valore"
                autoComplete="off"
                value={discount?.value.toString()}
                onChange={handleValueChange}
                type="number"
                min={0}
                step={0.01}
                prefix="EUR"
                suffix="€"
              />
            )}
          </FormLayout.Group>
          <TextField
            label="Motivo"
            helpText="I clienti potranno visualizzare questo motivo"
            value={discount?.description}
            onChange={handleDescriptionChange}
            autoComplete="off"
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
}

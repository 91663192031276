import React, { useCallback, useRef, useState } from 'react';

import { LegacyCard, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer, Banner, Button } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import { useUser } from '../../../utils/PrivateRoute';
import { ReportsCards, RiskFamilyList } from '../components';

export function ReportAllFamily() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const [saveError, setSaveError] = useState(false);

  // States
  const [reportsAvaible, setReportsAvaible] = useState(0);
  const [familyReports, setFamilyReports] = useState(0);
  const [individualReports, setIndividualReports] = useState(0);

  /**
   * Error markups & toast
   */
  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const loadingMarkup = isLoading ? <Loading /> : null;

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      fullWidth
      title="Retail e Famiglie"
      primaryAction={
        <Button primary url="/reports/family/new">
          Nuovo report
        </Button>
      }
    >
      <ReportsCards user={user} familyReports={familyReports} setFamilyReports={setFamilyReports} />

      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        <Layout.Section>
          {/* Reports list */}
          <RiskFamilyList setFamilyReports={setFamilyReports} user={user} />
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  LegacyCard,
  DatePicker,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Loading,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
  LegacyStack,
  Modal,
  DropZone,
  OptionList,
  Text,
  ContextualSaveBar,
  EmptyState,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../components';

import './ClaimDetails.scss';
import { SearchMinor } from '@shopify/polaris-icons';
import { useUser } from '../../../utils/PrivateRoute';
import { useNavigate, useParams } from 'react-router-dom';

export function ClaimDetails() {
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [active, setActive] = useState(false);
  const [uploadActive, setUploadActive] = useState(false);
  const [deleteActive, setDeleteActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);
  const toggleUploadActive = useCallback(() => setUploadActive((uploadActive) => !uploadActive), []);
  const toggleDeleteActive = useCallback(() => setDeleteActive((deleteActive) => !deleteActive), []);

  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const handleDeleteModalChange = useCallback(() => setDeleteModalActive(!deleteModalActive), [deleteModalActive]);

  const [loadError, setLoadError] = useState(false);
  const [saveError, setSaveError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * Customer States
   */
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');

  /**
   * Claim States
   */
  const [note, setNote] = useState('');

  const [defaultState, setDefaultState] = useState<any>({
    note: '',
    selectedDatesCreated: null,
    selectedDatesEvent: null,
    selectedDatesClosed: null,
    selectedStatus: null,
  });

  /**
   * Status
   */
  const [selectedStatus, setSelectedStatus] = useState('aperto');

  const statusOptions = [
    { label: 'Aperto', value: 'aperto' },
    { label: 'Liquidato', value: 'liquidato' },
    { label: 'Senza seguito', value: 'senza seguito' },
  ];

  /**
   * Dates States
   */
  const [monthCreated, setMonthCreated] = useState(new Date().getMonth());
  const [yearCreated, setYearCreated] = useState(new Date().getFullYear());
  const [dateCreatedSelection, setDateCreatedSelection] = useState(false);
  const [selectedDatesCreated, setSelectedDatesCreated] = useState({ start: new Date(), end: new Date() });

  const [monthEvent, setMonthEvent] = useState(new Date().getMonth());
  const [yearEvent, setYearEvent] = useState(new Date().getFullYear());
  const [dateEventSelection, setDateEventSelection] = useState(false);
  const [selectedDatesEvent, setSelectedDatesEvent] = useState({ start: new Date(), end: new Date() });

  const [monthClosed, setMonthClosed] = useState(new Date().getMonth());
  const [yearClosed, setYearClosed] = useState(new Date().getFullYear());
  const [dateClosedSelection, setDateClosedSelection] = useState(false);
  const [selectedDatesClosed, setSelectedDatesClosed] = useState({ start: new Date(), end: new Date() });

  /**
   * Handlers
   */
  const handleCustomerNameChange = useCallback((e: any) => {
    setCustomerName(e);
  }, []);
  const handleCustomerIdChange = useCallback((e: any) => {
    setCustomerId(e);
  }, []);
  const handleNoteChange = useCallback((e: string) => {
    setNote(e);
    setIsDirty(true);
  }, []);
  const handleSelectChange = useCallback((value: any) => {
    setSelectedStatus(value);
    setIsDirty(true);
  }, []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Date handler
   */
  const handleMonthCreatedChange = useCallback(
    (month: number, year: number) => {
      setMonthCreated(month);
      setYearCreated(year);
    },
    [{ monthCreated, yearCreated }],
  );
  const handleDateCreatedSelection = useCallback(() => {
    setDateCreatedSelection(!dateCreatedSelection);
  }, [dateCreatedSelection]);
  const handleSelectedDatesCreated = useCallback(
    (e: any) => {
      setSelectedDatesCreated(e);
      setIsDirty(true);
      if (dateCreatedSelection) setDateCreatedSelection(false);
    },
    [dateCreatedSelection],
  );

  const handleMonthEventChange = useCallback(
    (month: number, year: number) => {
      setMonthEvent(month);
      setYearEvent(year);
    },
    [{ monthEvent, yearEvent }],
  );
  const handleDateEventSelection = useCallback(() => {
    setDateEventSelection(!dateEventSelection);
  }, [dateEventSelection]);
  const handleSelectedDatesEvent = useCallback(
    (e: any) => {
      setSelectedDatesEvent(e);
      setIsDirty(true);
      if (dateEventSelection) setDateEventSelection(false);
    },
    [dateEventSelection],
  );

  const handleMonthClosedChange = useCallback(
    (month: number, year: number) => {
      setMonthClosed(month);
      setYearClosed(year);
    },
    [{ monthClosed, yearClosed }],
  );
  const handleDateClosedSelection = useCallback(() => {
    setDateClosedSelection(!dateClosedSelection);
  }, [dateClosedSelection]);
  const handleSelectedDatesClosed = useCallback(
    (e: any) => {
      setSelectedDatesClosed(e);
      setIsDirty(true);
      if (dateClosedSelection) setDateClosedSelection(false);
    },
    [dateClosedSelection],
  );

  /**
   * Save Bar
   */

  /** Discard */
  const handleDiscard = useCallback(() => {
    setNote(defaultState.note);
    setSelectedDatesCreated(defaultState.selectedDatesCreated);
    setSelectedDatesEvent(defaultState.selectedDatesEvent);
    setSelectedDatesClosed(defaultState.selectedDatesClosed);
    setIsDirty(false);
  }, [defaultState]);

  /** Save data */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims/${params.id}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          note: note,
          selected_dates_event: selectedDatesEvent.start,
          selected_dates_closed: selectedDatesClosed.start,
          status: selectedStatus,
        }),
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        setActive(true);
      } else {
        handleDiscard();
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setSaveError(true);
    }
    setIsDirty(false);
  }, [params.id, note, selectedDatesEvent.start, selectedDatesClosed.start, selectedStatus, handleDiscard]);

  /** Markup */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  /**
   * Delete claim
   */
  const handleDeleteClaim = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims/${params.id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        // Redirect
        navigate('/claims');
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setSaveError(true);
    }
  }, [handleDiscard, history, params.id]);

  /**
   * Search client
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  /**
   * File Upload - Documenti
   */
  const [files, setFiles] = useState<any>([]);
  const [fileID, setFileID] = useState<any>([]);
  const [fileNames, setFileNames] = useState<any>([]);
  const [txtFileUploadValue, setTxtFileUploadValue] = useState('');
  const [tmpFileUploadValue, setTmpFileUploadValue] = useState<any>();
  const [selectedFileUpload, setSelectedFileUpload] = useState<any>([]);
  const [optionsFileUpload, setOptionsFileUpload] = useState<any>([]);
  const [activeFileUpload, setActiveFileUpload] = useState(false);
  const [isAdderFileUpload, setIsAdderFileUpload] = useState(false);

  /**
   * Data fetching:
   * - fetch claim
   */
  useEffect(() => {
    const fetchClaim = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims/${params.id}`, {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const struct = response.data;

          setDefaultState({
            note: struct.claim.note,
            selectedStatus: struct.claim.status,
            selectedDatesCreated: {
              start: new Date(struct.claim.date_created),
              end: new Date(struct.claim.date_created),
            },
            selectedDatesEvent: {
              start: new Date(struct.claim.date_event),
              end: new Date(struct.claim.date_event),
            },
            selectedDatesClosed: {
              start: new Date(struct.claim.date_closed),
              end: new Date(struct.claim.date_closed),
            },
          });

          setSelectedStatus(struct.claim.status);
          setInputValue(struct.customer.name);

          setSelectedDatesCreated({
            start: new Date(struct.claim.date_created),
            end: new Date(struct.claim.date_created),
          });
          setSelectedDatesEvent({
            start: new Date(struct.claim.date_event),
            end: new Date(struct.claim.date_event),
          });
          setSelectedDatesClosed({
            start: new Date(struct.claim.date_closed),
            end: new Date(struct.claim.date_closed),
          });
          setNote(struct.claim.note);

          // Files
          const fileArray = [];
          const fileNamesArray = [];
          const fileIdsArray = [];
          const fileOptions = [];

          for (const item of struct.claim.files) {
            fileArray.push('Document');
            fileNamesArray.push(item.title);
            fileIdsArray.push(item._id);
            const fileName = item.title.split('__')[1];
            fileOptions.push({ label: fileName, value: item.key });
          }
          setFiles(fileArray);
          setFileNames(fileNamesArray);
          setFileID(fileIdsArray);
          setOptionsFileUpload(fileOptions);

          setIsLoading(false);
        } else {
          setIsLoading(false);
          setLoadError(true);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClaim();
  }, [params.id]);

  /**
   * Autocomplete Controls
   */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = options.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      handleCustomerNameChange(selected);
      handleCustomerIdChange(selectedValue[0].value);
    },
    [handleCustomerIdChange, handleCustomerNameChange, options],
  );

  const customerTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Cliente"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
      disabled={true}
    />
  );

  /**
   * File Uploading Handler
   */
  const toggleModalFileUpload = useCallback(() => setActiveFileUpload((activeFileUpload: boolean) => !activeFileUpload), []);
  const toggleIsAdderFileUpload = useCallback((e: boolean) => {
    setIsAdderFileUpload((isAdderFileUpload) => {
      isAdderFileUpload = e;
      return isAdderFileUpload;
    });
  }, []);

  const handleFileUploadAdd = useCallback(
    (e: any, fn: string) => {
      if (e === '' || e === undefined || fn === '' || fn === undefined) return;

      setFiles((files: any) => {
        const newFiles = files;
        //@ts-ignore
        newFiles.push(e);
        return newFiles;
      });

      setFileNames((fileNames: any) => {
        const newFileNames = fileNames;
        //@ts-ignore
        newFileNames.push(fn);
        return newFileNames;
      });

      setOptionsFileUpload((optionsFileUpload: any) => {
        const newOptions = optionsFileUpload;
        //@ts-ignore
        newOptions.push({ label: fn.split('__')[1], value: fn });
        return newOptions;
      });

      const addFile = async () => {
        try {
          const fd = new FormData();
          fd.append('document', e);

          const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims/${params.id}/upload`, {
            method: 'POST',
            credentials: 'include',
            body: fd,
          });
          const response = await data.json();
          if (response.status === 'success') {
            setUploadActive(true);
          } else {
            setSaveError(true);
          }
        } catch (error) {
          console.log(error);
          setSaveError(true);
        }
      };
      addFile();
    },
    [params.id],
  );

  const handleConfirmFileUpload = useCallback(() => {
    if (isAdderFileUpload) {
      handleFileUploadAdd(tmpFileUploadValue, txtFileUploadValue);
    }
    // else {
    //   handleFileUploadModify(tmpFileUploadValue, txtFileUploadValue, selectedFileUpload[0]);
    // }

    toggleModalFileUpload();
  }, [isAdderFileUpload, toggleModalFileUpload, handleFileUploadAdd, tmpFileUploadValue, txtFileUploadValue, selectedFileUpload]);

  const handleTmpFileUploadValue = useCallback((e: any) => {
    setTmpFileUploadValue(e);
  }, []);

  const handleAddFileUpload = useCallback(
    (_dropFiles: any, acceptedFiles: any, _rejectedFiles: any) => {
      toggleModalFileUpload();
      toggleIsAdderFileUpload(true);
      const blob = acceptedFiles[0];
      const file = new File([blob], params.id + '__' + acceptedFiles[0].name);
      setTxtFileUploadValue(file.name);
      setTmpFileUploadValue(file);
    },
    [toggleModalFileUpload, toggleIsAdderFileUpload, params.id],
  );

  const handleSelectionFileUpload = useCallback((e: any) => {
    setSelectedFileUpload(e);
  }, []);

  /**
   * Delete file
   */
  const handleDelFileUpload = useCallback(() => {
    if (selectedFileUpload[0] === '' || selectedFileUpload[0] === undefined) return;

    const modFileName = selectedFileUpload[0];
    const modFile = files[fileNames.indexOf(selectedFileUpload[0])];
    const modFileID = fileID[fileNames.indexOf(selectedFileUpload[0])];

    const delFile = async () => {
      try {
        const tmp = selectedFileUpload[0].split('/');
        let fileKey = selectedFileUpload[0];
        if (tmp.length === 1) {
          fileKey = `${user.id}/${fileKey}`;
        }
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/claims/${params.id}/files/${btoa(fileKey)}`, {
          method: 'DELETE',
          credentials: 'include',
        });
        const response = await data.json();
        if (response.status === 'success') {
          setDeleteActive(true);
        } else {
          setSaveError(true);
        }
      } catch (error) {
        console.log(error);
        setSaveError(true);
      }
    };
    delFile();

    setFiles((files: any) => {
      //@ts-ignore
      const indexOfDeletion = files.indexOf(modFile);
      files.splice(indexOfDeletion, 1);
      return files;
    });

    setFileNames((fileNames: any) => {
      //@ts-ignore
      const indexOfDeletion = fileNames.indexOf(modFileName);
      fileNames.splice(indexOfDeletion, 1);
      return fileNames;
    });

    setOptionsFileUpload((optionsFileUpload: any) => {
      //@ts-ignore
      const indexOfDeletion = optionsFileUpload.findIndex((op) => op.value === selectedFileUpload[0]);
      optionsFileUpload.splice(indexOfDeletion, 1);
      return optionsFileUpload;
    });

    setFileID((fileID: any) => {
      //@ts-ignore
      const indexOfDeletion = fileID.indexOf(modFileID);
      fileID.splice(indexOfDeletion, 1);
      return fileID;
    });

    handleSelectionFileUpload(['']);
  }, [fileID, fileNames, files, handleSelectionFileUpload, params.id, selectedFileUpload, user.id]);

  /**
   * Download single file
   */
  const handleOpenFile = useCallback(async () => {
    try {
      const tmp = selectedFileUpload[0].split('/');
      let fileKey = selectedFileUpload[0];
      if (tmp.length === 1) {
        fileKey = `${user.id}/${fileKey}`;
      }
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/files/${btoa(fileKey)}`, {
        method: 'GET',
        credentials: 'include',
      });
      const response = await data.blob();

      // saveAs(response, selectedFileUpload[0].split('__')[1]);
    } catch (error) {
      console.log(error);
      setSaveError(true);
    }
  }, [selectedFileUpload, user.id]);

  const fileUpload = !tmpFileUploadValue && <DropZone.FileUpload />;
  const uploadedFile = tmpFileUploadValue && (
    <LegacyStack>
      <div>
        {tmpFileUploadValue.name.split('__')[1]}{' '}
        <Text variant="bodySm" as="p">
          {tmpFileUploadValue.size} bytes
        </Text>
      </div>
    </LegacyStack>
  );

  const fileUploadJSX = (
    <div>
      <LegacyStack vertical>
        <DropZone allowMultiple={false} onDrop={handleAddFileUpload}>
          <DropZone.FileUpload />
        </DropZone>
        <LegacyStack distribution={'center'}>
          {selectedFileUpload[0] && <Button onClick={handleOpenFile}>Apri</Button>}
          {/*selectedFileUpload[0] && <Button onClick={handleModFileUpload}>Modifica</Button>*/}
          {selectedFileUpload[0] && <Button onClick={handleDelFileUpload}>Cancella</Button>}
        </LegacyStack>
        <OptionList onChange={handleSelectionFileUpload} options={optionsFileUpload} selected={selectedFileUpload} />
      </LegacyStack>

      <div>
        <Modal
          open={activeFileUpload}
          onClose={toggleModalFileUpload}
          title={isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
          primaryAction={{
            content: 'Chiudi',
            onAction: toggleModalFileUpload,
          }}
        >
          <Modal.Section>
            <FormLayout>
              <FormLayout.Group>
                <div style={{ height: 114 }}>
                  <DropZone onDrop={handleTmpFileUploadValue}>
                    {uploadedFile}
                    {fileUpload}
                  </DropZone>
                </div>
              </FormLayout.Group>
              {/*<FormLayout.Group>
                         <TextField autoComplete="on" type="text" label="Nome File" value={txtFileUploadValue} onChange={handleTxtFileUploadValue} />
                       </FormLayout.Group>*/}
            </FormLayout>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <Button primary onClick={handleConfirmFileUpload}>
              {isAdderFileUpload ? 'Conferma Aggiunta' : 'Conferma Modifica'}
            </Button>
          </Modal.Section>
        </Modal>
      </div>
    </div>
  );

  /**
   * Delete quote modal markup
   */
  const deleteModalMarkup = (
    <Modal
      open={deleteModalActive}
      onClose={handleDeleteModalChange}
      title="Elimina sinitro"
      primaryAction={{
        content: 'Continua',
        onAction: handleDeleteClaim,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Cancella',
          onAction: handleDeleteModalChange,
        },
      ]}
    >
      <Modal.Section>
        <TextContainer>
          <p>Si è sicuri di voler procedere con l'eliminazione del sinistro?</p>
          <p>Una volta che si è proceduto non è possibile tornare indietro.</p>
        </TextContainer>
      </Modal.Section>
    </Modal>
  );

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const toastUploadMarkup = uploadActive ? <Toast content="Il file è stato caricato con successo." onDismiss={toggleUploadActive} /> : null;

  const toastDeleteMarkup = deleteActive ? <Toast content="Il file è stato rimosso con successo." onDismiss={toggleDeleteActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page
      title="Sinistro"
      backAction={{ content: 'Sinistri', url: '/claims' }}
      primaryAction={{
        content: 'Elimina',
        destructive: true,
        onAction: handleDeleteModalChange,
      }}
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        {/* Panoramica cliente */}
        <Layout.AnnotatedSection title="Dettagli cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={customerTextField} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Dettagli Sinistro */}
        <Layout.AnnotatedSection title="Dettagli Sinistro">
          <LegacyCard sectioned>
            <FormLayout>
              {/* date_created */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data apertura sinistro"
                  value={selectedDatesCreated.start.toLocaleDateString()}
                />
                <Button onClick={handleDateCreatedSelection} disabled>
                  Seleziona data apertura sinistro
                </Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateCreatedSelection && (
                  <DatePicker
                    month={monthCreated}
                    year={yearCreated}
                    onChange={handleSelectedDatesCreated}
                    onMonthChange={handleMonthCreatedChange}
                    selected={selectedDatesCreated}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
              {/* date_event */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data evento sinistro"
                  value={selectedDatesEvent.start.toLocaleDateString()}
                />
                <Button onClick={handleDateEventSelection}>Seleziona data evento sinistro</Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateEventSelection && (
                  <DatePicker
                    month={monthEvent}
                    year={yearEvent}
                    onChange={handleSelectedDatesEvent}
                    onMonthChange={handleMonthEventChange}
                    selected={selectedDatesEvent}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
              {/* date_closed */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data chiusura sinistro"
                  value={selectedDatesClosed.start.toLocaleDateString()}
                />
                <Button onClick={handleDateClosedSelection}>Seleziona data chiusura sinistro</Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateClosedSelection && (
                  <DatePicker
                    month={monthClosed}
                    year={yearClosed}
                    onChange={handleSelectedDatesClosed}
                    onMonthChange={handleMonthClosedChange}
                    selected={selectedDatesClosed}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Status */}
        <Layout.AnnotatedSection title="Stato">
          <LegacyCard sectioned>
            <Select label="Stato" options={statusOptions} onChange={handleSelectChange} value={selectedStatus} />
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Note */}
        <Layout.AnnotatedSection title="Note">
          <LegacyCard sectioned>
            <TextField autoComplete="on" type="text" label="Note sinistro" value={note} onChange={handleNoteChange} multiline={6} />
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Documents */}
        <Layout.AnnotatedSection title="Documenti">
          <LegacyCard sectioned>{fileUploadJSX}</LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  // ---- Error ----
  const errorPageMarkup = (
    <Page>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <EmptyState
              heading="Nessun sinistro presente a questo indirizzo"
              image="https://cdn.shopify.com/shopifycloud/web/assets/v1/08f1b23a19257042c52beca099d900b0.svg"
            >
              <p>Controlla l'URL e riprova oppure usa la barra di ricerca per trovare ciò di cui hai bisogno.</p>
            </EmptyState>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : loadError ? errorPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {contextualSaveBarMarkup}
      {pageMarkup}
      {toastMarkup}
      {toastUploadMarkup}
      {toastDeleteMarkup}
      {deleteModalMarkup}
    </Frame>
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Banner,
  LegacyCard,
  ContextualSaveBar,
  Frame,
  Layout,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  Toast,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../../components';

import './OrganizationProducts.scss';
import axios from 'axios';
import { OrganizationProductList } from './OrganizationProductList';
import { CommissionModal } from './CommissionModal';
import { OrganizationProduct } from '../../../../types';
import { useUser } from '../../../../utils/PrivateRoute';
import { useParams } from 'react-router-dom';

export function OrganizationProducts() {
  const { user } = useUser();
  const params = useParams();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  const [commissionModalActive, setCommissionModalActive] = useState(false);

  /**
   * States
   */
  const [orgProducts, setOrgProducts] = useState<OrganizationProduct[]>([]);
  const [products, setProducts] = useState<string[]>([]);

  // Organization product
  const [orgProduct, setOrgProduct] = useState<OrganizationProduct | null>(null);
  const [newOrgProduct, setNewOrgProduct] = useState<boolean>(true);

  // Default
  const [defaultState, setDefaultState] = useState({
    name: '',
  });

  /** Discard */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);
  }, [defaultState]);

  /**
   * Data fetching:
   * - fetch organization
   * - fetch products
   */
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const { organization } = data.data;
          setDefaultState({
            name: organization.name,
          });
          setOrgProducts(organization.products ? organization.products : []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchProducts = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/products', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          setProducts(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganization().then(() => {
      fetchProducts();
    });
  }, [params.id, update]);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      console.log(orgProducts);

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/products`,
        {
          products: orgProducts,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setActive(true);
        setIsDirty(false);
        setUpdate(!update);
      } else {
        handleDiscard();
        setError(true);
      }
    } catch (error) {
      console.log(error);
      handleDiscard();
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id, orgProducts, handleDiscard, update]);

  /**
   * Handlers
   */
  // Handle update orgProducts
  const handleUpdateOrgProducts = useCallback((orgProducts: OrganizationProduct[]) => {
    setOrgProducts(orgProducts);
    setIsDirty(true);
  }, []);

  // Handle commission modal
  // Add product to orgProducts and add value and value_type
  const handleCommissionModal = useCallback(
    (productId: string, orgProduct: OrganizationProduct | undefined) => {
      if (orgProduct !== undefined) {
        setOrgProduct(orgProduct);
        setNewOrgProduct(false);
      } else {
        // Set default values
        setOrgProduct({
          product: productId,
          value: 0,
          value_type: 'fixed_amount',
          active: true,
        });

        setNewOrgProduct(true);
      }

      setCommissionModalActive(true);
    },
    [orgProducts],
  );

  // Handle commission modal save
  const handleCommissionModalSave = useCallback(
    (orgProduct: OrganizationProduct, reports?: { _id: string; name: string }[]) => {
      const tmp = orgProducts;
      console.log(reports);
      // If we are editing the orgProduct, then we need to update the value and the value_type
      if (!newOrgProduct) {
        const updatedOrgProducts = tmp.map((orgProductItem) => {
          if (orgProductItem.product === orgProduct.product) {
            const tmpItem = {
              ...orgProductItem,
              value: orgProduct.value,
              value_type: orgProduct.value_type,
              active: orgProduct.active,
            };
            if (reports) {
              tmpItem.reports = reports;
            } else {
              tmpItem.reports = null;
            }
            return tmpItem;
          } else {
            return orgProductItem;
          }
        });

        setOrgProducts(updatedOrgProducts);
        console.log(updatedOrgProducts);
      } else {
        setOrgProducts([...tmp, orgProduct]);
      }

      setCommissionModalActive(false);
      setIsDirty(true);
    },
    [orgProducts, newOrgProduct],
  );

  // Handle commission modal remove orgProduct
  const handleCommissionModalRemove = useCallback(
    (orgProduct: OrganizationProduct) => {
      const tmp = orgProducts;
      const updatedOrgProducts = tmp.filter((orgProductItem) => orgProductItem.product !== orgProduct.product);
      setOrgProducts(updatedOrgProducts);
      setIsDirty(true);
    },
    [orgProducts],
  );

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Banner
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title={`${defaultState.name}`} backAction={{ content: 'Organizzazioni', url: `/admin/organizations/${params.id}` }} fullWidth>
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {/* Panoramica organizazzione */}
        <Layout.Section>
          <LegacyCard title="Prodotti">
            <LegacyCard.Section>
              <p>Attiva i prodotti per l'organizzazione e imposta le loro provvigioni.</p>
            </LegacyCard.Section>
            <OrganizationProductList
              products={products}
              orgProducts={orgProducts}
              updateOrgProducts={handleUpdateOrgProducts}
              handleCommissionModal={handleCommissionModal}
            />
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleActive} /> : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {CommissionModal(
        commissionModalActive,
        setCommissionModalActive,
        orgProduct,
        setOrgProduct,
        newOrgProduct,
        handleCommissionModalSave,
        handleCommissionModalRemove,
      )}
      {toastMarkup}
    </Frame>
  );
}

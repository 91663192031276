import React, { useCallback, useRef, useState } from 'react';

import { Button, Frame, Layout, Page, Toast, Banner } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, OrderList } from '../../../components';

import './OrderAll.scss';
import { useUser } from '../../../utils/PrivateRoute';

export function OrderAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  const [active, setActive] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [counterError, setCounterError] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const counterErrorMarkup = counterError && (
    <Layout.Section>
      <Banner title={'Uno o più ordini non possono essere rimossi'} status="critical" onDismiss={() => setCounterError(false)}>
        <p>Le polizze sostituite non possono essere rimosse. Si è pregati di controllare e riprovare.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const pageMarkup = (
    <Page
      fullWidth
      title="Ordini"
      primaryAction={
        <Button primary url="/orders/new">
          Crea preventivo
        </Button>
      }
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {counterErrorMarkup}
        {/* List */}
        <Layout.Section>
          <OrderList setActive={setActive} setSaveError={setSaveError} setCounterError={setCounterError} user={user} />
        </Layout.Section>
      </Layout>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}

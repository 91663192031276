import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner, LegacyCard, Frame, Layout, Loading, Page, Spinner, LegacyStack, Text, Tooltip } from '@shopify/polaris';
import { DataPoint, DataSeries, LineChart, PolarisVizProvider } from '@shopify/polaris-viz';

import { TopBarMarkup, NavigationMarkup, OrganizationAnalyticsDatePicker } from '../../../components';

import './Analytics.scss';
import { parseDateLabelChart } from '../../../utils/Common';
import { useUser } from '../../../utils/PrivateRoute';

export function OrganizationAnalytics() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(true);
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Analytics
   */
  const [premioNetto, setPremioNetto] = useState([]);
  const [commissions, setCommissions] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [commissionsValue, setCommissionsValue] = useState([]);

  // Pass function to OrganizationAnalyticsDatePicker
  const handleAnalytics = (data: any) => {
    setPremioNetto(data.premioNetto || []);
    setCommissions(data.commissions || []);
    setCustomers(data.customers || []);
    setPolicies(data.policies || []);
    setCommissionsValue(data.commissionsValue || []);
  };

  /**
   * Banner markups
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Charts
   */
  /** Policies */
  const policiesData: DataPoint[] = [];
  const policiesDataMap = new Map<string, number | undefined>();
  policies.forEach((policy: any) => {
    const date_created = parseDateLabelChart(new Date(policy.date_created));
    if (policiesDataMap.has(date_created)) {
      let tmp = policiesDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      policiesDataMap.set(date_created, tmp);
    } else {
      policiesDataMap.set(date_created, 1);
    }
  });

  // Convert policiesDataMap to policiesData
  policiesDataMap.forEach((value: number | undefined, key: string) => {
    policiesData.push({
      key: key,
      value: value || null,
    });
  });

  const policiesDataSerie: DataSeries[] = [
    {
      data: policiesData,
      color: '#00FFAA',
      name: 'Polizze emesse',
    },
  ];

  const policiesChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={policiesDataSerie} showLegend={false} emptyStateText="Nessuna polizza" />
    </LegacyStack>
  );

  /** Premio netto */
  const premioNettoData: DataPoint[] = [];
  const premioNettoDataMap = new Map<string, number | undefined>();
  const premioNettoArray = Array.isArray(premioNetto) ? premioNetto : [];
  premioNettoArray.forEach((premio: any) => {
    const date_created = parseDateLabelChart(new Date(premio.date_created));
    const totalPrice = premio.total_price;
    if (premioNettoDataMap.has(date_created)) {
      let tmp = premioNettoDataMap.get(date_created);
      // @ts-ignore
      tmp += totalPrice;
      premioNettoDataMap.set(date_created, tmp);
    } else {
      premioNettoDataMap.set(date_created, totalPrice);
    }
  });

  // Convert premioNettoDataMap to premioNettoData
  premioNettoDataMap.forEach((value: number | undefined, key: string) => {
    premioNettoData.push({
      key: key,
      value: value || null,
    });
  });

  const premioNettoDataSerie: DataSeries[] = [
    {
      data: premioNettoData,
      color: '#00FFAA',
      name: 'Premio netto',
    },
  ];

  const totalPremio = premioNettoData.reduce((acc, dataPoint) => {
    return acc + (dataPoint.value || 0);
  }, 0);

  const premioNettoChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={premioNettoDataSerie} showLegend={false} emptyStateText="Nessun premio" />
    </LegacyStack>
  );

  /** Commissions */
  const commissionData: DataPoint[] = [];
  const commissionDataMap = new Map<string, number | undefined>();
  const commissionsArray = Array.isArray(commissions) ? commissions : [];
  commissionsArray.forEach((commission: any) => {
    const date_created = parseDateLabelChart(new Date(commission.date_created));
    // Commission
    if (commissionDataMap.has(date_created)) {
      let tmp = commissionDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      commissionDataMap.set(date_created, tmp);
    } else {
      commissionDataMap.set(date_created, 1);
    }
  });

  // Convert commissionDataMap to commissionData
  commissionDataMap.forEach((value: number | undefined, key: string) => {
    commissionData.push({
      key: key,
      value: value || null,
    });
  });

  const commissionDataSerie: DataSeries[] = [
    {
      data: commissionData,
      color: '#00FFAA',
      name: 'Provvigioni',
    },
  ];

  const commissionChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={commissionDataSerie} showLegend={false} emptyStateText="Nessuna provvigione" />
    </LegacyStack>
  );

  /** Customers */
  const customersData: DataPoint[] = [];
  const customersDataMap = new Map<string, number | undefined>();
  customers.forEach((customer: any) => {
    const date_created = parseDateLabelChart(new Date(customer.date_created));
    if (customersDataMap.has(date_created)) {
      let tmp = customersDataMap.get(date_created);
      // @ts-ignore
      tmp += 1;
      customersDataMap.set(date_created, tmp);
    } else {
      customersDataMap.set(date_created, 1);
    }
  });

  // Convert customersDataMap to customersData
  customersDataMap.forEach((value: number | undefined, key: string) => {
    customersData.push({
      key: key,
      value: value || null,
    });
  });

  const customersDataSerie: DataSeries[] = [
    {
      data: customersData,
      color: '#00FFAA',
      name: 'Clienti',
    },
  ];

  const customersChart = (
    <LegacyStack vertical>
      <Text variant="headingMd" as="h2">
        Evoluzione nel tempo
      </Text>
      <LineChart data={customersDataSerie} showLegend={false} emptyStateText="Nessun cliente" />
    </LegacyStack>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page fullWidth title="Dashboard">
      {/* Banners */}
      {bannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <OrganizationAnalyticsDatePicker handleAnalytics={handleAnalytics} userId={user.id} />
          <Text as="span" color="subdued">
            Usato per la visualizzazione dei dati
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Polizze emesse */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Polizze calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Polizze emesse</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale delle polizze create nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {Number(policies.length)}
                    </Text>
                  </>
                )}
                {policiesChart}
              </LegacyStack>
            </LegacyCard>
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numero di clienti attivi" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Clienti</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale dei clienti nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {Number(customers.length)}
                    </Text>
                  </>
                )}
                {customersChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Premio netto */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premio netto calcolato a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Premio netto</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale dei premi raccolti nel periodo selezionato
                    </Text>
                    <Text variant="headingLg" as="h3">
                      {Number(totalPremio).toFixed(2)} €
                    </Text>
                  </>
                )}
                {premioNettoChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Commission */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Provvigioni calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Provvigioni</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <>
                    <Text variant="headingMd" as="p">
                      Totale delle provvigioni create nel periodo selezionato
                    </Text>
                    <Text variant="heading2xl" as="p">
                      {Number(commissionsValue).toFixed(2)} €
                    </Text>
                  </>
                )}
                {commissionChart}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      <PolarisVizProvider
        themes={{
          Default: {
            chartContainer: {
              backgroundColor: '#fff',
              padding: '0.5rem 0',
            },
            grid: {
              horizontalMargin: 0,
              horizontalOverflow: false,
              // showVerticalLines: true,
              color: '#bfbfbf',
            },
            yAxis: {
              backgroundColor: '#fff',
              labelColor: '#8c8c8c',
            },
            xAxis: {
              labelColor: '#8c8c8c',
            },
            line: {
              hasSpline: true,
              hasArea: true,
            },
          },
        }}
      >
        {loadingMarkup}
        {pageMarkup}
      </PolarisVizProvider>
    </Frame>
  );
}

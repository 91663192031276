/**
 * Modal to create a new customer
 */
import { Autocomplete, Banner, FormLayout, Icon, Modal, Select, TextField } from '@shopify/polaris';
import { SearchMinor } from '@shopify/polaris-icons';
import React, { useCallback, useEffect, useState } from 'react';

export const NewCustomerModal = (active: boolean, setActive: any, setUpdated: any, setToastActive: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [existError, setExistError] = useState(false);

  const [firstname, setFirstname] = useState(''); // Firstname used as both first name and company name
  const [lastname, setLastname] = useState(''); //Only used if private customer is selected
  const [fiscalCode, setFiscalCode] = useState('');
  const [vat, setVat] = useState(''); // Only used if company customer is selected
  const [ateco, setAteco] = useState(''); // Only used if company customer is selected
  const [job, setJob] = useState('');

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [type, setType] = useState('private'); // Can be either 'private' or 'company'
  const [line, setLine] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('IT'); // Value is country.code
  const [province, setProvince] = useState('');
  const [countryOptions, setCountryOptions] = useState([]); //Used to load array of countries

  /**
   * Empty fields
   */
  const [emptyFields, setEmptyFields] = useState({
    firstname: false,
    lastname: false,
    fiscalCode: false,
    email: false,
  });

  /**
   * Handlers
   */
  const handleFirstnameChange = useCallback(
    (e: string) => {
      setFirstname(e);
      if (emptyFields.firstname) setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false }));
    },
    [emptyFields.firstname],
  );

  const handleLastnameChange = useCallback(
    (e: string) => {
      setLastname(e);
      if (emptyFields.lastname) setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: false }));
    },
    [emptyFields.lastname],
  );

  const handleFiscalCodeChange = useCallback(
    (e: string) => {
      setFiscalCode(e);
      if (emptyFields.fiscalCode) setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: false }));
    },
    [emptyFields.fiscalCode],
  );

  // Email
  const handleEmailChange = useCallback((e: string) => {
    if (emptyFields.email) setEmptyFields((emptyFields) => ({ ...emptyFields, email: false }));
    setEmail(e);
  }, []);

  // Phone
  const handlePhoneChange = useCallback((e: string) => {
    setPhone(e);
  }, []);

  // Vat
  const handleVatChange = useCallback((e: string) => {
    setVat(e);
  }, []);

  const handleAtecoChange = useCallback((e: any) => {
    setAteco(e);
  }, []);

  const handleTypeChange = useCallback((e: any) => {
    setType(e);
    setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: false, lastname: false, fiscalCode: false }));
  }, []);

  // Line
  const handleLineChange = useCallback((e: string) => {
    setLine(e);
  }, []);

  // City
  const handleCityChange = useCallback((e: string) => {
    setCity(e);
  }, []);

  // Zip
  const handleZipChange = useCallback((e: string) => {
    // Allow only numbers
    if (e.match(/^[0-9]*$/)) {
      setZip(e);
    }

    // Allow only 5 numbers
    if (e.length > 5) {
      setZip(e.substring(0, 5));
    }
  }, []);

  // Country
  const handleCountryChange = useCallback((e: string) => {
    setCountry(e);
  }, []);

  // Used to set type of customer options
  const options = [
    { label: 'Privato', value: 'private' },
    { label: 'Azienda', value: 'company' },
  ];

  /**
   * Search jobs
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [jobOptions, setJobOptions] = useState([]);

  /**
   * Search ateco
   */
  const [selectedAtecoOptions, setSelectedAtecoOptions] = useState([]);
  const [inputAtecoValue, setInputAtecoValue] = useState('');
  const [deselectedAtecoOptions, setDeselectedAtecoOptions] = useState([]);
  const [atecoOptions, setAtecoOptions] = useState([]);

  /**
   * Fetch data:
   * - jobs
   * - ateco codes
   */
  useEffect(() => {
    const fetchJobs = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/jobs', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedOptions(tmp);
          // @ts-ignore
          setJobOptions(tmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    const fetchAteco = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/ateco', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ value: item._id, label: item.label });
          }
          // @ts-ignore
          setDeselectedAtecoOptions(tmp);
          // @ts-ignore
          setAtecoOptions(tmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchJobs();
    fetchAteco();
  }, []);

  /**
   * Autocomplete Controls
   */

  /** Job */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setJobOptions(deselectedOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setJobOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = jobOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setJob(selectedValue[0].value);
    },
    [jobOptions],
  );

  const jobTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Professione"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /** Ateco */
  const updateAtecoText = useCallback(
    (value: string) => {
      setInputAtecoValue(value);

      if (value === '') {
        setAtecoOptions(deselectedAtecoOptions);
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedAtecoOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setAtecoOptions(resultOptions);
    },
    [deselectedAtecoOptions],
  );

  const updateAtecoSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = atecoOptions.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedAtecoOptions(selected);
      setInputAtecoValue(selectedValue[0].label);
      // handleCustomerNameChange(selected);
      // handleCustomerIdChange(selectedValue[0].value);
      setAteco(selectedValue[0].value);
    },
    [atecoOptions],
  );

  const atecoTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateAtecoText}
      label="ATECO"
      value={inputAtecoValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
    />
  );

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      setButtonSpinning(true);
      // Check firstname & lastname
      if (type === 'private' && (firstname === '' || lastname === '' || fiscalCode === '')) {
        if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
        if (lastname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, lastname: true }));
        if (fiscalCode === '') setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: true }));

        setButtonSpinning(false);
        return;
      } else if (type === 'company' && (firstname === '' || fiscalCode === '')) {
        if (firstname === '') setEmptyFields((emptyFields) => ({ ...emptyFields, firstname: true }));
        if (fiscalCode === '') setEmptyFields((emptyFields) => ({ ...emptyFields, fiscalCode: true }));

        setButtonSpinning(false);
        return;
      }

      setButtonSpinning(true);
      const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/customers/new', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
        },
        body: JSON.stringify({
          name: firstname + (type === 'private' ? ' ' + lastname : ''),
          email: email,
          fiscal_code: fiscalCode,
          vat: vat,
          ateco: ateco,
          job: job,
          type: type,
          phone: phone,
          line: line,
          city: city,
          zip: zip,
          country: country,
        }),
      });
      const data = await response.json();

      if (data.status === 'success') {
        setActive(false);
        setToastActive(true);
        setUpdated(true);
      } else if (data.status === 'user_exists') {
        setExistError(true);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [firstname, type, lastname, email, fiscalCode, vat, ateco, job, phone, line, city, zip, country]);

  /**
   * Error markups & toast
   */
  const saveErrorMarkup = saveError && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  const existErrorMarkup = existError && (
    <Modal.Section>
      <Banner title="Esiste già un cliente associato a questo codice fiscale" status="critical" onDismiss={() => setExistError(false)}>
        <p>Si è pregati di controllare il codice fiscale se si desidera proseguire.</p>
      </Banner>
    </Modal.Section>
  );

  const modalMarkup = (
    <Modal
      open={active}
      onClose={() => setActive(!active)}
      title="Crea un nuovo cliente"
      primaryAction={{
        content: 'Salva cliente',
        onAction: handleSave,
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setActive(!active),
        },
      ]}
    >
      {/* Banner */}
      {saveErrorMarkup}
      {existErrorMarkup}
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Tipologia cliente" options={options} onChange={handleTypeChange} value={type} />
          </FormLayout.Group>
          <FormLayout.Group>
            {/* Logic to display either private's or company's naming info */}
            {type === 'private' && (
              <TextField
                autoComplete="on"
                type="text"
                label="Nome"
                value={firstname}
                onChange={handleFirstnameChange}
                error={emptyFields.firstname && 'Il nome è obbligatorio'}
              />
            )}
            {type === 'private' && (
              <TextField
                autoComplete="on"
                type="text"
                label="Cognome"
                value={lastname}
                onChange={handleLastnameChange}
                error={emptyFields.lastname && 'Il cognome è obbligatorio'}
              />
            )}
            {type === 'company' && (
              <TextField
                autoComplete="on"
                type="text"
                label="Ragione Sociale"
                value={firstname}
                onChange={handleFirstnameChange}
                error={emptyFields.firstname && 'La regione sociale è obbligatoria'}
              />
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="text"
              label="Codice Fiscale"
              value={fiscalCode}
              onChange={handleFiscalCodeChange}
              error={emptyFields.fiscalCode && 'Il codice fiscale è obbligatorio'}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            {type === 'private' && <Autocomplete options={jobOptions} selected={selectedOptions} onSelect={updateSelection} textField={jobTextField} />}
          </FormLayout.Group>
          <FormLayout.Group>
            {/* Logic checks if vat is to be displayed */}
            {type === 'company' && <TextField autoComplete="on" type="text" label="Partita IVA" value={vat} onChange={handleVatChange} />}
            {/* {type === 'company' && <TextField autoComplete="on" type="text" label="Codice ATECO" value={ateco} onChange={handleAtecoChange} />} */}
            {type === 'company' && (
              <Autocomplete options={atecoOptions} selected={selectedAtecoOptions} onSelect={updateAtecoSelection} textField={atecoTextField} />
            )}
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField
              autoComplete="on"
              type="email"
              label="Email"
              value={email}
              onChange={handleEmailChange}
              error={emptyFields.email && "L'email è obbligatoria"}
            />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="tel" label="Numero di telefono" value={phone} onChange={handlePhoneChange} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
      <Modal.Section>
        <FormLayout>
          <FormLayout.Group>
            <Select label="Stato" options={countryOptions} onChange={handleCountryChange} value={country} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="Indirizzo" value={line} onChange={handleLineChange} />
          </FormLayout.Group>
          <FormLayout.Group>
            <TextField autoComplete="on" type="text" label="CAP" value={zip} onChange={handleZipChange} />
            <TextField autoComplete="on" type="text" label="Città" value={city} onChange={handleCityChange} />
          </FormLayout.Group>
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  return modalMarkup;
};

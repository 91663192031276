import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useOutletContext } from 'react-router-dom';
import { verifyToken } from './Common';
import { Layout, LegacyCard, SkeletonBodyText, TextContainer, SkeletonDisplayText, Frame, Loading } from '@shopify/polaris';

type ContextType = { user: any | null };

/**
 * Private route component
 * Compatible with react-router-dom v6
 */

const skeletonPageMarkup = (
  <div>
    <Frame>
      <Loading />
      {/* Top Bar with Logo */}
      <div
        style={{
          backgroundColor: '#fff',
          padding: '16px',
          borderBottom: '1px solid #dfe3e8',
        }}
      >
        <SkeletonDisplayText size="small" />
      </div>

      <div style={{ display: 'flex' }}>
        {/* Sidebar Section */}
        <div
          style={{
            width: '15%', // Smaller width for the sidebar
            padding: '16px',
            backgroundColor: '#f4f6f8',
          }}
        >
          <TextContainer>
            {/* Loop through to generate skeleton items for the navbar */}
            {Array(12) // Generate 12 lines
              .fill(0)
              .map((_, index) => (
                <div key={index} style={{ padding: '16px' }}>
                  <SkeletonBodyText lines={1} />
                </div>
              ))}
          </TextContainer>
        </div>

        {/* Main Content Section */}
        <div style={{ flex: 1, padding: '16px' }}>
          <Layout.Section>
            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={8} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={5} />
              </TextContainer>
            </LegacyCard>

            <LegacyCard sectioned>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard>
          </Layout.Section>
        </div>
      </div>
    </Frame>
  </div>
);

export function PrivateRoute({ component: Component, path, roles, organization, ...rest }: any) {
  const [auth, setAuth] = useState(false);
  const [goOn, setGoOn] = useState(false);
  const [user, setUser] = useState(null);

  // Show skeleton page immediately while loading
  useEffect(() => {
    verifyToken()
      .then((res: any) => {
        setAuth(res.status);
        if (res.data) {
          setUser(res.data.user);
        }
      })
      .finally(() => {
        setGoOn(true); // Once verification is complete, show the authenticated content
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Show the loading skeleton if the page hasn't finished loading yet
  if (!goOn) {
    return skeletonPageMarkup;
  }

  let res;

  if (auth) {
    // If the user is authenticated and its role is customer, redirect to the dashboard
    // @ts-ignore
    if (user && user.role === 'customer' && process.env.REACT_APP_USER_DASHBOARD_URL) {
      window.location.href = process.env.REACT_APP_USER_DASHBOARD_URL;
    }
    // @ts-ignore
    if ((roles && roles.includes(user.role)) || !roles) {
      res = <Outlet context={{ user }} />;
      // @ts-ignore
    } else if (organization && user && user.organization_id && user.organization_id.length > 0) {
      res = <Outlet context={{ user }} />;
    } else {
      res = <Navigate to="/" />;
    }
  } else {
    res = <Navigate to="/login" />;
  }

  return res;
}

export function useUser() {
  return useOutletContext<ContextType>();
}

/**
 * Modal to change the number of reports
 */

import { Banner, Checkbox, Modal, TextField } from '@shopify/polaris';
import React, { useCallback, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';

interface Props {
  active: boolean;
  /** The reports state */
  reports: number;
  /** The unlimited state */
  unlimited: boolean;
  /** The reports handlers */
  onChangeReports: (reports: number) => void;
  onChangeUnlimited: (unlimited: boolean) => void;
  /** The toast handler */
  onToastActive: (active: boolean) => void;
  onClose: () => void;
}

export function ChangeReportsModal({ active, reports, unlimited, onChangeReports, onChangeUnlimited, onToastActive, onClose }: Props) {
  const params = useParams();

  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [saveError, setSaveError] = useState(false);
  const [existError, setExistError] = useState(false);

  const handleChange = useCallback((newValue: string) => {
    onChangeReports(parseInt(newValue));
    setIsDirty(true);
  }, []);

  const handleChangeCheckbox = useCallback((newChecked: boolean) => {
    onChangeUnlimited(newChecked);
    setIsDirty(true);
  }, []);

  const handleClose = () => {
    onClose();
  };

  const handleSave = useCallback(async () => {
    try {
      setSaveError(false);
      setButtonSpinning(true);

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/organizations/${params.id}/wallet`,
        {
          wallet: { family_analysis: reports, family_unlimited: unlimited },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        onToastActive(true);
        setIsDirty(false);
        onClose();
      } else {
        setSaveError(true);
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      console.log(axiosError);
      setSaveError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [params.id, reports, unlimited, onClose]);

  const saveErrorMarkup = saveError && (
    <Modal.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Modal.Section>
  );

  const existErrorMarkup = existError && (
    <Modal.Section>
      <Banner title="Dato non valido" status="critical" onDismiss={() => setExistError(false)}>
        <p>Si è pregati di controllare il dato inseriti se si desidera proseguire.</p>
      </Banner>
    </Modal.Section>
  );

  const modalMarkup = (
    <Modal
      open={active}
      title="Modifica reports"
      onClose={handleClose}
      primaryAction={{
        content: 'Salva',
        onAction: handleSave,
        loading: buttonSpinning,
        disabled: !isDirty,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: handleClose,
        },
      ]}
    >
      {/* Banner */}
      {saveErrorMarkup}
      {existErrorMarkup}
      <Modal.Section>
        <Checkbox label="Report illimitati" checked={unlimited} onChange={handleChangeCheckbox} />
      </Modal.Section>
      {!unlimited && (
        <Modal.Section>
          <TextField label="Modifica la quantita di reports" type="number" value={reports.toString()} onChange={handleChange} autoComplete="off" />
        </Modal.Section>
      )}
    </Modal>
  );

  return modalMarkup;
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Banner, LegacyCard, Frame, Layout, Loading, Page, Spinner, LegacyStack, Text, Toast, Tooltip } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, DashboardDatePicker, DeadlineList, PrintReportModal } from '../../components';

import './Dashboard.scss';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PrintMinor } from '@shopify/polaris-icons';
import { useUser } from '../../utils/PrivateRoute';
import { DashboardAffinity } from '../DashboardAffinity';

export function Dashboard() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [printError, setPrintError] = useState(false);
  const [trialBannerIsVisible, setTrialBannerIsVisible] = useState(true);
  const date = new Date();
  const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth(), date.getDate()));

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive);
  }, []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive(!data);
  };

  const [printReportModalActive, setPrintReportModalActive] = useState(false);
  const handlePrintReportModal = useCallback(() => setPrintReportModalActive((printReportModalActive) => !printReportModalActive), []);

  const [printToastActive, setPrintToastActive] = useState(false);
  const handlePrintToast = useCallback(() => setPrintToastActive((printToastActive) => !printToastActive), []);

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Analytics
   */
  const [totalPrice, setTotalPrice] = useState(-1);
  const [provvTot, setProvvTot] = useState(-1);
  const [customers, setCustomers] = useState(-1);
  const [deadlines, setDeadlines] = useState([]);
  const [frontItems, setFrontItems] = useState([]);

  // Loading
  useEffect(() => {
    if (customers !== -1) setIsLoading(false);
  }, [customers]);

  // Pass function to DashboardDatePicker
  const updateSelectedDates = (dates: any) => {
    setStartDate(dates.start);
    setEndDate(dates.end);
  };

  const handleAnalytics = (data: any) => {
    setTotalPrice(data.totalPrice);
    setCustomers(data.customers);
    setDeadlines(data.deadlines);
    setFrontItems(data.deadlines);
  };

  // Pass function to ScadenzeList
  const handleFrontItems = (data: any) => {
    setFrontItems(data);
  };

  const handleFrontItemsSort = (order: number) => {
    const tmp = [...frontItems];
    // @ts-ignore
    tmp.sort((a, b) => (a.date_scadenza > b.date_scadenza ? order : -order));
    setFrontItems(tmp);
  };

  /**
   * Handle print report
   */
  const handlePrintReport = useCallback(async () => {
    try {
      alert('to do');
    } catch (error) {
      console.log(error);
    }
  }, []);

  /**
   * Manage stripe payment
   */
  const handleCreateCustomerPortal = useCallback(async () => {
    try {
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/create-customer-portal-session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();

      if (response.status === 'success') {
        // If user does not have an active subscription redirect to checkout page
        // otherwise redirect to customer portal
        if (response.subscription === false && response.data) {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
          await stripe!.redirectToCheckout({
            sessionId: response.data,
          });
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, []);

  /**
   * Banner markup
   */
  const bannerMarkup = error && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printBannerMarkup = printError && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner title="Si è verificato un errore nella stampa del report" status="critical" onDismiss={() => setPrintError(false)}>
            <p>Si è pregati di riprovare più tardi.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  const printToastMarkup = printToastActive && <Toast content="Report stampato con successo" onDismiss={handlePrintToast} />;

  /**
   * Trialing banner
   */

  // Compute how many days of a 14 days trial are left from user date_created
  const daysLeft = user.status === 'trialing' && 14 - (new Date().getDate() - new Date(user.date_created).getDate());

  const trialBannerMarkup = user.status === 'trialing' && trialBannerIsVisible && (
    <Layout>
      <Layout.Section>
        <div style={{ marginBottom: '1.6rem', maxWidth: '800px', margin: 'auto auto 1.6rem' }}>
          <Banner
            title={`${daysLeft} giorni di prova rimasti`}
            action={{
              content: 'Abbonati ora',
              onAction: handleCreateCustomerPortal,
            }}
            onDismiss={() => setTrialBannerIsVisible(false)}
            status="warning"
          >
            <p>Al termine della prova gratuita, il tuo account verrà disattivato ma i dati saranno conservati.</p>
          </Banner>
        </div>
      </Layout.Section>
    </Layout>
  );

  /**
   * Page markup
   */
  const pageMarkup = (
    <Page
      fullWidth
      title="Dashboard"
      secondaryActions={[
        {
          content: 'Genera report',
          accessibilityLabel: 'Genera report',
          icon: PrintMinor as any,
          onAction: () => handlePrintReportModal(),
        },
      ]}
    >
      {/* Banners */}
      {trialBannerMarkup}
      {bannerMarkup}
      {printBannerMarkup}
      <div className="dashboardHeader">
        <LegacyStack alignment="center">
          <DashboardDatePicker updateSelectedDates={updateSelectedDates} handleAnalytics={handleAnalytics} userId={user.id} />
          <Text as="span" color="subdued">
            Usato per la visualizzazione dei dati e delle scadenze
          </Text>
        </LegacyStack>
      </div>
      <div className="dashboardContent">
        {/* First col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Premio netto calcolato a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Premio netto</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(totalPrice).toFixed(2).replace('.', ',')} €
                  </Text>
                )}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
        {/* Second col */}
        {/* <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Provvigioni calcolate a partire dalla data impostata" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Provvigioni</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(provvTot).toFixed(2)} €
                  </Text>
                )}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div> */}
        {/* Third col */}
        <div className="dashboardContentCol">
          <div className="dashboardContentColPadding">
            {/* Active customers */}
            <LegacyCard sectioned>
              <LegacyStack vertical>
                <Tooltip content="Numero di clienti attivi" dismissOnMouseOut preferredPosition="above">
                  <Text variant="headingMd" as="h2">
                    <span className="cardTitle">Clienti</span>
                  </Text>
                </Tooltip>
                {isLoading ? (
                  <LegacyStack distribution="center" alignment="center">
                    <Spinner></Spinner>
                  </LegacyStack>
                ) : (
                  <Text variant="heading2xl" as="p">
                    {Number(customers)}
                  </Text>
                )}
              </LegacyStack>
            </LegacyCard>
          </div>
        </div>
      </div>
      <div className="scadenzeContent">
        <Layout>
          <Layout.AnnotatedSection title="Scadenze" description="Elenco delle polizze in scadenza nel periodo indicato.">
            <DeadlineList
              deadlines={deadlines}
              frontItems={frontItems}
              isLoading={isLoading}
              handleFrontItems={handleFrontItems}
              handleFrontItemsSort={handleFrontItemsSort}
            />
          </Layout.AnnotatedSection>
        </Layout>
      </div>
    </Page>
  );

  const affinityDashboardMarkup = <DashboardAffinity />;

  return user && user.role === 'affinity' ? (
    affinityDashboardMarkup
  ) : (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
      {printToastMarkup}
      {PrintReportModal({
        active: printReportModalActive,
        handleModalChange: handlePrintReportModal,
        organizationId: user.organization,
        toggleToastSuccess: handlePrintToast,
        toggleBannerError: setPrintError,
        startDate: startDate,
        endDate: endDate,
      })}
    </Frame>
  );
}

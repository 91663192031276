import React, { useCallback, useEffect, useRef, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js/pure';

import { Banner, LegacyCard, Frame, Layout, Page, EmptyState } from '@shopify/polaris';

import { TopBarMarkup } from '../../components';

import styles from './disabled.module.css';
import { useParams } from 'react-router-dom';
import { useUser } from '../../utils/PrivateRoute';

export function Disabled() {
  const params = useParams();
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * If user account has been reactivated perform logout
   */
  useEffect(() => {
    const logout = async () => {
      try {
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/logout', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          window.location.href = '/login';
        }
      } catch (error) {
        console.log(error);
      }
    };
    const checkUserDisabled = async () => {
      try {
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/disabled', {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          if (response.data === false) {
            await logout();
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    checkUserDisabled();
  }, []);

  /**
   * Manage stripe payment
   */
  const handleCreateCustomerPortal = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/account/create-customer-portal-session`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const response = await data.json();
      setButtonSpinning(false);

      if (response.status === 'success') {
        // If user does not have an active subscription redirect to checkout page
        // otherwise redirect to customer portal
        if (response.subscription === false && response.data) {
          const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
          await stripe!.redirectToCheckout({
            sessionId: response.data,
          });
        } else if (response.subscription === true && response.data) {
          window.open(response.data, '_blank')?.focus();
        }
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    }
  }, []);

  /**
   * Error banner
   */
  const bannerMarkup = error && (
    <div style={{ marginBottom: '20px' }}>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </div>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page fullWidth>
      <Layout>
        <div className={styles.FullPage}>
          {bannerMarkup}
          <LegacyCard sectioned>
            <EmptyState
              heading="Il tuo account è disattivato"
              action={{
                content: 'Gestisci abbonamento',
                onAction: handleCreateCustomerPortal,
                loading: buttonSpinning,
              }}
              image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png"
            >
              <p>Per poter utilizzare l'applicazione devi sottoscrivere un abbonamento.</p>
            </EmptyState>
          </LegacyCard>
        </div>
      </Layout>
    </Page>
  );

  const pageMarkup = actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
    </Frame>
  );
}

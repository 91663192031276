import React, { useCallback, useEffect, useRef, useState } from 'react';

import { LegacyCard, Frame, Layout, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer, Toast } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import axios, { AxiosError } from 'axios';
import { useUser } from '../../../utils/PrivateRoute';
import { useParams } from 'react-router-dom';

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

export function ReportPurchase() {
  const { user } = useUser();
  const params = useParams();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [update, setUpdate] = useState(false);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * States
   */
  const [organizationId, setOrganizationId] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [stripeCustomerId, setStripeCustomerId] = useState<string>('');
  const [stripeSession, setStripeSession] = useState<string>('');

  /**
   * Data fetching:
   * - fetch organization
   * - fetch users
   */
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = response.data;

        if (data.status === 'success') {
          const organization = data.data.organization;
          setOrganizationId(organization._id);
          setEmail(organization.email);
          setStripeCustomerId(organization.stripe_customer_id);
          setStripeSession(data.data.session.client_secret);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        console.log(axiosError);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganization();
  }, [params.id, update]);

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Acquista report">
      <Layout>
        <stripe-pricing-table
          style={{ width: '100%', height: '100%' }}
          pricing-table-id="prctbl_1PTOizJuPerR8CViU5dhgwMP"
          publishable-key="pk_test_51K5ZtSJuPerR8CViEjCLcUeffXWClKUihOE8pGmmrjhopcy4hVbtxtMblrgPQ2b5gmXxcbAPFRxmfWXxsloBq3S5006XQ8xQnJ"
          client-reference-id={organizationId}
          customer-session-client-secret={stripeSession}
        ></stripe-pricing-table>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  /**
   * Toast markup
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati" onDismiss={toggleActive} /> : null;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}

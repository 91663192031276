import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Autocomplete,
  Button,
  LegacyCard,
  ContextualSaveBar,
  DatePicker,
  FormLayout,
  Frame,
  Icon,
  Layout,
  Loading,
  Page,
  Select,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  TextContainer,
  TextField,
  Toast,
  Banner,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup, contextControlMarkup } from '../../../components';

import './ClaimNew.scss';
import { SearchMinor } from '@shopify/polaris-icons';
import { useUser } from '../../../utils/PrivateRoute';

export function ClaimNew() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(true);
  const [active, setActive] = useState(false);

  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [saveError, setSaveError] = useState(false);
  const [validationCustomer, setValidationCustomer] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: any) => {
    setMobileNavigationActive((data) => !data);
  };

  /**
   * Customer States
   */
  const [customerName, setCustomerName] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [note, setNote] = useState('');

  /**
   * Status
   */
  const [selectedStatus, setSelectedStatus] = useState('aperto');

  const handleSelectChange = useCallback((value: any) => setSelectedStatus(value), []);
  const statusOptions = [
    { label: 'Aperto', value: 'aperto' },
    { label: 'Liquidato', value: 'liquidato' },
    { label: 'Senza seguito', value: 'senza seguito' },
  ];

  /**
   * Dates States
   */
  const [monthCreated, setMonthCreated] = useState(new Date().getMonth());
  const [yearCreated, setYearCreated] = useState(new Date().getFullYear());
  const [dateCreatedSelection, setDateCreatedSelection] = useState(false);
  const [selectedDatesCreated, setSelectedDatesCreated] = useState({ start: new Date(), end: new Date() });

  const [monthEvent, setMonthEvent] = useState(new Date().getMonth());
  const [yearEvent, setYearEvent] = useState(new Date().getFullYear());
  const [dateEventSelection, setDateEventSelection] = useState(false);
  const [selectedDatesEvent, setSelectedDatesEvent] = useState({ start: new Date(), end: new Date() });

  const [monthClosed, setMonthClosed] = useState(new Date().getMonth());
  const [yearClosed, setYearClosed] = useState(new Date().getFullYear());
  const [dateClosedSelection, setDateClosedSelection] = useState(false);
  const [selectedDatesClosed, setSelectedDatesClosed] = useState({ start: new Date(), end: new Date() });

  const handleDiscard = useCallback(() => {
    setCustomerName('');
    setNote('');
    setIsDirty(false);
  }, []);

  /**
   * Save data
   */
  const handleSave = useCallback(async () => {
    try {
      // Check for customer id
      if (customerId === '') {
        setValidationCustomer(true);
        return;
      }

      const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/claims/new', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer_id: customerId,
          selected_dates_created: selectedDatesCreated.start,
          selected_dates_event: selectedDatesEvent.start,
          selected_dates_closed: selectedDatesClosed.start,
          status: selectedStatus,
          note: note,
        }),
      });
      const response = await data.json();

      if (response.status === 'success') {
        setActive(true);
        setTimeout(() => {
          window.location.href = `/claims/${response.data._id}`;
        }, 3000);
      } else {
        setSaveError(true);
      }
    } catch (error) {
      console.log(error);
    }
    setIsDirty(false);
  }, [customerId, note, selectedDatesClosed.start, selectedDatesCreated.start, selectedDatesEvent.start, selectedStatus]);

  /**
   * Customer Handlers
   */
  const handleCustomerNameChange = useCallback((e: any) => {
    setCustomerName(e);
  }, []);
  const handleCustomerIdChange = useCallback((e: any) => {
    setCustomerId(e);
  }, []);
  const handleNoteChange = useCallback((e: string) => {
    setNote(e);
  }, []);

  /**
   * Date picker
   */
  const handleMonthCreatedChange = useCallback(
    (month: number, year: number) => {
      setMonthCreated(month);
      setYearCreated(year);
    },
    [{ monthCreated, yearCreated }],
  );
  const handleDateCreatedSelection = useCallback(() => {
    setDateCreatedSelection(!dateCreatedSelection);
  }, [dateCreatedSelection]);
  const handleSelectedDatesCreated = useCallback(
    (e: any) => {
      setSelectedDatesCreated(e);
      if (dateCreatedSelection) setDateCreatedSelection(false);
    },
    [dateCreatedSelection],
  );

  const handleMonthEventChange = useCallback(
    (month: number, year: number) => {
      setMonthEvent(month);
      setYearEvent(year);
    },
    [{ monthEvent, yearEvent }],
  );
  const handleDateEventSelection = useCallback(() => {
    setDateEventSelection(!dateEventSelection);
  }, [dateEventSelection]);
  const handleSelectedDatesEvent = useCallback(
    (e: any) => {
      setSelectedDatesEvent(e);
      if (dateEventSelection) setDateEventSelection(false);
    },
    [dateEventSelection],
  );

  const handleMonthClosedChange = useCallback(
    (month: number, year: number) => {
      setMonthClosed(month);
      setYearClosed(year);
    },
    [{ monthClosed, yearClosed }],
  );
  const handleDateClosedSelection = useCallback(() => {
    setDateClosedSelection(!dateClosedSelection);
  }, [dateClosedSelection]);
  const handleSelectedDatesClosed = useCallback(
    (e: any) => {
      setSelectedDatesClosed(e);
      if (dateClosedSelection) setDateClosedSelection(false);
    },
    [dateClosedSelection],
  );

  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message={'Modifiche non salvate'}
      saveAction={{
        onAction: handleSave,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
      contextControl={contextControlMarkup}
    />
  ) : null;

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Search client
   */
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [deselectedOptions, setDeselectedOptions] = useState([]);
  const [options, setOptions] = useState([]);

  /**
   * Data fetching:
   * - fetch customers
   */
  useEffect(() => {
    const fetchClients = async () => {
      try {
        setIsLoading(true);
        const data = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/customers', {
          method: 'GET',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        const response = await data.json();

        if (response.status === 'success') {
          const tmp = [];
          for (const item of response.data) {
            tmp.push({ value: item._id, label: item.name });
          }
          // @ts-ignore
          setDeselectedOptions(tmp);
          // @ts-ignore
          setOptions(tmp);
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchClients();
  }, []);

  /**
   * Autocomplete Controls
   */
  const updateText = useCallback(
    (value: any) => {
      setInputValue(value);

      if (value === '') {
        setOptions(deselectedOptions);
        setInputValue('');
        setCustomerId('');
        return;
      }

      const filterRegex = new RegExp(value, 'i');
      const resultOptions = deselectedOptions.filter((option) => {
        // @ts-ignore
        return option.label.match(filterRegex);
      });
      setOptions(resultOptions);
    },
    [deselectedOptions],
  );

  const updateSelection = useCallback(
    (selected: any) => {
      const selectedValue = selected.map((selectedItem: any) => {
        const matchedOption = options.find((option) => {
          // @ts-ignore
          return option.value.match(selectedItem);
        });
        // @ts-ignore
        return matchedOption;
      });
      setSelectedOptions(selected);
      setInputValue(selectedValue[0].label);
      handleCustomerNameChange(selected);
      handleCustomerIdChange(selectedValue[0].value);
    },
    [handleCustomerIdChange, handleCustomerNameChange, options],
  );

  const customerTextField = (
    <Autocomplete.TextField
      autoComplete="off"
      onChange={updateText}
      label="Cliente"
      value={inputValue}
      prefix={<Icon source={SearchMinor as any} color="base" />}
      placeholder="Cerca"
      error={validationCustomer && 'Il cliente è obbligatorio'}
    />
  );

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="Il sinistro è stato creato con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page title="Sinistro" backAction={{ content: 'Sinistri', url: '/claims' }}>
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}

        {/* Panoramica cliente */}
        <Layout.AnnotatedSection title="Dettagli cliente">
          <LegacyCard sectioned>
            <FormLayout>
              <FormLayout.Group>
                <Autocomplete options={options} selected={selectedOptions} onSelect={updateSelection} textField={customerTextField} />
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Dettagli Sinistro */}
        <Layout.AnnotatedSection title="Dettagli Sinistro">
          <LegacyCard sectioned>
            <FormLayout>
              {/* date_created */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data apertura sinistro"
                  value={selectedDatesCreated.start.toLocaleDateString()}
                />
                <Button onClick={handleDateCreatedSelection}>Seleziona data apertura sinistro</Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateCreatedSelection && (
                  <DatePicker
                    month={monthCreated}
                    year={yearCreated}
                    onChange={handleSelectedDatesCreated}
                    onMonthChange={handleMonthCreatedChange}
                    selected={selectedDatesCreated}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
              {/* date_event */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data evento sinistro"
                  value={selectedDatesEvent.start.toLocaleDateString()}
                />
                <Button onClick={handleDateEventSelection}>Seleziona data evento sinistro</Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateEventSelection && (
                  <DatePicker
                    month={monthEvent}
                    year={yearEvent}
                    onChange={handleSelectedDatesEvent}
                    onMonthChange={handleMonthEventChange}
                    selected={selectedDatesEvent}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
              {/* date_closed */}
              <FormLayout.Group>
                <TextField
                  autoComplete="on"
                  type="text"
                  disabled={true}
                  labelHidden={true}
                  label="Data chiusura sinistro"
                  value={selectedDatesClosed.start.toLocaleDateString()}
                />
                <Button onClick={handleDateClosedSelection}>Seleziona data chiusura sinistro</Button>
              </FormLayout.Group>
              <FormLayout.Group>
                {dateClosedSelection && (
                  <DatePicker
                    month={monthClosed}
                    year={yearClosed}
                    onChange={handleSelectedDatesClosed}
                    onMonthChange={handleMonthClosedChange}
                    selected={selectedDatesClosed}
                    allowRange={false}
                    weekStartsOn={1}
                  />
                )}
              </FormLayout.Group>
            </FormLayout>
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Status */}
        <Layout.AnnotatedSection title="Stato">
          <LegacyCard sectioned>
            <Select label="Stato" options={statusOptions} onChange={handleSelectChange} value={selectedStatus} />
          </LegacyCard>
        </Layout.AnnotatedSection>
        {/* Note */}
        <Layout.AnnotatedSection title="Note">
          <LegacyCard sectioned>
            <TextField autoComplete="on" type="text" label="Note sinistro" value={note} onChange={handleNoteChange} multiline={6} />
          </LegacyCard>
        </Layout.AnnotatedSection>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {loadingMarkup}
      {pageMarkup}
      {toastMarkup}
    </Frame>
  );
}

import React, { useCallback, useRef, useState } from 'react';

import { LegacyCard, Frame, Layout, Loading, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, LegacyStack, TextContainer } from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../components';
import { useUser } from '../../utils/PrivateRoute';
import { GuideItem } from './components';

export function Guide() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = () => {
    setMobileNavigationActive((data) => !data);
  };

  const loadingMarkup = isLoading ? <Loading /> : null;

  /**
   * Page markup
   */
  const actualPageMarkup = (
    <Page title="Guida">
      <Layout>
        <Layout.Section>
          {/*FIRST ROW*/}
          <div className="dashboardContent">
            <LegacyStack vertical>
              <GuideItem
                title="Titulo"
                description="Here goes the description, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's "
              />
            </LegacyStack>
            <LegacyStack vertical>
              <GuideItem
                title="TituloDUE"
                description="Here goes the description,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
              />
            </LegacyStack>
          </div>

          {/*SECOND ROW*/}
          <div className="dashboardContent">
            <LegacyStack vertical>
              <GuideItem
                title="Titulo"
                description="Here goes the description, Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's "
              />
            </LegacyStack>
            <LegacyStack vertical>
              <GuideItem
                title="TituloDUE"
                description="Here goes the description,Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's"
              />
            </LegacyStack>
          </div>
        </Layout.Section>
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingMarkup}
      {pageMarkup}
    </Frame>
  );
}

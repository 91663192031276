import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  Button,
  LegacyCard,
  Frame,
  Layout,
  Badge,
  Page,
  SkeletonBodyText,
  SkeletonDisplayText,
  SkeletonPage,
  LegacyStack,
  TextContainer,
  Text,
  Banner,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';

import styles from './Payments.module.css';
import { useUser } from '../../../utils/PrivateRoute';

export function Payments() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [active, setActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [error, setError] = useState(false);
  const [pwdError, setPwdError] = useState(false);

  const [connectConfigured, setConnectConfigured] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Fetch organization data
   */
  useEffect(() => {
    const fetchOrganiaztion = async () => {
      try {
        setIsLoading(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          if (data.data.stripe_account && data.data.stripe_account.length > 0) {
            setConnectConfigured(true);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrganiaztion();
  }, [user.organization]);

  /**
   * Setup stripe connect
   */
  const handleSetupStripeConnect = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await fetch(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/payment/setup`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = await response.json();

      if (data.status === 'success' && data.data.url) {
        // Wait 2 seconds before redirecting
        setTimeout(() => {
          setButtonSpinning(false);
          // window.open(data.data.url, '_blank')?.focus();
          window.location.href = data.data.url;
        }, 2000);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [user.organization]);

  /**
   * Manage stripe connect
   */
  const handleStripeConnectDashboard = useCallback(async () => {
    try {
      setButtonSpinning(true);
      const response = await fetch(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/settings/organization/${user.organization}/payment/dashboard`,
        {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = await response.json();

      if (data.status === 'success' && data.data.url) {
        // Wait 2 seconds before redirecting
        setTimeout(() => {
          setButtonSpinning(false);
          // window.open(data.data.url, '_blank')?.focus();
          window.location.href = data.data.url;
        }, 2000);
      } else {
        setError(true);
      }
    } catch (error) {
      console.log(error);
      setError(true);
    } finally {
      setButtonSpinning(false);
    }
  }, [user.organization]);

  /**
   * Banner markups
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nell'aggiornamento dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const bannerWrongPwdMarkup = pwdError && (
    <Layout.Section>
      <Banner title="Password errata" status="critical" onDismiss={() => setPwdError(false)}>
        <p>Si è pregati di ricontrollare la password.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const actualPageMarkup = (
    <Page title="Pagamenti" backAction={{ content: 'Impostazioni', url: '/settings' }}>
      <Layout>
        {/* Banner */}
        {bannerMarkup}
        {bannerWrongPwdMarkup}
        {/* Panoramica account */}
        <Layout.Section>
          <LegacyCard title={<img src="/stripe_logo.svg" width={80} />}>
            <LegacyCard.Section>
              <LegacyStack vertical>
                {/* Payment methods container */}
                <div className={styles.paymentsContainer}>
                  <LegacyCard>
                    <LegacyCard.Section>
                      <div className={styles.paymentsRow}>
                        <LegacyStack>
                          <img className={styles.singlePayment} src="/payments/visa.svg" alt="Visa" />
                          <img className={styles.singlePayment} src="/payments/mastercard.svg" alt="Mastercard" />
                          <img className={styles.singlePayment} src="/payments/amex.svg" alt="American Express" />
                          <img className={styles.singlePayment} src="/payments/maestro.svg" alt="Maestro" />
                          <img className={styles.singlePayment} src="/payments/apple_pay.svg" alt="Apple Pay" />
                          <img className={styles.singlePayment} src="/payments/google_pay.svg" alt="Google Pay" />
                        </LegacyStack>
                      </div>
                    </LegacyCard.Section>
                    <LegacyCard.Section subdued>
                      <LegacyStack>
                        <LegacyStack.Item>
                          <div>
                            <p>Commissione per le carte di credito</p>
                            <p>
                              <Text as="span" color="subdued">
                                Appena 1,6% + 0,25 €
                              </Text>
                            </p>
                          </div>
                        </LegacyStack.Item>
                        <LegacyStack.Item>
                          <div>
                            <p>Commissione</p>
                            <p>
                              <Text as="span" color="subdued">
                                0%
                              </Text>
                            </p>
                          </div>
                        </LegacyStack.Item>
                      </LegacyStack>
                    </LegacyCard.Section>
                  </LegacyCard>
                </div>
                <div>Per iniziare ad accettare pagamenti nel negozio con Shopify Payments, devi fornire ulteriori informazioni sul tuo negozio.</div>
                {connectConfigured ? (
                  <Button onClick={handleStripeConnectDashboard} loading={buttonSpinning}>
                    Gestisci il tuo account
                  </Button>
                ) : (
                  <Button onClick={handleSetupStripeConnect} primary loading={buttonSpinning}>
                    Completa configurazione account
                  </Button>
                )}
              </LegacyStack>
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
        {/* Payments acquisition */}
        <Layout.Section secondary>
          <LegacyCard title="Acquisizione pagamento" sectioned>
            <LegacyStack>
              <Badge>Automatica</Badge>
              <div>
                <Text as="span" color="subdued">
                  Il metodo di pagamento del cliente è autorizzato e l'importo è addebitato automaticamente.
                </Text>
              </div>
            </LegacyStack>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section />
      </Layout>
    </Page>
  );

  // ---- Loading ----
  const loadingPageMarkup = (
    <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={9} />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  );

  const pageMarkup = isLoading ? loadingPageMarkup : actualPageMarkup;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {pageMarkup}
    </Frame>
  );
}

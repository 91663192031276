import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Button, Frame, Layout, Page, Toast, Banner, ContextualSaveBar } from '@shopify/polaris';
import { TopBarMarkup, NavigationMarkup } from '../../../../components';
import { CategoryList, CategoryModal } from './components';
import { Category, CategoryState } from '../../../../types';
import { useUser } from '../../../../utils/PrivateRoute';

export function CategoryAll() {
  const { user } = useUser();

  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  const [isDirty, setIsDirty] = useState(false);
  const [active, setActive] = useState(false);
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const [saveError, setSaveError] = useState(false);
  const [counterError, setCounterError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [update, setUpdate] = useState(false);

  const [categoryModalActive, setCategoryModalActive] = useState(false);

  /**
   * States
   */
  const [categories, setCategories] = useState<Category[]>([]);
  const [frontItems, setFrontItems] = useState<Category[]>([]);
  const [category, setCategory] = useState<CategoryState | null>(null);
  const [newCategory, setNewCategory] = useState<boolean>(true);

  /** Discard */
  const handleDiscard = useCallback(() => {
    setIsDirty(false);
  }, []);

  /**
   * Fetch categories
   */
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setIsLoading(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/categories', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        });
        const data = await response.json();

        if (data.status === 'success') {
          setCategories(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCategories();
  }, [update]);

  /**
   * Save data
   */
  const handleSave = useCallback(
    async (category: any) => {
      try {
        setButtonSpinning(true);
        console.log(category);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + '/admin/categories/new', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: category.name,
            tax: parseFloat(category.tax),
          }),
        });
        const data = await response.json();

        if (data.status === 'success') {
          setActive(true);
          setUpdate(!update);
        } else {
          handleDiscard();
          setSaveError(true);
        }
      } catch (error) {
        console.log(error);
        handleDiscard();
        setSaveError(true);
      } finally {
        setButtonSpinning(false);
      }
    },
    [handleDiscard, update],
  );

  /**
   * Update data
   */
  const handleUpdate = useCallback(
    async (category: any) => {
      try {
        setButtonSpinning(true);
        const response = await fetch((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '/api') + `/admin/categories/${category._id}`, {
          method: 'PUT',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: category.name,
            tax: category.tax,
          }),
        });
        const data = await response.json();

        if (data.status === 'success') {
          setActive(true);
          setUpdate(!update);
        } else {
          handleDiscard();
          setSaveError(true);
        }
      } catch (error) {
        console.log(error);
        handleDiscard();
        setSaveError(true);
      } finally {
        setButtonSpinning(false);
      }
    },
    [handleDiscard, update],
  );

  /**
   * Handlers
   */
  // Handle commission modal
  // Add product to orgProducts and add value and value_type
  const handleCategoryModal = useCallback(
    (categoryId?: string, category?: Category) => {
      if (!categoryId && category !== undefined) {
        setCategory({
          name: category.name,
          tax: String(category.tax),
        });
        setNewCategory(false);
      } else if (categoryId !== undefined && !category) {
        // Find category based on id
        const category = categories.find((category) => category._id === categoryId);
        if (category) {
          setCategory({
            name: category.name,
            tax: String(category.tax),
          });
          setNewCategory(false);
        }
      } else {
        // Set default values
        setCategory({
          name: '',
          tax: '0.00',
        });
        setNewCategory(true);
      }

      setCategoryModalActive(true);
    },
    [categories],
  );

  // Handle category modal save
  const handleCategoryModalSave = useCallback(
    (category: Category) => {
      // If we are editing the orgProduct, then we need to update the value and the value_type
      if (!newCategory) {
        handleUpdate(category);
      } else {
        handleSave(category);
      }

      setCategoryModalActive(false);
      // setIsDirty(true);
    },
    [categories, newCategory],
  );

  /**
   * Save bar
   */
  const contextualSaveBarMarkup = isDirty ? (
    <ContextualSaveBar
      message="Modifiche non salvate"
      saveAction={{
        onAction: () => alert('Salvataggio'),
        loading: buttonSpinning,
      }}
      discardAction={{
        onAction: handleDiscard,
        discardConfirmationModal: true,
      }}
    />
  ) : null;

  /**
   * Error markups & toast
   */
  const toastMarkup = active ? <Toast content="I dati sono stati aggiornati con successo." onDismiss={toggleActive} /> : null;

  const saveErrorMarkup = saveError && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setSaveError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const counterErrorMarkup = counterError && (
    <Layout.Section>
      <Banner title={'Una o più categorie non possono essere rimosse'} status="critical" onDismiss={() => setCounterError(false)}>
        <p>Le polizze sostituite non possono essere rimosse. Si è pregati di controllare e riprovare.</p>
      </Banner>
    </Layout.Section>
  );

  // ---- Page markup ----
  const pageMarkup = (
    <Page
      title="Categorie"
      primaryAction={
        <Button primary onClick={() => handleCategoryModal()}>
          Aggiungi categoria
        </Button>
      }
    >
      <Layout>
        {/* Banner */}
        {saveErrorMarkup}
        {counterErrorMarkup}
        {/* List */}
        <Layout.Section>
          <CategoryList
            categories={categories}
            frontItems={frontItems}
            setFrontItems={setFrontItems}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            handleCategoryModal={handleCategoryModal}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {contextualSaveBarMarkup}
      {pageMarkup}
      {CategoryModal(categoryModalActive, setCategoryModalActive, category, setCategory, newCategory, handleCategoryModalSave)}
      {toastMarkup}
    </Frame>
  );
}

import React, { useCallback, useEffect, useRef, useState } from 'react';

import {
  LegacyCard,
  Frame,
  Layout,
  Page,
  Text,
  Banner,
  Filters,
  EmptyState,
  FilterInterface,
  IndexTable,
  useIndexResourceState,
  Toast,
  Select,
  Loading,
  FormLayout,
  Modal,
  ButtonGroup,
  Button,
  TextField,
} from '@shopify/polaris';

import { TopBarMarkup, NavigationMarkup } from '../../../components';
import axios, { AxiosError } from 'axios';
import { Report } from '../../../types';
import { useUser } from '../../../utils/PrivateRoute';

export function Reports() {
  const { user } = useUser();
  const skipToContentRef = useRef<HTMLAnchorElement>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [error, setError] = useState(false);
  const [buttonSpinning, setButtonSpinning] = useState(false);
  const [update, setUpdate] = useState(false);

  const [reportUpdatedActive, setReportUpdatedActive] = useState(false);
  const toggleReportUpdatedActive = useCallback(() => setReportUpdatedActive((reportUpdatedActive) => !reportUpdatedActive), []);

  const [reportCreatedActive, setReportCreatedActive] = useState(false);
  const toggleReportCreatedActive = useCallback(() => setReportCreatedActive((reportCreatedActive) => !reportCreatedActive), []);

  const [addReportModalOpen, setAddReportModalOpen] = useState(false);

  const toggleMobileNavigationActive = useCallback(() => setMobileNavigationActive((mobileNavigationActive) => !mobileNavigationActive), []);

  const handleMobileNavigation = (data: boolean) => {
    setMobileNavigationActive(!data);
  };

  /**
   * Report states
   */
  const [taggedWith, setTaggedWith] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const [sortValue, setSortValue] = useState('DATE_CREATED_DESC');
  const [items, setItems] = useState<Report[]>([]);
  const [frontItems, setFrontItems] = useState<Report[]>([]);

  const handleTaggedWithChange = useCallback((value: any) => setTaggedWith(value), []);
  const handleTaggedWithRemove = useCallback(() => setTaggedWith(''), []);
  const handleQueryValueRemove = useCallback(() => setQueryValue(''), []);
  const handleClearAll = useCallback(() => {
    handleTaggedWithRemove();
    handleQueryValueRemove();
  }, [handleQueryValueRemove, handleTaggedWithRemove]);

  const resourceName = {
    singular: 'report',
    plural: 'reports',
  };

  const [reportId, setReportId] = useState<string | null>(null);
  const [name, setName] = useState('');
  const [methodName, setMethodName] = useState('');

  const [isEditing, setIsEditing] = useState(false);

  /** Empty fields */
  const [emptyFields, setEmptyFields] = useState({
    name: false,
    methodName: false,
  });

  /**
   * Data fetching:
   * - fetch reports
   */
  useEffect(() => {
    const fetchReports = async () => {
      try {
        setIsLoading(true);
        const response = await axios.get((process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/reports`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
          timeout: 10000,
        });
        const data = response.data;
        if (data.status === 'success') {
          setItems(data.data);
          setFrontItems(data.data);
        }
      } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError.response) {
          console.log(axiosError.response);
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchReports();
  }, [update]);

  /**
   * Handle add report
   */
  const handleAddReport = useCallback(async () => {
    try {
      setButtonSpinning(true);

      let flag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        flag = true;
      }
      if (methodName === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, methodName: true }));
        flag = true;
      }

      if (flag) {
        setButtonSpinning(false);
        return;
      }

      const response = await axios.post(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/reports`,
        {
          name: name,
          method_name: methodName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        setName('');
        setMethodName('');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
        setError(true);
      }
    } finally {
      setButtonSpinning(false);
      setAddReportModalOpen(false);
    }
  }, [emptyFields, name, methodName, update]);

  /**
   * Handle update report
   */
  const handleUpdateReport = useCallback(async () => {
    try {
      setButtonSpinning(true);

      let flag = false;
      if (name === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: true }));
        flag = true;
      }
      if (methodName === '') {
        setEmptyFields((emptyFields) => ({ ...emptyFields, methodName: true }));
        flag = true;
      }

      if (flag) {
        setButtonSpinning(false);
        return;
      }

      const response = await axios.put(
        (process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : `/api`) + `/admin/reports/${reportId}`,
        {
          name: name,
          method_name: methodName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('mb__access_token')}`,
          },
        },
      );
      const data = response.data;

      if (data.status === 'success') {
        setUpdate(!update);
        setName('');
        setMethodName('');
      }
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response) {
        console.log(axiosError.response);
        setError(true);
      }
    } finally {
      setButtonSpinning(false);
      setAddReportModalOpen(false);
    }
  }, [emptyFields, name, methodName, update, reportId]);

  /**
   * Name change
   */
  const handleNameChange = useCallback(
    (value: string) => {
      if (emptyFields.name) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, name: false }));
      }
      setName(value);
    },
    [emptyFields],
  );

  /**
   * Url change
   */
  const handleUrlChange = useCallback(
    (value: string) => {
      if (emptyFields.methodName) {
        setEmptyFields((emptyFields) => ({ ...emptyFields, methodName: true }));
      }
      setMethodName(value);
    },
    [emptyFields],
  );

  /**
   * Handle edit template modal
   */
  const handleEdit = useCallback(
    (id: string) => {
      const item = items.find((item: Report) => item._id === id);
      if (item) {
        console.log(item);
        setAddReportModalOpen(true);
        setIsEditing(true);
        setReportId(id);
        setName(item.name);
        setMethodName(item.method_name);
      }
    },
    [items],
  );

  /**
   * Filtering
   */
  function disambiguateLabel(key: string, value: string | null) {
    switch (key) {
      case 'taggedWith':
        return `Tagged with ${value}`;
      default:
        return '';
    }
  }

  function isEmpty(value: string | any[] | null) {
    if (Array.isArray(value)) {
      return value.length === 0;
    } else {
      return value === '' || value == null;
    }
  }

  const filters: FilterInterface[] = [];

  const appliedFilters = !isEmpty(taggedWith)
    ? [
        {
          key: 'taggedWith',
          label: disambiguateLabel('taggedWith', taggedWith),
          // @ts-ignore
          onRemove: handleTaggedWithRemove,
        },
      ]
    : [];

  useEffect(() => {
    const filterItems = () => {
      const filteredItems = items.filter((item) => {
        return item.name.toLowerCase().includes(queryValue.toLowerCase());
      });
      setFrontItems(filteredItems);
    };
    filterItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryValue]);

  /**
   * Handle sort
   */
  useEffect(() => {
    if (sortValue === 'DATE_CREATED_DESC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(b.date_created) - new Date(a.date_created));
      setFrontItems(tmp);
    } else if (sortValue === 'DATE_CREATED_ASC') {
      const tmp = [...items];
      // @ts-ignore
      tmp.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));
      setFrontItems(tmp);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortValue]);

  /**
   * Index table handlers
   */
  const resourceIDResolver = (items: { _id: any }) => {
    return items._id;
  };

  const { selectedResources, allResourcesSelected, handleSelectionChange } = useIndexResourceState(frontItems, {
    resourceIDResolver,
  });

  /**
   * Render row
   */
  const rowMarkup = frontItems.map((item: Report, index: number) => {
    const { _id: id, name, method_name } = item;

    return (
      <IndexTable.Row id={id} key={id} selected={selectedResources.includes(id)} position={index}>
        <IndexTable.Cell>
          <Text as="span" fontWeight="semibold">
            {name}
          </Text>
        </IndexTable.Cell>
        <IndexTable.Cell>{method_name}</IndexTable.Cell>
        <IndexTable.Cell>
          <div style={{ float: 'right' }}>
            <ButtonGroup segmented>
              <Button size="slim" onClick={() => handleEdit(id)}>
                Modifica
              </Button>
            </ButtonGroup>
          </div>
        </IndexTable.Cell>
      </IndexTable.Row>
    );
  });

  /**
   * Report modal
   */
  const reportModalMarkup = (
    <Modal
      open={addReportModalOpen}
      onClose={() => setAddReportModalOpen(false)}
      title="Aggiungi un report"
      primaryAction={{
        content: !isEditing ? 'Aggiungi report' : 'Modifica',
        onAction: () => (!isEditing ? handleAddReport() : handleUpdateReport()),
        loading: buttonSpinning,
      }}
      secondaryActions={[
        {
          content: 'Annulla',
          onAction: () => setAddReportModalOpen(false),
        },
      ]}
    >
      <Modal.Section>
        <FormLayout>
          <TextField
            label="Nome"
            value={name}
            onChange={handleNameChange}
            placeholder="Nome del report"
            autoComplete="off"
            error={emptyFields.name && 'Il nome è obbligatorio'}
          />
          <TextField
            label="Metodo"
            value={methodName}
            onChange={handleUrlChange}
            placeholder="Nome metodo del report"
            autoComplete="off"
            error={emptyFields.name && "L'URL è obbligatorio"}
          />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );

  /**
   * Empty list markup
   */
  const emptyListMarkup = !items.length && (
    <EmptyState
      heading="Crea e gestisci i tuoi report"
      image="https://cdn.shopify.com/shopifycloud/web/assets/v1/87c89b0a776f2d13fdde186958e6a83c28fd4a6ca1ff7f04bb16e6629bb8f6de.svg"
      action={{
        content: 'Aggiungi un report',
        onAction: () => setAddReportModalOpen(true),
        loading: buttonSpinning,
      }}
    >
      <p>I report devono essere associati a un'organizzazione per uno specifico prodotto</p>
    </EmptyState>
  );

  /**
   * Banner & toast markups
   */
  const bannerMarkup = error && (
    <Layout.Section>
      <Banner title="Si è verificato un errore nel salvataggio dei dati" status="critical" onDismiss={() => setError(false)}>
        <p>Si è pregati di riprovare più tardi.</p>
      </Banner>
    </Layout.Section>
  );

  const toastReportUpdatedMarkup = reportUpdatedActive && <Toast content="Il report è stato modficato con successo." onDismiss={toggleReportUpdatedActive} />;

  const toastReportCreatedMarkup = reportCreatedActive && <Toast content="Il report è stato creato con successo." onDismiss={toggleReportCreatedActive} />;

  /**
   * Page markup
   */
  useEffect(() => {
    if (items.length > 0) setIsLoading(false);
  }, [items]);

  const actualPageMarkup = (
    <Page
      title="Reports"
      primaryAction={{
        content: 'Aggiungi un report',
        onAction: () => setAddReportModalOpen(true),
        loading: buttonSpinning,
      }}
    >
      <Layout>
        {/* Banner */}
        {bannerMarkup}

        {/* Templates list */}
        <Layout.Section>
          <LegacyCard>
            <div style={{ padding: '16px', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Filters
                  queryValue={queryValue}
                  filters={filters}
                  // @ts-ignore
                  appliedFilters={appliedFilters}
                  queryPlaceholder={'Filtra reports'}
                  onQueryChange={setQueryValue}
                  onQueryClear={handleQueryValueRemove}
                  onClearAll={handleClearAll}
                />
              </div>
              <div style={{ paddingLeft: '0.4rem' }}>
                <Select
                  labelInline
                  label="Ordina per"
                  options={[
                    { label: 'Data aggiunta report (dal più recente)', value: 'DATE_CREATED_DESC' },
                    { label: 'Data aggiunta report (dal meno recente)', value: 'DATE_CREATED_ASC' },
                  ]}
                  value={sortValue}
                  onChange={(selected) => {
                    setSortValue(selected);
                  }}
                />
              </div>
            </div>
            <IndexTable
              resourceName={resourceName}
              emptyState={emptyListMarkup}
              loading={isLoading}
              itemCount={frontItems.length}
              selectedItemsCount={allResourcesSelected ? 'All' : selectedResources.length}
              hasMoreItems
              onSelectionChange={handleSelectionChange}
              headings={[{ title: 'Nome' }, { title: 'Nome del metodo' }]}
            >
              {rowMarkup}
            </IndexTable>
          </LegacyCard>
        </Layout.Section>

        <Layout.Section />
      </Layout>
    </Page>
  );

  const loadingPageMarkup = isLoading && <Loading />;

  return (
    <Frame
      topBar={<TopBarMarkup user={user} handleMobileNavigation={handleMobileNavigation} />}
      navigation={<NavigationMarkup user={user} />}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      skipToContentTarget={skipToContentRef}
    >
      {loadingPageMarkup}
      {actualPageMarkup}
      {reportModalMarkup}
      {toastReportUpdatedMarkup}
      {toastReportCreatedMarkup}
    </Frame>
  );
}

import React, { useCallback, useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, LegacyCard, FormLayout, Select, LegacyStack, TextField, Text } from '@shopify/polaris';
import { DeleteMinor, DragHandleMinor } from '@shopify/polaris-icons';

interface Props {
  id: string;
  nameOptions: any[];
  typeOptions: any[];
  pricingRuleItem?: any;
  onDelete: (id: string) => void;
  onSave?: (id: string, name: 'minimum_price', type: 'fixed_amount' | 'percentage', value: string) => void;
  onUpdate?: (_id: string, id: string, name: 'minimum_price', type: 'fixed_amount' | 'percentage', value: string) => void;
}

export function SortableItemPricingRule(props: Props) {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const [name, setName] = useState(props.pricingRuleItem?.name || props.nameOptions[0].value);
  const [value, setValue] = useState(props.pricingRuleItem?.value || '');
  const [type, setType] = useState(props.pricingRuleItem?.type || props.typeOptions[0].value);
  const [isEditing, setIsEditing] = useState(props.pricingRuleItem === undefined);

  const [emptyFields, setEmptyFields] = useState({
    name: false,
    value: false,
  });

  const onDelete = () => {
    props.onDelete(props.id);
  };

  const onSelectChange = useCallback((value: any) => {
    setType(value);

    // If text is selected, clear value
    if (value === 'text') {
      setValue('');
    }
  }, []);

  const onSelectNameChange = useCallback((value: any) => {
    setName(value);
  }, []);

  const onValueChange = useCallback(
    (value: any) => {
      if (emptyFields.value) {
        setEmptyFields({ ...emptyFields, value: false });
      }
      setValue(value);
    },
    [emptyFields.value],
  );

  const handleSave = () => {
    let errFlag = false;

    // Check if name is empty
    if (name === '') {
      setEmptyFields({
        ...emptyFields,
        name: true,
      });
      errFlag = true;
    }

    // Check if value is empty
    if (value === '' && type !== 'text') {
      setEmptyFields({
        ...emptyFields,
        value: true,
      });
      errFlag = true;
    }

    if (errFlag) {
      return;
    }

    // Save data if pricingRuleItem is undefined otherwise update data
    if (props.pricingRuleItem === undefined) {
      if (props.onSave !== undefined) props?.onSave(props.id, name, type, value);
    } else {
      if (props.onUpdate !== undefined) props?.onUpdate(props.pricingRuleItem._id, props.id, name, type, value);
    }

    // Change component to display mode
    setIsEditing(false);
  };

  /**
   * Edit mode markup
   */
  const editModeMarkup = (
    <LegacyStack.Item fill>
      <FormLayout>
        {/* <TextField label="Nome" value={name} onChange={onNameChange} error={emptyFields.name && 'Il campo è obbligatorio'} autoComplete="off" /> */}
        <Select label="Nome" options={props.nameOptions} onChange={onSelectNameChange} value={name} />
        <FormLayout.Group>
          <Select label="Tipo" options={props.typeOptions} onChange={onSelectChange} value={type} />
          {type !== 'text' && (
            <TextField label="Valore" value={value} onChange={onValueChange} error={emptyFields.value && 'Il campo è obbligatorio'} autoComplete="off" />
          )}
        </FormLayout.Group>
        <Button onClick={handleSave}>Fatto</Button>
      </FormLayout>
    </LegacyStack.Item>
  );

  /**
   * Display mode markup
   */
  const displayModeMarkup = (
    <LegacyStack.Item fill>
      <Text as="span" fontWeight="semibold">
        {props.nameOptions.find((option: any) => option.value === name)?.label}
      </Text>
      <div>
        Tipo: {props.typeOptions.find((option: any) => option.value === type)?.label} {type !== 'text' && `— Valore: ${value}`}
      </div>
    </LegacyStack.Item>
  );

  return (
    <LegacyCard.Section>
      <div ref={setNodeRef} style={style}>
        <LegacyStack alignment="center">
          <LegacyStack.Item>
            {/* <Icon source={DragHandleMinor} {...attributes} {...listeners} /> */}
            <Button plain icon={DragHandleMinor as any} {...attributes} {...listeners} />
          </LegacyStack.Item>
          {isEditing ? editModeMarkup : displayModeMarkup}
          <LegacyStack.Item>
            {isEditing ? (
              <Button icon={DeleteMinor as any} plain onClick={onDelete} />
            ) : (
              <Button size="slim" onClick={() => setIsEditing(true)}>
                Modifica
              </Button>
            )}
          </LegacyStack.Item>
        </LegacyStack>
      </div>
    </LegacyCard.Section>
  );
}

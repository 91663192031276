import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from './utils/PrivateRoute';
import routes from './routes';

/** Pages */
import { LoginPage } from './pages/LoginPage/LoginPage';
import { ForgotPasswordPage } from './pages';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />

          {/* Private routes */}
          <Route element={<PrivateRoute />}>
            {routes.map((route, idx) => {
              return <Route key={idx} path={route.path} element={<route.component />} />;
            })}
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
